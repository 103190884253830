import React from 'react'
import { Link } from 'react-router-dom'
import { Badge } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass, faPaperPlane, faTrash } from '@fortawesome/free-solid-svg-icons'
import { Divider, Empty, message, Popconfirm, Table, Tooltip } from 'antd'
import moment from 'moment'

import Pagination from '../../../components/Pagination'

import { deleteCertificate, updateCertificate } from '../services'
import { handleError } from '../../../helpers'

export default function CertificatesTable({ certificates, reload, pagination, setPagination }) {
	
	const columns = [
		{
			title: 'SKU Certificado',
			dataIndex: 'sku',
			render: (t, r) => `${r.brand_slug.toUpperCase()} - ${t}`
		},
		{
			title: 'Empresa',
			dataIndex: 'service_report',
			render: t => t.service.location.company.legal_name
		},
		{
			title: 'Sede',
			dataIndex: 'service_report',
			render: t => t.service.location.name
		},
		{
			title: 'Servicio',
			dataIndex: 'service_report',
			render: t => t.service.name
		},
		{
			title: 'Fecha de publicación',
			dataIndex: 'published_at',
			render: t => t ? t : <em>Sin publicar</em>
		},
		{
			title: 'Estado',
			dataIndex: 'status',
			render: t => <Badge color={t.color}>{t.label}</Badge>
		},
		{
			title: 'Acciones',
			width: '125px',
			render: (t, r) => (
				<React.Fragment>
					<Tooltip title="Ver Certificado">
						<React.Fragment>
							<Link to={`/certificates/preview/${r.id}`} target="_blank">
								<FontAwesomeIcon icon={faMagnifyingGlass} className="text-link" />
							</Link>
						</React.Fragment>
					</Tooltip>
					<Divider type='vertical' />
					{ r.status.key === 'draft' && (
						<React.Fragment>							
							<Tooltip title="Publicar">
								<React.Fragment>
									<FontAwesomeIcon 
										icon={faPaperPlane} 
										className="text-link"
										onClick={() => handlePublish(r.id)}
									/>
								</React.Fragment>
							</Tooltip>
							<Divider type='vertical' />
						</React.Fragment>
					)}
					<Tooltip title="Eliminar">
						<React.Fragment>
							<Popconfirm
								title="Esta acción no se puede revertir"
								okText="Eliminar"
								cancelText="Cancelar"
								okButtonProps={{ danger:true }}
								onConfirm={() => handleDelete(r.id)}
							>
								<FontAwesomeIcon icon={faTrash} className="text-link" />
							</Popconfirm>
						</React.Fragment>
					</Tooltip>
				</React.Fragment>
			)
		}
	]

	const handlePublish = id => {
		updateCertificate(id, { published_at: moment().format('YYYY-MM-DD HH:mm:ss') })
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(handleError)
	}

	const handleDelete = id => {
		deleteCertificate(id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(handleError)
	}
	
	return (
		<React.Fragment>
			<Table 
				dataSource={certificates}
				rowKey={r => r.id}
				pagination={false}
				columns={columns}
				loading={!certificates}
				size="small"
				locale={{ emptyText: (
					<Empty
						description="No se encontraron registros" 
						imageStyle={{ height: '80px' }}
					/> 
				)}}
			/>
			<Pagination 
				pagination={pagination}
				setPagination={setPagination}
				reload={reload}
				className="mb-0"
			/>
		</React.Fragment>
	)
}