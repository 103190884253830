import React from 'react'
import { Form } from 'react-bootstrap'

export default function VehicleForm({ register, errors, editable }) {
	return (
		<React.Fragment>
			<Form.Group className='form-group'>
				<Form.Label>Nombre del Vehículo <span className='text-danger'>*</span></Form.Label>
				<Form.Control 
					{...register('name', { required: true })}
					placeholder="Escriba aquí..."
				/>
				{ errors.name && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
			<Form.Group className='form-group'>
				<Form.Label>Placas del Vehículo <span className='text-danger'>*</span></Form.Label>
				<Form.Control 
					{...register('placas', { required: true })}
					placeholder="Escriba aquí..."
					disabled={editable}
				/>
				{ errors.placas && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
			<Form.Group className='form-group'>
				<Form.Label>Fecha de vinculación <span className='text-danger'>*</span></Form.Label>
				<Form.Control 
					type="date"
					{...register('register_at', { required:true })}
				/>
				{ errors.register_at && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
		</React.Fragment>
	)
}