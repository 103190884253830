import React from 'react'

export default function ReviewDocWithTasks({ serviceReview }) {
	return (
		<React.Fragment>
			<tr>
				<td colSpan={20}>Empresa: {serviceReview.service.location.company.legal_name}</td>
				<td colSpan={14}>NIT: {serviceReview.service.location.company.document}</td>
			</tr>
			<tr>
				<td colSpan={34}>Dirección: {serviceReview.service.location.address} {serviceReview.service.location.address_extra}, {serviceReview.service.location.city} {serviceReview.service.location.department}</td>
			</tr>
			<tr><td colSpan={34} className="hr"></td></tr>
			<tr>
				<td colSpan={34} className="text-center bold">Estadó de limpieza y desinfección</td>
			</tr>
			<tr>
				<td colSpan={12}>Aspecto a verificar</td>
				<td colSpan={2}>Cumple</td>
				<td colSpan={2}>No Cumple</td>
				<td colSpan={18}>Observaciones</td>
			</tr>
			{ serviceReview.review_tasks.map( (reviewTask, i) =>  (
				<tr key={reviewTask.id}>
					<td width="20px" className='text-center' colSpan={1}>{i+1}</td>
					<td colSpan={11}>{reviewTask.task_name}</td>
					<td colSpan={2}>{reviewTask.is_meet === 1 ? 'X' : ''}</td>
					<td colSpan={2}>{reviewTask.is_meet === 0 ? 'X' : ''}</td>
					<td colSpan={20}>{reviewTask.observations}</td>
				</tr>
			)) }
			<tr><td colSpan={34} className="hr"></td></tr>
			<tr>
				<td colSpan={12} className='pt-30'>
					<p className='pt-30 mb-0 bold'>{serviceReview.technician.name}</p>
					<small className='inline-block'>Firma tecnico y / o persona que realiza la inspección</small>
				</td>
				<td colSpan={22} className='pt-40'>
					<small className='pt-30 inline-block'>Firma de quien realiza el acompañamiento</small>
				</td>
			</tr>
		</React.Fragment>
	)
}