import ingealimentosApi, { getDomain } from '../../services/ingealimentosApi'

export const getServiceResults = params => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.get('/service_results', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getServiceResult = (id, params) => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.get(`/service_results/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeServiceResult = data => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.post('/service_results', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateServiceResult = (id, data) => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.put(`/service_results/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteServiceResult = id => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.delete(`/service_results/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const notifyServiceResult = id => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.post(`/service_results/notify/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getPublicServiceResult = (id, params) => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.get(`/public/service_results/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchServiceResults = async (term, token) => (
	fetch( getDomain()+`/service_results?filter[name]=${term}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(item => ({
				label: item.name,
				value: item.id,
			})),
		)
)