import React, { useEffect, useState } from 'react'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Empty, Popconfirm, Table, Tooltip, message } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUnlink } from '@fortawesome/free-solid-svg-icons'

import AttachEmployeeToLocationModal from './AttachEmployeeToLocationModal'
import Pagination from '../../../components/Pagination'

import { detachEmployeeLocation, getEmployees } from '../../Employees/services'
import { handleError, parsePagination } from '../../../helpers'

export default function LocationEmployeesModal({ visible, onClose, location }) {
	const [employees, setEmployees] = useState(null)
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})
	const [attachEmployeeModal, setAttachEmployeeModal] = useState(false)

	useEffect(() => {
		!employees && getEmployees({
			...pagination,
			'filter[locations.id]': location.id,
		})
			.then(res => {
				setEmployees(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [ employees, pagination, location ])

	const handleDetach = employeeId => {
		detachEmployeeLocation(employeeId, location.id)
			.then(res => {
				message.success(res.data.message)
				setEmployees(null)
			})
			.catch(error => handleError(error))
	}

	const columns = [
		{
			title: 'Nombre del Contacto',
			dataIndex: 'name',
		},
		{
			title: 'Cargo',
			dataIndex: 'position',
		},
		{
			title: 'Correo Electrónico',
			dataIndex: 'email',
			render: t => t ? t : <em>Sin registro</em>
		},
		{
			title: 'Acciones',
			render: (t, r) => (
				<React.Fragment>
					<Tooltip title="Desasociar">
						<React.Fragment>
							<Popconfirm
								title="¿Desea desasociar el contacto de la sede?"
								okText="Desasociar"
								cancelText="Cancelar"
								okButtonProps={{ danger:true }}
								onConfirm={() => handleDetach(r.id)}
							>
								<FontAwesomeIcon icon={faUnlink} className="text-link" />
							</Popconfirm>
						</React.Fragment>
					</Tooltip>
				</React.Fragment>
			)
		}
	]

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose} size="xl">
				<ModalHeader toggle={onClose}>Listado de Contactos de la Sede</ModalHeader>
				<ModalBody>
					<Table 
						dataSource={employees}
						rowKey={r => r.id}
						pagination={false}
						columns={columns}
						loading={!employees}
						size="small"
						locale={{ emptyText: (
							<Empty
								description="No se tiene contactos asociados a la sede" 
								imageStyle={{ height: '80px' }}
							/> 
						)}}
					/>
					<Pagination
						pagination={pagination}
						setPagination={setPagination}
						reload={() => setEmployees(null)}
					/>
					<div className='text-right mt-10'>
						<Button 
							color="primary" 
							outline 
							onClick={() => setAttachEmployeeModal(true)}
						>
							Asociar Contacto a Sede
						</Button>
					</div>
				</ModalBody>
			</Modal>
			{ attachEmployeeModal && (
				<AttachEmployeeToLocationModal 
					visible
					onClose={() => setAttachEmployeeModal(false)}
					reload={() => setEmployees(null)}
					location={location}
				/>
			)}
		</React.Fragment>
	)
}