import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faMagnifyingGlass, faPrint, faTrash } from '@fortawesome/free-solid-svg-icons'
import { Divider, Empty, message, Popconfirm, Table, Tooltip } from 'antd'

import EditServiceReviewModal from './EditServiceReviewModal'
import Pagination from '../../../components/Pagination'

import { deleteServiceReview } from '../services'
import { handleError } from '../../../helpers'

export default function ServiceReviewsTable({ serviceReviews, reload, pagination, setPagination }) {
	const [editModalRecord, setEditModalRecord] = useState(null)
	
	const columns = [
		{
			title: 'Consecutivo',
			dataIndex: 'sku',
			render: (t, r) => <Link to={`/service_reviews/${r.id}`}>{t}</Link>
		},
		{
			title: 'Empresa',
			dataIndex: 'service',
			render: t => t.location.company.legal_name
		},
		{
			title: 'Sede',
			dataIndex: 'service',
			render: t => t.location.name
		},
		{
			title: 'Servicio',
			dataIndex: 'service',
			render: t => t.service_format.name
		},
		{
			title: 'Acciones',
			width: '125px',
			render: (t, r) => (
				<React.Fragment>
					<Tooltip title="Abrir Diagnóstico">
						<React.Fragment>
							<Link to={`/service_reviews/${r.id}`}>
								<FontAwesomeIcon icon={faMagnifyingGlass} className="text-link" />
							</Link>
						</React.Fragment>
					</Tooltip>
					<Divider type='vertical' />
					<Tooltip title="Imprimir Diagnóstico">
						<React.Fragment>
							<Link to={`/service_reviews/preview/${r.id}`} target="_blank">
								<FontAwesomeIcon icon={faPrint} className="text-link" />
							</Link>
						</React.Fragment>
					</Tooltip>
					<Divider type='vertical' />
					<Tooltip title="Editar" onClick={() => setEditModalRecord(r)}>
						<React.Fragment>
							<FontAwesomeIcon icon={faEdit} className="text-link" />
						</React.Fragment>
					</Tooltip>
					<Divider type='vertical' />
					<Popconfirm
						title="Esta acción no se puede revertir"
						okText="Eliminar"
						cancelText="Cancelar"
						okButtonProps={{ danger:true }}
						onConfirm={() => handleDelete(r.id)}
					>
						<FontAwesomeIcon icon={faTrash} className="text-link" />
					</Popconfirm>
				</React.Fragment>
			)
		}
	]

	const handleDelete = id => {
		deleteServiceReview(id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(handleError)
	}
	
	return (
		<React.Fragment>
			<Table 
				dataSource={serviceReviews}
				rowKey={r => r.id}
				pagination={false}
				columns={columns}
				loading={!serviceReviews}
				size="small"
				locale={{ emptyText: (
					<Empty
						description="No se encontraron registros" 
						imageStyle={{ height: '80px' }}
					/> 
				)}}
			/>
			<Pagination 
				pagination={pagination}
				setPagination={setPagination}
				reload={reload}
				className="mb-0"
			/>
			{ editModalRecord && (
				<EditServiceReviewModal 
					visible
					onClose={() => setEditModalRecord(null)}
					serviceReview={editModalRecord}
					reload={reload}
					editable={{ valid_until: true }}
				/>
			)}
		</React.Fragment>
	)
}