import { message } from 'antd'
import React from 'react'
import { Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'

import { updateUser } from '../services'
import { handleError } from '../../../helpers'

export default function UserPermissionsModal({ visible, onClose, user, reload }) {
	const { handleSubmit, register } = useForm()

	const onSubmit = values => {
		updateUser(user.id, { 
			permissions: JSON.stringify(values.permissions) 
		})
			.then(res => {
				message.success(res.data.message)
				reload()
				onClose()
			})
			.catch(handleError)
	}

	const FormCheck = ({ slug, label }) => (
		<Form.Check 
			inline
			type="checkbox"
			{...register('permissions')}
			defaultChecked={user.permissions.includes(slug)}
			value={slug}
			label={label}
		/>
	)

	return (
		<Modal isOpen={visible} toggle={onClose}>
			<ModalHeader toggle={onClose}>Permisos del Usuario</ModalHeader>
			<ModalBody>
				<Form onSubmit={handleSubmit(onSubmit)}>
					<Form.Group>
						<FormCheck label="Ver Reportes Administradores" slug="view_reports" />
						<FormCheck label="Consultar Usuarios" slug="view_users" />
						<FormCheck label="Actualizar Usuarios" slug="edit_users" />
						<FormCheck label="Eliminar Usuarios" slug="delete_users" />
						<FormCheck label="Consultar Vehículos" slug="view_vehicles" />
						<FormCheck label="Actualizar Vehículos" slug="edit_vehicles" />
						<FormCheck label="Eliminar Vehículos" slug="delete_vehicles" />
						<FormCheck label="Consultar Técnicos" slug="view_technicians" />
						<FormCheck label="Actualizar Técnicos" slug="edit_technicians" />
						<FormCheck label="Eliminar Técnicos" slug="delete_technicians" />
						<FormCheck label="Consultar, Actualizar y Eliminar Clientes/Sedes/Contactos" slug="view_companies" />
						<FormCheck label="Eliminar Servicios" slug="delete_services" />
						<FormCheck label="Actualizar Facturas y Ctas de Cobro" slug="update_invoice_status" />
					</Form.Group>
					<Button color='primary' type="submit">
						Actualizar Permisos
					</Button>
				</Form>
			</ModalBody>
		</Modal>
	)
}