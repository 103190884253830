import React, { useEffect, useState } from 'react'
import { Spinner, Button } from 'react-bootstrap'
import { Divider, Empty } from 'antd'

import ServiceEquipmentCard from './partials/ServiceEquipmentCard'
import NewServiceEquipmentModal from './partials/NewServiceEquipmentModal'

import { getServiceEquipments } from './services'
import { handleError } from '../../helpers'

export default function ServiceEquipmentsWidget({ service }) {
	const [serviceEquipments, setServiceEquipments] = useState(null)
	const [showNewRecordModal, setShowNewRecordModal] = useState(false)

	useEffect(() => {
		!serviceEquipments && getServiceEquipments({
			'filter[service_id]': service.id,
			include: 'equipmentType'
		})
			.then(res => setServiceEquipments(res.data.data))
			.catch(handleError)
	}, [ serviceEquipments, service ])

	if(!serviceEquipments) 
		return <Spinner animation='border' size="sm" />

	return (
		<React.Fragment>
			{ serviceEquipments.length > 0 ? (
				<React.Fragment>
					{ serviceEquipments.map(item => (
						<ServiceEquipmentCard 
							key={item.id}
							serviceEquipment={item}
							reload={() => setServiceEquipments(null)}
						/>
					)) }
				</React.Fragment>
			) : (
				<Empty 
					description={`No se tienen equipos registrados`}
					imageStyle={{ maxHeight: '75px' }}
				/>
			)}
			<Divider className='my-15' />
			<div className='text-center'>
				<Button 
					variant='outline-primary' 
					size="sm" 
					onClick={() => setShowNewRecordModal(true)}
				>
					Registrar Equipo Utilizado
				</Button>
			</div>
			{ showNewRecordModal && (
				<NewServiceEquipmentModal 
					visible
					onClose={() => setShowNewRecordModal(false)}
					reload={() => setServiceEquipments(null)}
					service={service}
				/>
			)}
		</React.Fragment>
	)
}