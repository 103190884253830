import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'

export default function LocationForm({ register, errors, editable }) {

	return (
		<React.Fragment>
			<Form.Group className='form-group'>
				<Form.Label>Tipo de Empresa <span className='text-danger'>*</span></Form.Label>
				<Form.Control
					as="select"
					{...register('type', { required: true })}
					disabled={editable}
				>
					<option value="">:: Seleccione una opción ::</option>
					<option value="cliente">Cliente</option>
					<option value="proveedor">Proveedor</option>
				</Form.Control>
			</Form.Group>
			<Form.Group className='form-group'>
				<Form.Label>Razón Social <span className='text-danger'>*</span></Form.Label>
				<Form.Control 
					{...register('legal_name', { required: true })}
					placeholder="Escriba aquí..."
				/>
				{ errors.legal_name && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
			<Form.Group className='form-group'>
				<Form.Label>Nombre Comercial <small>(opcional)</small></Form.Label>
				<Form.Control 
					{...register('commercial_name', {})}
					placeholder="Escriba aquí..."
				/>
			</Form.Group>
			<Row>
				<Form.Group className='form-group' as={Col}>
					<Form.Label>NIT <span className='text-danger'>*</span></Form.Label>
					<Form.Control
						type="number"
						{...register('document', { required: true })}
						placeholder="Escriba aquí..."
						disabled={editable}
					/>
					{ errors.document && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
				<Form.Group className='form-group' as={Col}>
					<Form.Label>Dígito de Verificación <small>(opcional)</small></Form.Label>
					<Form.Control 
						type="number"
						{...register('document_suffix', { })}
						placeholder="Escriba aquí..."
						disabled={editable}
					/>
				</Form.Group>
			</Row>
			<Form.Group className='form-group'>
				<Form.Label>Regimen <span className='text-danger'>*</span></Form.Label>
				<Form.Control 
					as="select"
					{...register('regimen', { required: true })}
				>
					<option value="">:: Seleccione una opción ::</option>
					<option>Común</option>
					<option>Simplificado</option>
					<option>Especial</option>
				</Form.Control>
				{ errors.regimen && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
		</React.Fragment>
	)
}