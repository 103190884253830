import ingealimentosApi, { getDomain } from '../../services/ingealimentosApi'

export const getResultActivities = params => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.get('/result_activities', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getResultActivity = (id, params) => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.get(`/result_activities/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeResultActivity = data => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.post('/result_activities', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateResultActivity = (id, data) => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.put(`/result_activities/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteResultActivity = id => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.delete(`/result_activities/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchResultActivities = async (term, token) => (
	fetch( getDomain()+`/result_activities?filter[name]=${term}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(item => ({
				label: item.name,
				value: item.id,
			})),
		)
)