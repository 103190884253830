import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import ServiceReviewDocument from './partials/ServiceReviewDocument'

import { getServiceReview } from './services'
import { handleError } from '../../helpers'

export default function ServiceReviewPreview(props) {
	const serviceReviewId = props.match.params.id
	const [serviceReview, setServiceReview] = useState(null)

	useEffect(() => {
		setTimeout(() => window.print(), 2500);
	}, [])

	useEffect(() => { 
		if(serviceReview && serviceReview.id !== parseInt(serviceReviewId)) 
			setServiceReview(null) 
	}, [ serviceReview, serviceReviewId ])

	useEffect(() => {
		!serviceReview && getServiceReview(serviceReviewId, {
			include: 'reviewFormat,service.serviceFormat,service.location.company,reviewTasks,reviewResults,technician,service.employee'
		})
			.then(res => setServiceReview(res.data.data))
			.catch(handleError)
	}, [ serviceReviewId, serviceReview ])

	return (
		<React.Fragment>
			<PreviewContainer className='printFormatContainer'>
				<ServiceReviewDocument serviceReview={serviceReview} />
			</PreviewContainer>
		</React.Fragment>
	)
}

const PreviewContainer = styled.div`
	background-color: #F8F9FA;
	padding: 10px;
	max-width: 100%;
	overflow-x: scroll;
`