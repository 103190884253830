import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { Empty, Table, Tooltip } from 'antd'

import EditItemTypeModal from './EditItemTypeModal'
import { Badge } from 'reactstrap'

export default function ItemTypesTable({ itemTypes, reload, pagination, setPagination }) {
	const [editModalRecord, setEditModalRecord] = useState(null)
	
	const columns = [
		{
			title: 'Categoría',
			dataIndex: 'scope',
			render: t => t === 'product' ? 'Producto' : 'Servicio'
		},
		{
			title: 'Producto',
			dataIndex: 'name'
		},
		{
			title: 'Número de Lote',
			dataIndex: 'default_lote_no',
			render: t => t ? t : <em className='text-muted small'>Sin valor por defecto</em>
		},
		{
			title: 'Fecha Fabricación',
			dataIndex: 'default_lote_ff',
			render: t => t ? t : <em className='text-muted small'>Sin valor por defecto</em>
		},
		{
			title: 'Fecha Vencimiento',
			dataIndex: 'default_lote_fv',
			render: t => t ? t : <em className='text-muted small'>Sin valor por defecto</em>
		},
		{
			title: 'Estado',
			dataIndex: 'status',
			render: t => <Badge color={t.color}>{t.label}</Badge>
		},
		{
			title: 'Acciones',
			render: (t, r) => (
				<React.Fragment>
					<Tooltip title="Editar" onClick={() => setEditModalRecord(r)} className="text-link">
						<React.Fragment>
							<FontAwesomeIcon icon={faEdit} />{" "}
							Editar
						</React.Fragment>
					</Tooltip>
				</React.Fragment>
			)
		}
	]
	
	return (
		<React.Fragment>
			<Table 
				dataSource={itemTypes}
				rowKey={r => r.id}
				columns={columns}
				loading={!itemTypes}
				size="small"
				locale={{ emptyText: (
					<Empty
						description="No se encontraron registros" 
						imageStyle={{ height: '80px' }}
					/> 
				)}}
			/>
			{ editModalRecord && (
				<EditItemTypeModal 
					visible
					onClose={() => setEditModalRecord(null)}
					itemType={editModalRecord}
					reload={reload}
				/>
			)}
		</React.Fragment>
	)
}