import React, { useState } from 'react'
import { faEdit, faUnlink } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Divider, message, Popconfirm, Tooltip } from 'antd'

import EditServiceItemModal from './EditServiceItemModal'
import ListItemCard from '../../../components/ListItemCard'

import { deleteServiceItem } from '../services'
import { handleError } from '../../../helpers'

export default function ServiceItemCard({ serviceItem, reload, scope }) {
	const [showEditModal, setShowEditModal] = useState(false)

	const handleDelete = () => {
		deleteServiceItem(serviceItem.id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(handleError)
	}

	return (
		<React.Fragment>
			<ListItemCard>
				<div className='float-right'>
					{ scope === 'product' && (
						<React.Fragment>
							<Tooltip title="Editar" onClick={() => setShowEditModal(true)}>
								<FontAwesomeIcon className='text-link' icon={faEdit} />
							</Tooltip>
							<Divider type="horizontal" className='my-5' />
						</React.Fragment>
					)}
					<Tooltip title="Desasociar" placement='bottom'>
						<Popconfirm
							title="Esta acción no se puede revertir"
							okText="Desasociar"
							cancelText="Cancelar"
							okButtonProps={{ danger:true }}
							onConfirm={handleDelete}
						>
							<FontAwesomeIcon className='text-link' icon={faUnlink} />
						</Popconfirm>
					</Tooltip>
				</div>
				<p className='bold mb-3'>{serviceItem.item_type.name}</p>
				{ scope === 'product' && (
					<p className='mb-0 text-muted small'>
						F.F. {serviceItem.lote_ff} | F.V. {serviceItem.lote_fv} | LOTE: {serviceItem.lote_no}
					</p>
				)}
				<p className='mb-0 text-muted small'>
					{scope === 'product' ? 'Asociado' : 'Autorizado'} el {serviceItem.created_at}
				</p>
			</ListItemCard>
			{ showEditModal && (
				<EditServiceItemModal 
					visible
					onClose={() => setShowEditModal(false)}
					serviceItem={serviceItem}
					reload={reload}
					scope={scope}
				/>
			)}
		</React.Fragment>
	)
}