import React from 'react'

export default function ReportDocResultDetails({ serviceReport, formatSettings }) {

	const resultDetailOpts = formatSettings.filter(r => r.setting_type === 'detail_type')

	const hasValueSet = setting => {
		return serviceReport.report_details.filter(i => i.detail_type_id === setting.id).length > 0
	}

	if(resultDetailOpts.length === 0) return null

	return (
		<React.Fragment>
			<div className='mt-10'>
				<h2 className='mb-10 bold'>RESULTADOS</h2>
				{ resultDetailOpts.map( opt => (
					<h2 className='inline-block ml-15 mb-0' key={opt.id}>
						<div className='detailRow'>
							<p className='detailLabel'>
								{ opt.setting.name }
								<span className='ml-10 bold'>
									{ hasValueSet(opt.setting) ? serviceReport.report_details.filter(i => i.detail_type_id === opt.setting.id)[0].comment : <small>N/A</small> }
								</span>
							</p>				
						</div>
					</h2>
				)) }
			</div>
		</React.Fragment>
	)
}