import React, { useState } from 'react'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form, Spinner } from 'react-bootstrap'
import { message } from 'antd'

import ServerSideSelect from '../../../components/ServerSideSelect'

import { attachEmployeeLocation } from '../../Employees/services'
import { searchLocations } from '../../Locations/services'
import { handleError } from '../../../helpers'

export default function AttachLocationToEmployeeModal({ visible, onClose, reload, employee }) {
    const [selectedLocation, setSelectedLocation] = useState([])
    const [loading, setLoading] = useState(false)

    const onSubmit = () => {
        if(!selectedLocation)
            return message.warning('Debe seleccionar primer un contacto')
        
        setLoading(true)
        attachEmployeeLocation(employee.id, selectedLocation.value)
            .then(res => {
                message.success(res.data.message)
                reload()
                onClose()
            })
            .catch(error => handleError(error, setLoading))
    }

    return (
        <Modal isOpen={visible} toggle={onClose}>
            <ModalHeader toggle={onClose}>Asociar Sede a Contacto</ModalHeader>
            <ModalBody>
                <Form.Group className="form-group">
                    <Form.Label>Buscar Sede <span className='text-danger'>*</span></Form.Label>
                    <ServerSideSelect
						value={selectedLocation}
						placeholder="Buscar por nombre de sede"
						fetchOptions={searchLocations}
                        scopeId={employee.company_id}
						onChange={record => setSelectedLocation(record)}
						className="full-width"
					/>
                </Form.Group>
                <Button
                    color="primary"
                    outline
                    onClick={onSubmit}
                    disabled={loading}
                >
                    { loading && <Spinner animation='border' size="sm" /> }{" "}
                    Asociar Sede
                </Button>
            </ModalBody>
        </Modal>
    )
}