import React, { useState } from 'react'
import { Form, Spinner } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { message } from 'antd'

import TechnicianForm from './TechnicianForm'

import { updateTechnician } from '../services'
import { handleError } from '../../../helpers'

export default function EditTechnicianModal({ visible, onClose, technician, reload }) {
	const { handleSubmit, register, formState: { errors } } = useForm({ defaultValues: technician })
	const [loading, setLoading] = useState(false)

	const onSubmit = values => {
		setLoading(true)
		if(parseInt(values.document) === technician.document) delete values.document
		if(values.password === '') delete values.password
		updateTechnician(technician.id, values)
			.then(res => {
				message.success(res.data.message)
				reload()
				onClose()
			})
			.catch(error => handleError(error, setLoading))
	}

	return (
		<Modal isOpen={visible} toggle={onClose}>
			<ModalHeader toggle={onClose}>Actualizar Registro</ModalHeader>
			<ModalBody>
				<Form onSubmit={handleSubmit(onSubmit)}>
					<TechnicianForm 
						register={register}
						errors={errors}
						editable
					/>
					<Button color='primary' type="submit" disabled={loading}>
						{ loading && <Spinner animation="border" size="sm" className='mr-1' /> }
						Actualizar Registro
					</Button>
				</Form>
			</ModalBody>
		</Modal>
	)
}