import React, { useEffect, useState } from 'react'
import { Spinner, Button } from 'react-bootstrap'
import { Divider, Empty } from 'antd'

import ServiceDeliveredDocumentCard from './partials/ServiceDeliveredDocumentCard'
import ToggleServiceDeliveredDocumentsModal from './partials/ToggleServiceDeliveredDocumentsModal'

import { getServiceDeliveredDocuments } from './services'
import { handleError } from '../../helpers'

export default function ServiceDeliveredDocumentsWidget({ service }) {
	const [serviceDeliveredDocuments, setServiceDeliveredDocuments] = useState(null)
	const [showToggleRecordsModal, setShowToggleRecordsModal] = useState(false)

	useEffect(() => {
		!serviceDeliveredDocuments && getServiceDeliveredDocuments({
			'filter[service_id]': service.id,
			include: 'deliveredDocumentType'
		})
			.then(res => setServiceDeliveredDocuments(res.data.data))
			.catch(handleError)
	}, [ serviceDeliveredDocuments, service ])

	if(!serviceDeliveredDocuments) 
		return <Spinner animation='border' size="sm" />

	return (
		<React.Fragment>
			{ serviceDeliveredDocuments.length > 0 ? (
				<React.Fragment>
					{ serviceDeliveredDocuments.map(item => (
						<ServiceDeliveredDocumentCard 
							key={item.id}
							serviceDeliveredDocument={item}
							reload={() => setServiceDeliveredDocuments(null)}
						/>
					)) }
				</React.Fragment>
			) : (
				<Empty 
					description={`No se tienen documentos entregados`}
					imageStyle={{ maxHeight: '75px' }}
				/>
			)}
			<Divider className='my-15' />
			<div className='text-center'>
				<Button 
					variant='outline-primary' 
					size="sm" 
					onClick={() => setShowToggleRecordsModal(true)}
				>
					Actualizar Documentos Entregados
				</Button>
			</div>
			{ showToggleRecordsModal && (
				<ToggleServiceDeliveredDocumentsModal 
					visible
					onClose={() => setShowToggleRecordsModal(false)}
					reload={() => setServiceDeliveredDocuments(null)}
					service={service}
					serviceDeliveredDocuments={serviceDeliveredDocuments}
				/>
			)}
		</React.Fragment>
	)
}