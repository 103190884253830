import React, { useState, useEffect } from 'react'
import { Card, CardBody } from 'reactstrap'

import ListTopBar from '../../components/ListTopBar'

import ItemTypesTable from './partials/ItemTypesTable'
import NewItemTypeModal from './partials/NewItemTypeModal'

import { handleError } from '../../helpers'
import { getItemTypes } from './services'

export default function ItemTypesList() {
	const [itemTypes, setItemTypes] = useState(null)
	const [filterType, setFilterType] = useState('name')
	const [filterValue, setFilterValue] = useState('')
	const [filterScope, setFilterScope] = useState('')
	const [showNewItemTypeModal, setShowNewItemTypeModal] = useState(false)

	useEffect(() => {
		!itemTypes && getItemTypes({
			[`filter[${filterType}]`]: filterValue,
			[filterScope ? `filter[${filterScope}]` : 'noscope']: 1,
			sort: '-name'
		})
			.then(res => setItemTypes(res.data.data))
			.catch(error => handleError(error))
	}, [ itemTypes, filterType, filterValue, filterScope ])

	return (
		<React.Fragment>
			<ListTopBar 
				title="Administrar Productos y Servicios"
				subtitle="Listado de productos y servicios disponibles en la plataforma"
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				reload={() => setItemTypes(null)}
				options={[
					{ label: 'Buscar por nombre', value: 'name' },
				]}
				filterScope={filterScope}
				setFilterScope={setFilterScope}
				scopes={[
					{ label: 'Solo items activos', value: 'active' },
					{ label: 'Solo items inactivos', value: 'inactive' },
					{ label: 'Ver solo productos', value: 'only_products' },
					{ label: 'Ver solo servicios', value: 'only_services' },
				]}
				ctaText="Nuevo Producto/Servicio"
				handleCtaClick={() => setShowNewItemTypeModal(true)}
			/>
			<Card>
				<CardBody>
					<ItemTypesTable 
						itemTypes={itemTypes}
						reload={() => setItemTypes(null)}
					/>
				</CardBody>
			</Card>
			{ showNewItemTypeModal && (
				<NewItemTypeModal 
					visible
					onClose={() => setShowNewItemTypeModal(false)}
					reload={() => setItemTypes(null)}
				/>
			)}
		</React.Fragment>
	)
}