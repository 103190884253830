import React, { useState } from 'react'
import { faEdit, faUnlink } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Divider, message, Popconfirm, Tooltip } from 'antd'

import EditServiceTechnicianModal from './EditServiceTechnicianModal'
import ListItemCard from '../../../components/ListItemCard'

import { deleteServiceTechnician } from '../services'
import { handleError } from '../../../helpers'

export default function ServiceTechnicianCard({ serviceTechnician, reload }) {
	const [showEditModal, setShowEditModal] = useState(false)

	const handleDelete = () => {
		deleteServiceTechnician(serviceTechnician.id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(handleError)
	}

	return (
		<React.Fragment>
			<ListItemCard>
				<div className='float-right'>
					<Tooltip title="Editar" onClick={() => setShowEditModal(true)}>
						<FontAwesomeIcon className='text-link' icon={faEdit} />
					</Tooltip>
					<Divider type="horizontal" className='my-5' />
					<Tooltip title="Desasociar" placement='bottom'>
						<Popconfirm
							title="Esta acción no se puede revertir"
							okText="Desasociar"
							cancelText="Cancelar"
							okButtonProps={{ danger:true }}
							onConfirm={handleDelete}
						>
							<FontAwesomeIcon className='text-link' icon={faUnlink} />
						</Popconfirm>
					</Tooltip>
				</div>
				<p className='bold mb-3'>
					{serviceTechnician.technician.name}{" "}
					<small>(CC {serviceTechnician.technician.document})</small>
				</p>
				<p className='mb-0 text-muted small'>Trabajo asignado: {serviceTechnician.job_role}</p>
				<p className='mb-0 text-muted small'>Asociado el {serviceTechnician.created_at}</p>
			</ListItemCard>
			{ showEditModal && (
				<EditServiceTechnicianModal 
					visible
					onClose={() => setShowEditModal(false)}
					serviceTechnician={serviceTechnician}
					reload={reload}
				/>
			)}
		</React.Fragment>
	)
}