import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash, faUsers } from '@fortawesome/free-solid-svg-icons'
import { Divider, Empty, message, Popconfirm, Table, Tooltip } from 'antd'

import EditLocationModal from './EditLocationModal'
import LocationEmployeesModal from './LocationEmployeesModal'
import Pagination from '../../../components/Pagination'

import { deleteLocation } from '../services'
import { handleError } from '../../../helpers'

export default function LocationsTable({ locations, reload, pagination, setPagination }) {
	const [editRecordModal, setEditRecordModal] = useState(null)
	const [locationEmployeesModal, setLocationEmployeesModal] = useState(null)
	
	const columns = [
		{
			title: 'Id',
			dataIndex: 'id'
		},
		{
			title: 'Empresa',
			dataIndex: 'company',
			render: t => t.commercial_name ? t.commercial_name : t.legal_name
		},
		{
			title: 'Nombre de Sede',
			dataIndex: 'name'
		},
		{
			title: 'Dirección',
			dataIndex: 'address',
			render: (t, r) => `${t}, ${r.address_extra}`
		},
		{
			title: 'Ciudad',
			dataIndex: 'city',
		},
		{
			title: 'Departamento',
			dataIndex: 'department',
		},
		{
			title: 'Acciones',
			width: '100px',
			render: (t, r) => (
				<React.Fragment>
					<Tooltip title="Editar" onClick={() => setEditRecordModal(r)}>
						<React.Fragment>
							<FontAwesomeIcon icon={faEdit} className="text-link" />
						</React.Fragment>
					</Tooltip>
					<Divider type='vertical' />
					<Tooltip title="Contactos Sede" onClick={() => setLocationEmployeesModal(r)}>
						<React.Fragment>
							<FontAwesomeIcon icon={faUsers} className="text-link" />
						</React.Fragment>
					</Tooltip>
					<Divider type='vertical' />
					<Tooltip title="Eliminar">
						<React.Fragment>
							<Popconfirm
								title="Esta acción no se puede revertir"
								okText="Eliminar"
								cancelText="Cancelar"
								okButtonProps={{ danger:true }}
								onConfirm={() => handleDelete(r.id)}
							>
								<FontAwesomeIcon icon={faTrash} className="text-link" />
							</Popconfirm>
						</React.Fragment>
					</Tooltip>
				</React.Fragment>
			)
		}
	]

	const handleDelete = id => {
		deleteLocation(id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(handleError)
	}
	
	return (
		<React.Fragment>
			<Table 
				dataSource={locations}
				rowKey={r => r.id}
				pagination={false}
				columns={columns}
				loading={!locations}
				size="small"
				locale={{ emptyText: (
					<Empty
						description="No se encontraron registros" 
						imageStyle={{ height: '80px' }}
					/> 
				)}}
			/>
			<Pagination 
				pagination={pagination}
				setPagination={setPagination}
				reload={reload}
				className="mb-0"
			/>
			{ editRecordModal && (
				<EditLocationModal 
					visible
					onClose={() => setEditRecordModal(null)}
					location={editRecordModal}
					reload={reload}
				/>
			)}
			{ locationEmployeesModal && (
				<LocationEmployeesModal 
					visible
					onClose={() => setLocationEmployeesModal(null)}
					location={locationEmployeesModal}
				/>
			)}
		</React.Fragment>
	)
}