import ingealimentosApi, { getDomain } from '../../services/ingealimentosApi'

export const getServiceVehicles = params => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.get('/service_vehicles', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getServiceVehicle = (id, params) => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.get(`/service_vehicles/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeServiceVehicle = data => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.post('/service_vehicles', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateServiceVehicle = (id, data) => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.put(`/service_vehicles/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteServiceVehicle = id => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.delete(`/service_vehicles/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchServiceVehicles = async (term, token) => (
	fetch( getDomain()+`/service_vehicles?filter[name]=${term}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(item => ({
				label: item.name,
				value: item.id,
			})),
		)
)