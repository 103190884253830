import ingealimentosApi, { getDomain } from '../../services/ingealimentosApi'

export const getVehicles = params => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.get('/vehicles', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getVehicle = (id, params) => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.get(`/vehicles/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeVehicle = data => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.post('/vehicles', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateVehicle = (id, data) => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.put(`/vehicles/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteVehicle = id => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.delete(`/vehicles/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchVehicles = async (term, token) => (
	fetch( getDomain()+`/vehicles?filter[placas]=${term}&filter[active]=1`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(item => ({
				label: `${item.placas} (${item.name})`,
				value: item.id,
			})),
		)
)