import React, { useEffect, useState } from 'react'
import { Form, Spinner } from 'react-bootstrap'

import { getFormatTypes } from '../../FormatTypes/services'
import { handleError } from '../../../helpers'

export default function ServiceEquipmentForm({ register, errors, editable, service }) {
	const [options, setOptions] = useState(null)

	useEffect(() => {
		!options && service && getFormatTypes({
			'filter[format_type]':'report_format',
			'filter[format_id]': service.service_format.report_format_id,
			'filter[setting_type]': 'equipment_type',
			include: 'setting'
		})
			.then(res => setOptions(res.data.data))
			.catch(handleError)
	}, [ options, service ])

	if(!options && service) return <div className='mb-10'><Spinner animation='border' size="sm" /></div>

	return (
		<React.Fragment>
			{ !editable && (
				<Form.Group className='form-group'>
					<Form.Label>Equipo Utilizado <span className='text-danger'>*</span></Form.Label>
					<Form.Control 
						as="select"
						{...register('equipment_type_id', { required: true })}
					>
						<option value="">:: Seleccione una opción ::</option>
						{ options.map(opt => (
							<option key={opt.setting.id} value={opt.setting.id}>
								{opt.setting.name}
							</option>
						)) }
					</Form.Control>
					{ errors.equipment_type_id && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
			)}
			<Form.Group className='form-group'>
				<Form.Label>Dosificación <small>(opcional)</small></Form.Label>
				<Form.Control 
					{...register('dosification', {})}
					placeholder="Escriba aquí..."
				/>
			</Form.Group>
		</React.Fragment>
	)
}