import React, { useState, useEffect } from 'react'
import { Card, CardBody } from 'reactstrap'

import ListTopBar from '../../components/ListTopBar'

import CompaniesTable from './partials/CompaniesTable'
import NewCompanyModal from './partials/NewCompanyModal'

import { handleError, parsePagination } from '../../helpers'
import { getCompanies } from './services'

export default function CompaniesList() {
	const [companies, setCompanies] = useState(null)
	const [filterType, setFilterType] = useState('legal_name')
	const [filterValue, setFilterValue] = useState('')
	const [filterScope, setFilterScope] = useState('active')
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})
	const [showNewRecordModal, setShowNewRecordModal] = useState(false)

	useEffect(() => {
		!companies && getCompanies({
			...pagination,
			[`filter[${filterType}]`]: filterValue,
			[filterScope ? `filter[${filterScope}]` : 'noscope']: 1,
			include: ''
		})
			.then(res => {
				setCompanies(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [ companies, pagination, filterType, filterValue, filterScope ])

	return (
		<React.Fragment>
			<ListTopBar 
				title="Empresas Vinculadas"
				subtitle="Listado de clientes y proveedores registrados"
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				filterScope={filterScope}
				setFilterScope={setFilterScope}
				reload={() => setCompanies(null)}
				options={[
					{ label: 'Buscar por razón social', value: 'legal_name' },
					{ label: 'Buscar por nombre comercial', value: 'commercial_name' },
					{ label: 'Buscar por documento', value: 'document' },
					{ label: 'Buscar por tipo (cliente o proveedor)', value: 'type' },
				]}
				scopes={[
					{ label: 'Empresas Activas', value: 'active' },
					{ label: 'Empresas Inactivas', value: 'inactive' },
				]}
				ctaText="Agregar Registro"
				handleCtaClick={() => setShowNewRecordModal(true)}
			/>
			<Card>
				<CardBody>
					<CompaniesTable 
						companies={companies}
						reload={() => setCompanies(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
			{ showNewRecordModal && (
				<NewCompanyModal 
					visible
					onClose={() => setShowNewRecordModal(false)}
					reload={() => setCompanies(null)}
				/>
			)}
		</React.Fragment>
	)
}