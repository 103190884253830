import React, { useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'

import ServerSideSelect from '../../../components/ServerSideSelect'
import { searchTechnicians } from '../../Technicians/services'

export default function ServiceReportForm({ register, errors, editable, setValue }) {
	const [selectedTechnicial, setSelectedTechnicial] = useState([])

	return (
		<React.Fragment>
			{ !editable && (
				<React.Fragment>
					{ !editable && (
						<Form.Group className='form-group'>
							<Form.Label>Técnico a cargo del diagnóstico <span className='text-danger'>*</span></Form.Label>
							<ServerSideSelect
								value={selectedTechnicial}
								placeholder="Buscar por nombre del técnico"
								fetchOptions={searchTechnicians}
								onChange={record => {
									setSelectedTechnicial(record)
									setValue('technician_id', record.value)
								}}
								className="full-width"
							/>
							<Form.Control {...register('technician_id',{required:true})} style={{ display:'none' }} />
							{errors.technician_id && <Form.Text className='text-warning'>Este campo es requerido</Form.Text>}
						</Form.Group>
					)}
				</React.Fragment>
			)}
			<Row>
				<Form.Group as={Col} className='form-group'>
					<Form.Label>Fecha del diagnóstico <span className='text-danger'>*</span></Form.Label>
					<Form.Control 
						type="date"
						{...register('review_at', { required:true })}
					/>
					{ errors.review_at && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
				<Form.Group as={Col} className='form-group'>
					<Form.Label>Próximo diagnóstico <small>(opcional)</small></Form.Label>
					<Form.Control 
						type="date"
						{...register('next_review_at', { required:true })}
					/>
					{ errors.next_review_at && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
			</Row>
			<Form.Group className='form-group'>
				<Form.Label>Objetivo <small>(opcional)</small></Form.Label>
				<Form.Control 
					as="textarea"
					{...register('objective', {})}
					placeholder="Escribe aquí..."
					style={{ height: '80px' }}
				/>
			</Form.Group>
			<Form.Group className='form-group'>
				<Form.Label>Alcance <small>(opcional)</small></Form.Label>
				<Form.Control 
					as="textarea"
					{...register('reach', {})}
					placeholder="Escribe aquí..."
					style={{ height: '80px' }}
				/>
			</Form.Group>
		</React.Fragment>
	)
}