import React, { useState } from 'react'
import { Descriptions, Divider } from 'antd'
import { Spinner } from 'react-bootstrap'
import { Button } from 'reactstrap'

import EditServiceReviewModal from '../EditServiceReviewModal'

const { Item } = Descriptions

export default function ReviewInfoWidget({ serviceReview, reload }) {
	const [showEditModal, setShowEditModal] = useState(false)

	if(!serviceReview) 
		return <Spinner animation='border' size="sm" />

	return (
		<React.Fragment>
			<Descriptions column={1}>
				<Item label="Consecutivo">{serviceReview.sku}</Item>
				<Item label="Técnico asignado">{serviceReview.technician.name}</Item>
				<Item label="Fecha del diagnósitico">{serviceReview.review_at}</Item>
				<Item label="Fecha próximo diagnósitico">{serviceReview.next_review_at}</Item>
			</Descriptions>
			<p className='item-label'>Objetivo:</p>
			<p>{serviceReview.objective ? serviceReview.objective : <em>sin registro</em>}</p>
			<p className='item-label'>Alcance:</p>
			<p>{serviceReview.reach ? serviceReview.reach : <em>sin registro</em>}</p>
			<Divider className='my-15' />
			<div className='text-center'>
				<Button
					color='primary'
					outline
					size="sm" 
					onClick={() => setShowEditModal(true)}
				>
					Actualizar Detalles del Diagnósitico
				</Button>
			</div>
			{ showEditModal && (
				<EditServiceReviewModal 
					visible
					onClose={() => setShowEditModal(false)}
					serviceReview={serviceReview}
					reload={reload}
					editable={{
						valid_until: true,
					}}
				/>
			)}
		</React.Fragment>
	)
}