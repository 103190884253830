import React from 'react'
import { Form } from 'react-bootstrap'
import { Col, Row } from 'reactstrap'

export default function ReviewTaskForm({ register, errors, editable, serviceReview }) {

	return (
		<React.Fragment>
			<Form.Group className='form-group'>
				<Form.Label>Aspecto a verificar <span className='text-danger'>*</span></Form.Label>
				<Form.Control 
					as="textarea"
					{...register('task_name', { required: true })}
					style={{ height: '80px' }}
					placeholder="Escriba aquí"
				/>
				{ errors.task_name && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
			<Row>
				<Form.Group as={Col} className="form-group">
					<Form.Label>Posición <span className='text-danger'>*</span></Form.Label>
					<Form.Control 
						type="number"
						{...register('task_order', { required:true })}
						placeholder="0"
					/>
					{ errors.task_order && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
				<Form.Group as={Col} className="form-group">
					<Form.Label>Estado <span className='text-danger'>*</span></Form.Label>
					<Form.Control 
						as="select"
						{...register('is_meet', { required:true })}
					>
						<option value="">:: Seleccione una opción ::</option>
						<option value="1">Cumple</option>
						<option value="0">No Cumple</option>
					</Form.Control>
					{ errors.is_meet && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
			</Row>
			<Form.Group className='form-group'>
				<Form.Label>Observaciones <span className='text-danger'>*</span></Form.Label>
				<Form.Control 
					{...register('observations', { required:true })}
					placeholder="Escriba aquí..."
					style={{ height: '80px' }}
				/>
				{ errors.observations && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
		</React.Fragment>
	)
}