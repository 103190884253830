import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faCircleCheck } from '@fortawesome/free-solid-svg-icons'

export default function ReportDocProductItems({ serviceReport, formatSettings }) {

	const productItemOpts = formatSettings.filter(r => r.setting.scope === 'product')

	const isRelatedToProduct = setting => {
		return serviceReport.service.service_items.filter(i => i.item_type_id === setting.id).length > 0
	}

	return (
		<React.Fragment>
			<div className='detailsDiv'>
				<h2 className='inline-block ml-30 mb-0 bold'>Productos Utilizados</h2>
				{ productItemOpts.map( opt => (
					<h2 className='inline-block ml-30 mb-0' key={opt.id}>
						{ isRelatedToProduct(opt.setting) ? (
							<React.Fragment>
								<FontAwesomeIcon color='#333' icon={faCircleCheck} className="mr-5" />
								{ opt.setting.name }{" "}
								<span className='ml-5 text-muted small'>
									(Lote No. { serviceReport.service.service_items.filter(i => i.item_type_id === opt.setting.id)[0].lote_no } | F.F. { serviceReport.service.service_items.filter(i => i.item_type_id === opt.setting.id)[0].lote_ff } | F.V. { serviceReport.service.service_items.filter(i => i.item_type_id === opt.setting.id)[0].lote_fv })
								</span>
							</React.Fragment>
						) : (
							<React.Fragment>
								<FontAwesomeIcon color='#ccc' icon={faCircle} className="mr-5" />
								{ opt.setting.name }
							</React.Fragment>
						)}						
					</h2>
				)) }
			</div>
		</React.Fragment>
	)
}