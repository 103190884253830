import React, { useState } from 'react'
import { Form } from 'react-bootstrap'

import ServerSideSelect from '../../../components/ServerSideSelect'
import NewTechnicianModal from '../../Technicians/partials/NewTechnicianModal'
import { searchTechnicians } from '../../Technicians/services'

export default function ServiceTechnicianForm({ register, errors, setValue, editable }) {
	const [selectedTechnician, setSelectedTechnician] = useState([])
	const [showNewTechnicianModal, setShowNewTechnicianModal] = useState(false)

	return (
		<React.Fragment>
			{ !editable && (
				<Form.Group className='form-group'>
					<Form.Label>Nombre del técnico <span className='text-danger'>*</span></Form.Label>
					<ServerSideSelect 
						value={selectedTechnician}
						placeholder="Buscar por nombre del técnico"
						fetchOptions={searchTechnicians}
						onChange={record => {
							setSelectedTechnician(record)
							setValue('technician_id', record.value)
						}}
						className="full-width"
					/>
					<Form.Text className='text-link' onClick={() => setShowNewTechnicianModal(true)}>Crear nuevo técnico</Form.Text>
					<Form.Control {...register('technician_id', { required: true })} style={{ display:'none' }} />
					{ errors.technician_id && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
			)}
			<Form.Group className='form-group'>
				<Form.Label>Cargo del técnico <small>(opcional)</small></Form.Label>
				<Form.Control 
					{...register('job_role', {})}
					placeholder="Escriba aquí..."
				/>
			</Form.Group>
			{ showNewTechnicianModal && (
				<NewTechnicianModal 
					visible
					onClose={() => setShowNewTechnicianModal(false)}
					reload={() => setSelectedTechnician([])}
				/>
			)}
		</React.Fragment>
	)
}