import React from 'react'
import { Link } from 'react-router-dom'
import { Button, CardSubtitle, CardTitle } from 'reactstrap'

export default function ServiceReviewProfileTopbar({ serviceReview }) {
	if(!serviceReview) return null

	return (
		<React.Fragment>
			<div className='float-right pt-5'>
				<Link to={`/services/${serviceReview.service.id}`}>
					<Button color='primary' outline className='ml-7'>
						Servicio Asociado
					</Button>
				</Link>
			</div>

			<CardTitle>Diagnósitico de Servicio #{serviceReview.sku} - {serviceReview.service.location.company.legal_name}</CardTitle>
			<CardSubtitle>{serviceReview.service.name}</CardSubtitle>
		</React.Fragment>
	)
}