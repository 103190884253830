import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Badge } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faLockOpen, faToggleOff, faToggleOn, faTrash } from '@fortawesome/free-solid-svg-icons'
import { Divider, Empty, message, Popconfirm, Table, Tooltip } from 'antd'

import Pagination from '../../../components/Pagination'
import EditUserModal from './EditUserModal'
import UserPermissionsModal from './UserPermissionsModal'

import { deleteUser, updateUser } from '../services'
import { handleError } from '../../../helpers'

export default function UsersTable({ users, reload, pagination, setPagination }) {
	const currentUser = useSelector(state => state.auth.user)
	const [editModalRecord, setEditModalRecord] = useState(null)
	const [userPermissionsModal, setUserPermissionsModal] = useState(false)
	
	const columns = [
		{
			title: 'Nombre Completo',
			dataIndex: 'name'
		},
		{
			title: 'Correo Electrónico',
			dataIndex: 'email',
			render: t => t ? t : <em className='text-muted small'>Sin registro</em>
		},
		{
			title: 'Documento',
			dataIndex: 'document',
		},
		{
			title: 'Tipo de Acceso',
			dataIndex: 'role_name'
		},
		{
			title: 'Fecha Creación',
			dataIndex: 'created_at',
		},
		{
			title: 'Estado',
			dataIndex: 'status',
			render: t => <Badge color={t.color}>{t.label}</Badge>
		},
		{
			title: 'Acciones',
			render: (t, r) => (
				<React.Fragment>
					{ r.role !== 'admin' && (
						<React.Fragment>
							<Tooltip title="Permisos" onClick={() => setUserPermissionsModal(r)}>
								<React.Fragment>
									<FontAwesomeIcon icon={faLockOpen} className="text-link" />
								</React.Fragment>
							</Tooltip>
							<Divider type='vertical' />
						</React.Fragment>
					)}
					{ (currentUser.role === 'admin' || currentUser.permissions.includes('edit_users')) && (
						<React.Fragment>
							<Tooltip title="Editar" onClick={() => setEditModalRecord(r)}>
								<React.Fragment>
									<FontAwesomeIcon icon={faEdit} className="text-link" />
								</React.Fragment>
							</Tooltip>
							<Divider type='vertical' />
							<Tooltip title={r.status.key === 'active' ? 'Inactivar' : 'Activar'}>
								<React.Fragment>
									<FontAwesomeIcon 
										icon={r.status.key === 'active' ? faToggleOff : faToggleOn} 
										className="text-link"
										onClick={() => handleToggle(r.id, r.status.key === 'inactive')}
									/>
								</React.Fragment>
							</Tooltip>
							<Divider type='vertical' />
						</React.Fragment>
					)}
					{ (currentUser.role === 'admin' || currentUser.permissions.includes('delete_users')) && (
						<React.Fragment>
							<Tooltip title="Eliminar">
								<React.Fragment>
									<Popconfirm
										title="Esta acción no se puede revertir"
										okText="Eliminar"
										cancelText="Cancelar"
										okButtonProps={{ danger:true }}
										onConfirm={() => handleDelete(r.id)}
									>
										<FontAwesomeIcon icon={faTrash} className="text-link" />
									</Popconfirm>
								</React.Fragment>
							</Tooltip>
						</React.Fragment>
					)}
				</React.Fragment>
			)
		}
	]

	const handleToggle = (id, isActive) => {
		updateUser(id, { is_active: isActive ? 1 : 0 })
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(handleError)
	}

	const handleDelete = id => {
		deleteUser(id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(handleError)
	}
	
	return (
		<React.Fragment>
			<Table 
				dataSource={users}
				rowKey={r => r.id}
				pagination={false}
				columns={columns}
				loading={!users}
				size="small"
				locale={{ emptyText: (
					<Empty
						description="No se encontraron registros" 
						imageStyle={{ height: '80px' }}
					/> 
				)}}
			/>
			<Pagination 
				pagination={pagination}
				setPagination={setPagination}
				reload={reload}
				className="mb-0"
			/>
			{ editModalRecord && (
				<EditUserModal 
					visible
					onClose={() => setEditModalRecord(null)}
					user={editModalRecord}
					reload={reload}
				/>
			)}
			{ userPermissionsModal && (
				<UserPermissionsModal 
					visible
					onClose={() => setUserPermissionsModal(null)}
					user={userPermissionsModal}
					reload={reload}
				/>
			)}
		</React.Fragment>
	)
}