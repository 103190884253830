import React from 'react'
import { faCircle, faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Row } from 'reactstrap'
import moment from 'moment/moment'

export default function ReportDocDetails({ serviceReport }) {

	if(!serviceReport.service || !serviceReport.service.location)
		return <em>Este servicio no tiene sede asociada</em>

	return (
		<React.Fragment>
			<div className='detailsDiv'>
				<Row className='detailRow'>
					<Col>
						<p className='detailLabel'>CIUDAD</p>
						<p className='detailInfo'>
							{serviceReport.service.location.city}
						</p>
					</Col>
					<Col>
						<p className='detailLabel'>FECHA</p>
						<p className='detailInfo'>
							{moment(serviceReport.service.service_at).format('YYYY-MM-DD')}
						</p>
					</Col>
					<Col>
						<p className='detailLabel'>HORA</p>
						<p className='detailInfo'>
							{moment(serviceReport.service.service_at).format('hh:mm A')}
						</p>
					</Col>
				</Row>
				<Row className='detailRow'>
					<Col>
						<p className='detailLabel'>EMPRESA</p>
						<p className='detailInfo'>
							{serviceReport.service.location.company.legal_name}
							{" "}({serviceReport.service.location.name})
						</p>
					</Col>
				</Row>
				<Row className='detailRow'>
					<Col>
						<p className='detailLabel'>DIRECCIÓN</p>
						<p className='detailInfo'>
							{serviceReport.service.location.address} {serviceReport.service.location.address_extra}, {serviceReport.service.location.city} {serviceReport.service.location.department}
						</p>
					</Col>
				</Row>
				<Row className='detailRow mb-0'>
					<Col>
						<p className='detailLabel'>CONTACTO</p>
						<p className='detailInfo'>
							{serviceReport.service.employee.name}
						</p>
					</Col>
					<Col>
						<p className='detailLabel'>TELÉFONO</p>
						<p className='detailInfo'>
							{serviceReport.service.employee.mobile} {serviceReport.service.employee.phone1} {serviceReport.service.employee.phone2} {serviceReport.service.employee.phone3}
						</p>
					</Col>
				</Row>
			</div>
			<div className='px-20 mt-10 mb-0 text-center'>
				<h1 className='bold inline-block'>DESCRIPCIÓN DEL SERVICIO:</h1>
				<h2 className='inline-block ml-30'>
					<FontAwesomeIcon color={serviceReport.service.is_corrective ? '#ccc' : '#333'} icon={serviceReport.service.is_corrective ? faCircle : faCircleCheck} className="mr-5" />PREVENTIVO
				</h2>
				<h2 className='inline-block ml-30'>
					<FontAwesomeIcon color={serviceReport.service.is_corrective ? '#333' : '#ccc'} icon={serviceReport.service.is_corrective ? faCircleCheck : faCircle} className="mr-5" />CORRECTIVO
				</h2>
			</div>
		</React.Fragment>
	)
}