import React from 'react'
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import { message, Upload } from "antd"
import AWS from "aws-sdk"

import { updateReviewResult } from '../services'
import { handleError } from '../../../helpers'

const { Dragger } = Upload

export default function ReviewResultImagesModal({ visible, onClose, reviewResult, reload }) {

	const getProps = (field) => ({
		multiple: false,
		customRequest({
			action,
			data,
			file,
			filename,
			headers,
			onError,
			onProgress,
			onSuccess,
			withCredentials
		}) {
			AWS.config.update({
				accessKeyId: 'AKIAIRCZKCHEMLE5F5IA',
    			secretAccessKey: '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'
			});
		
			const S3 = new AWS.S3({
				params: { Bucket: 'ingealimentos' },
				region: 'us-east-1',
		  });

		  let fileExt = file.name.split('.').pop();
		  let uri = `app/review_results/${reviewResult.id}/${Date.now()}.${fileExt}`
		
			const objParams = {
				Bucket: "ingealimentos",
				Key: uri,
				Body: file,
				ContentType: file.type
			};
		
			S3.putObject(objParams)
				.on("httpUploadProgress", function({ loaded, total }) {
					onProgress(
						{ percent: Math.round((loaded / total) * 100) },
						file
					);
				})
				.send(function(err, data) {
					if (err) {
						onError();
						console.error('Error al subir imagen: ', err.message)
					} else {
						onSuccess(data.response, file);						
						updateReviewResult(reviewResult.id, {
							[field]: `https://ingealimentos.s3.us-east-1.amazonaws.com/${uri}`
						})
							.then(res => {
								message.success(res.data.message)
								reload()
							})
							.catch(error => handleError(error))
					}
				});
			}
		})

	return (
		<Modal isOpen={visible} toggle={onClose}>
			<ModalHeader toggle={onClose}>Imagenes adjuntas al resultado</ModalHeader>
			<ModalBody>
				<div className='bg-light p-10 mb-10'>
					<Row>
						<Col>
							<p className='bold'>Adjunto #1</p>
							{ reviewResult.condition_img1_url && <img src={reviewResult.condition_img1_url} alt="Evidencia" width="70px" />}
						</Col>
						<Col>
							<div>
								<Dragger {...getProps('condition_img1_url')}>
									<p className="bold mb-0 px-10">Haga clic o arraste su archivo aquí...</p>
								</Dragger>
							</div>
						</Col>
					</Row>
				</div>
				<div className='bg-light p-10 mb-10'>
					<Row>
						<Col>
							<p className='bold'>Adjunto #2</p>
							{ reviewResult.condition_img2_url && <img src={reviewResult.condition_img2_url} alt="Evidencia" width="70px" />}
						</Col>
						<Col>
							<div>
								<Dragger {...getProps('condition_img2_url')}>
									<p className="bold mb-0 px-10">Haga clic o arraste su archivo aquí...</p>
								</Dragger>
							</div>
						</Col>
					</Row>
				</div>
				<div className='bg-light p-10 mb-10'>
					<Row>
						<Col>
							<p className='bold'>Adjunto #3</p>
							{ reviewResult.condition_img3_url && <img src={reviewResult.condition_img3_url} alt="Evidencia" width="70px" />}
						</Col>
						<Col>
							<div>
								<Dragger {...getProps('condition_img3_url')}>
									<p className="bold mb-0 px-10">Haga clic o arraste su archivo aquí...</p>
								</Dragger>
							</div>
						</Col>
					</Row>
				</div>
			</ModalBody>
		</Modal>
	)
}