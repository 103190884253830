import React from 'react'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function ReportDocControlPlagasFooter({ serviceReport }) {
	return (
		<React.Fragment>
			<p className='text-center bold mt-10'>RECOMENDACIONES</p>
			<p className='small text-justify xs'>Informamos que el alcance del servicio realizado está sujeto al grado de infestación, deficiencias físicas, culturales y/o ambientales que presenten las instalaciones por lo anterior, de ninguna manera INGEALIMENTOS, garantiza la erradicación de algún tipo de plaga; el servicio está condicionado a la implementación de un programa de control de plagas acorde a la necesidad de su empresa para minimizar el riesgo Motivo por el cual el cliente es responsable de reportar y solicitar el servicio en caso de ser requerido con el fin de mantener actualizado el programa.</p>
			<div className='mt-10 mb-10'>
				<div>
					<p className='bold inline-block mr-5'>CONTROL FISICO: </p>
					<span>Se sugiere adaptar los siguientes elementos de control:</span>
				</div>
				<h2 className='inline-block ml-10 mb-0'>
					<FontAwesomeIcon color='#ccc' icon={faCircle} className="mr-5" />
					<small>Banda de Caucho</small>
				</h2>
				<h2 className='inline-block ml-10 mb-0'>
					<FontAwesomeIcon color='#ccc' icon={faCircle} className="mr-5" />
					<small>Anjeo</small>
				</h2>
				<h2 className='inline-block ml-10 mb-0'>
					<FontAwesomeIcon color='#ccc' icon={faCircle} className="mr-5" />
					<small>Rejillas</small>
				</h2>
				<h2 className='inline-block ml-10 mb-0'>
					<FontAwesomeIcon color='#ccc' icon={faCircle} className="mr-5" />
					<small>Emboquillados</small>
				</h2>
				<p className='small mt-4'>Recomendaciones Físicas: {serviceReport.physic_recomendations}</p>
			</div>
			<div className='mb-10'>
				<div>
					<p className='bold inline-block mr-5'>CONTROL CULTURAL Y/O AMBIENTAL: </p>
					<span>Se sugiere mejorar las siguientes condiciones ambientales en la:</span>
				</div>
				<h2 className='inline-block ml-10 mb-0'>
					<FontAwesomeIcon color='#ccc' icon={faCircle} className="mr-5" />
					<small>Limpieza</small>
				</h2>
				<h2 className='inline-block ml-10 mb-0'>
					<FontAwesomeIcon color='#ccc' icon={faCircle} className="mr-5" />
					<small>Almacenamiento</small>
				</h2>
				<h2 className='inline-block ml-10 mb-0'>
					<FontAwesomeIcon color='#ccc' icon={faCircle} className="mr-5" />
					<small>Manejo de Basuras</small>
				</h2>
				<h2 className='inline-block ml-10 mb-0'>
					<FontAwesomeIcon color='#ccc' icon={faCircle} className="mr-5" />
					<small>Condiciones Externas</small>
				</h2>
				<p className='small mt-4'>Recomendaciones Ambientales: {serviceReport.ambient_recomendations}</p>
			</div>
			<p className='text-center bold mt-10'>OBSERVACIONES DEL TÉCNICO</p>
			<p className='detailInfoMultiline'>{serviceReport.technician_observations}</p>
			<p className='text-center bold mt-10'>OBSERVACIONES FINALES</p>
			<p className='detailInfoMultiline'>{serviceReport.final_recomendations}</p>
		</React.Fragment>
	)
}