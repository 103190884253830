import React from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { Provider } from 'react-redux'
import { HashRouter } from 'react-router-dom'
import ReactDOM from 'react-dom/client'
import { PersistGate } from 'redux-persist/integration/react'

import { persistor, store } from './store'

import App from './App'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'antd/dist/reset.css'
import './assets/css/helpers.css'
import './assets/css/main.css'

Bugsnag.start({
  apiKey: 'ee9aaeeaa77505a4a4e6fc15ac6a4022',
  plugins: [new BugsnagPluginReact()]
})

const ErrorBoundary = Bugsnag.getPlugin('react')
  .createErrorBoundary(React)

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <ErrorBoundary>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <HashRouter>
          <App />
        </HashRouter>
      </PersistGate>
    </Provider>
  </ErrorBoundary>
)