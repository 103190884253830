import React from 'react'

import logoAndina from '../../../../assets/images/logo_andina.png'
import logoIngealimentos from '../../../../assets/images/logo_ingealimentos.png'

export default function ResultDocHeader({ serviceResult }) {
	return (
		<React.Fragment>
			<tr>
				<td colSpan={1} rowSpan={3} className="p-10 text-center">
					<img 
						src={ serviceResult.service_report.service.brand_slug === 'andina' ? logoAndina : logoIngealimentos } 
						width={ serviceResult.service_report.service.brand_slug === 'andina' ? '100px' : '140px' } 
						alt="Logo"
					/>
				</td>
				<td colSpan={2} rowSpan={2}>
					<h1 className='mb-0 bold text-center'>
						INFORME DE SERVICIO
					</h1>
				</td>
				<td colSpan={1} rowSpan={1}>
					<div className='text-center'>
						<p>Código: GO-P027-F040</p>
					</div>
				</td>
			</tr>
			<tr>
				<td colSpan={1} rowSpan={1}>
					<div className='text-center'>
						<p>Versión: 02</p>
					</div>
				</td>
			</tr>
			<tr>
				<td colSpan={2} rowSpan={1}>
					<h1 className='mb-0 bold text-center px-20'>
						{serviceResult.service_report.service.name}<br />
					</h1>
				</td>
				<td colSpan={1} rowSpan={1}>
					<div className='text-center px-10'>
						<p>Fecha de revisión: Enero 4 de 2022</p>
					</div>
				</td>
			</tr>
		</React.Fragment>
	)
}