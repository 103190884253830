import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

import LocationsList from '../../Locations/LocationsList'

export default function CompanyLocationsModal({ visible, onClose, company }) {
	return (
		<Modal isOpen={visible} toggle={onClose} size="xl">
			<ModalHeader toggle={onClose}>Listado de Sedes</ModalHeader>
			<ModalBody>
				<LocationsList company={company} />
			</ModalBody>
		</Modal>
	)
}