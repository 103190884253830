import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'

export default function UserForm({ register, errors, editable }) {
	return (
		<React.Fragment>
			<Form.Group className='form-group'>
				<Form.Label>Nombre Completo <span className='text-danger'>*</span></Form.Label>
				<Form.Control 
					{...register('name', { required:true })}
					placeholder="Escriba aquí..."
				/>
				{ errors.name && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
			<Form.Group className='form-group'>
				<Form.Label>Cédula de Ciudadanía <span className='text-danger'>*</span></Form.Label>
				<Form.Control 
					{...register('document', { required:true })}
					placeholder="Escriba aquí..."
				/>
				{ errors.name && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
			<Form.Group className='form-group'>
				<Form.Label>Correo Electrónico <small>(opcional)</small></Form.Label>
				<Form.Control 
					{...register('email', {})}
					placeholder="Escriba aquí..."
					type="email"
				/>
			</Form.Group>			
			<Row>
				<Form.Group as={Col} className='form-group'>
					<Form.Label>Contraseña <small>({editable ? 'Solo para actualizar' : 'Requerido'})</small></Form.Label>
					<Form.Control 
						{...register('password', { required: !editable })}
						type="password"
						placeholder="Escriba aquí..."
					/>
					{ errors.password && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
				<Form.Group as={Col} className='form-group'>
					<Form.Label>Confirmar Contraseña</Form.Label>
					<Form.Control 
						{...register('password_confirmation', { required: !editable })}
						type="password"
						placeholder="Escriba aquí..."
					/>
					{ errors.password_confirmation && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
			</Row>
			<Form.Group className='form-group'>
				<Form.Label>Tipo de Acceso <span className='text-danger'>*</span></Form.Label>
				<Form.Control
					as="select"
					{...register('role', { required:true })}
				>
					<option value="">:: Seleccione una opción ::</option>
					<option value="asesor">Permisos Personalizados</option>
					<option value="admin">Acceso Super Administrador</option>
				</Form.Control>
				{ errors.role && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
		</React.Fragment>
	)
}