import React from 'react'
import styled from 'styled-components'
import { Spinner } from 'react-bootstrap'
import { Col, Row } from 'reactstrap'
import 'moment/locale/es'

import ServiceReportDocument from '../../ServiceReports/partials/ServiceReportDocument'

import logoAndina from '../../../assets/images/logo_andina.png'
import logoIngealimentos from '../../../assets/images/logo_ingealimentos.png'
import logoAra from '../../../assets/images/logo_ara.png'

import { numberFormat } from '../../../helpers'

export default function ServiceResultDocumentJM({ serviceResult }) {
	
	if(!serviceResult) return <Spinner animation='border' />

	const subtotal = (serviceResult.result_activities && serviceResult.result_activities.length > 0) ? 	serviceResult.result_activities.reduce((accumulator, object) => { return accumulator + object.total_value; }, 0) : 0

	return (
		<React.Fragment>
			<Document>
				<div id="reportPreviewContainer" sytle={{ padding: '10px' }}>
					<table>
						<thead className='hidden'><tr><th /><th /><th /><th /><th /><th /></tr></thead>
						<tbody>
							<tr>
								<td colSpan={5} className='bold text-center fs-18'>
									MANTENIMIENTO PREVENTIVO/CORRECTIVO
								</td>
							</tr>
							<tr>
								<td colSpan={2} className='p-15 text-center br-0'>
									<img 
										src={ serviceResult.service_report.service.brand_slug === 'andina' ? logoAndina : logoIngealimentos } 
										width={ serviceResult.service_report.service.brand_slug === 'andina' ? '100px' : '140px' } 
										alt="Logo"
									/>
								</td>
								<td colSpan={3} className='p-0 b-0'>
									<table>
										<tbody>
											<tr>
												<td className='bold bt-0'>Ciudad</td>
												<td className='bt-0'>{serviceResult.service_report.service.location.city}</td>
											</tr>
											<tr>
												<td className='bold'>SAP Tienda</td>
												<td>{serviceResult.service_report.service.location.sap_sku}</td>
											</tr>
											<tr>
												<td className='bold'>Nombre Tienda</td>
												<td>{serviceResult.service_report.service.location.name}</td>
											</tr>
											<tr>
												<td className='bold'>N° Tiket</td>
												<td>{serviceResult.external_sku}</td>
											</tr>
											<tr>
												<td className='bold bb-0'>Fecha</td>
												<td className='bb-0'>{serviceResult.service_report.service.service_at}</td>
											</tr>
										</tbody>
									</table>
								</td>
							</tr>
							<tr>
								<td colSpan={5} className='bold'>
									<img 
										src={logoAra} 
										width="120px" 
										alt="Logo"
									/>
								</td>
							</tr>
							<tr>
								<td colSpan={5} className='p-0 b-0 pt-5 pb-5'>
									<table>
										<thead>
											<tr>
												<td className='bold' colSpan={2}>DESCRIPCIÓN</td>
												<td className='bold' width="80px">CANTIDAD</td>
												<td className='bold' width="80px">VOLUMEN</td>
												<td className='bold' width="100px">VALOR UND.</td>
												<td className='bold' width="90px">TOTAL</td>
											</tr>
										</thead>
										<tbody>
											{ serviceResult.result_activities.length > 0 && (
												<React.Fragment>
													{ serviceResult.result_activities.map( resultActivity =>  (
														<tr key={`TA-${resultActivity.id}`}>
															<td colSpan={2}>{resultActivity.description}</td>
															<td>{resultActivity.quantity}</td>
															<td>{numberFormat(resultActivity.volume)}</td>
															<td>$ {numberFormat(resultActivity.value_per_unit)}</td>
															<td>$ {numberFormat(resultActivity.total_value)}</td>
														</tr>
													)) }
												</React.Fragment>
											)}
											<tr>
												<td colSpan={2}></td>
												<td></td><td></td><td></td><td></td>
											</tr>
											<tr></tr>
											<tr>
												<td colSpan={4} className='bold'>DESCRIPCIÓN DE LA FALLA</td>
												<td>Subtotal</td>
												<td>$ {numberFormat(subtotal)}</td>
											</tr>
											<tr>
												<td colSpan={4} rowSpan={2}>
													{serviceResult.note}
												</td>
												<td>IVA</td>
												<td>$ {numberFormat(subtotal*.19)}</td>
											</tr>
											<tr>
												<td>TOTAL</td>
												<td>$ {numberFormat(subtotal*1.19)}</td>
											</tr>
										</tbody>
									</table>
								</td>
							</tr>
							<tr>
								<td colSpan={5}>
									{ serviceResult.result_attachments.length > 0 ? (
										<Row>
											{ serviceResult.result_attachments.map(resultAttachment => (
												<Col xs={6} key={resultAttachment.id} className="p-10 text-center">
													<img src={`${resultAttachment.url}?v=${new Date().getTime()}`} alt="Evidencia" width="160px" crossOrigin='anonymous' />
												</Col>
											)) }
										</Row>
									) : <em>No se tiene evidencia fotográfica</em>}
								</td>
							</tr>
							<tr className='printApart'>
								<td colSpan={5} className='p-0'>
									<ServiceReportDocument serviceReport={serviceResult.service_report} />
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</Document>
		</React.Fragment>
	)
}

const Document = styled.div`
	width: 216mm;
	min-height: 330mm;
	overflow: hidden;
	padding: 0;
	background-color: #fff;
	font-family: "Arial Narrow", Arial, sans-serif;
	h1 {
		font-size: 16px;
		margin-bottom: 0px;
		line-height: 16px;
	}
	h2 {
		font-size: 16px;
	}
	h5 {
		font-size: 14px;
		margin-bottom: 0px;
	}
	p {
		font-size: 14px;
		margin-bottom: 0px;
	}
	p.small {
		font-size: 13px;
	}
	p.xs {
		font-size: 12px;
	}
	td, th {
		font-size: 14px;
		height: 15px;
	}
	.sm-width {
		max-width: 550px;
		margin: auto;
	}
	.border {
		border: 1px solid #919191!important;
  	}
  	.logoDiv {
		background-color: white;
		border: 4px solid white;
		position: relative;
		top: -15px;
  	}
	.titleDiv {
		margin: auto;
		text-align: center;
	}
	.detailsDiv {
		border: 1px solid #888;
		border-radius: 10px;
		padding: 7px 10px;
		margin-bottom: 5px;
	}
	table td {
		border: 1px solid #888;
		padding-left: 10px;
		padding-right: 10px;
		vertical-align: top;
	}
	tr td.hr {
		background-color: #d9e2f3;
		height: 5px !important;
	}
	table {
		width: 100%;
	}
`