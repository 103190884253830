import React from 'react'

import logoAndina from '../../../../assets/images/logo_andina.png'
import logoIngealimentos from '../../../../assets/images/logo_ingealimentos.png'

export default function ReviewDocWithResults({ serviceReview }) {

	const resultadosFisicos = serviceReview.review_results.filter(r => r.group === 'fisica')
	const resultadosCulturales = serviceReview.review_results.filter(r => r.group === 'cultural')
	const resultadosPeriodicidad = serviceReview.review_results.filter(r => r.group === 'periodicidad')

	return (
		<React.Fragment>
			<table>
				<thead>
					<tr><th /><th /><th /><th /><th /><th /><th /><th /></tr>
				</thead>
				<tbody>		
					<tr>
						<td colSpan={2} rowSpan={3} className="p-10 text-center" width={"250px"}>
							<img 
								src={ serviceReview.service.brand_slug === 'andina' ? logoAndina : logoIngealimentos } 
								width={ serviceReview.service.brand_slug === 'andina' ? '90px' : '120px' } 
								alt="Logo"
							/>
						</td>
						<td colSpan={4} rowSpan={3}>
							<h1 className='mb-0 bold text-center px-30'>
								{serviceReview.review_format.name}
							</h1>
						</td>
						<td colSpan={2} rowSpan={1}>
							<div className='text-center'>
								<p>Código { serviceReview.review_format.code }</p>
							</div>
						</td>
					</tr>
					<tr>
						<td colSpan={2} rowSpan={1}>
							<div className='text-center'>
								<p>Versión { serviceReview.review_format.current_version }</p>
							</div>
						</td>
					</tr>
					<tr>
						<td colSpan={2} rowSpan={1}>
							<div className='text-center px-10'>
								<p>Fecha de vigencia y/ revisión: { serviceReview.review_format.valid_until }</p>
							</div>
						</td>
					</tr>
					<tr>
						<td colSpan={6}>
							<strong>Empresa:</strong> {serviceReview.service.location.company.legal_name}
						</td>
						<td colSpan={2}>
							<strong>Telefono:</strong> {serviceReview.service.employee.mobile}
						</td>
					</tr>
					<tr>
						<td colSpan={3}>
							<strong>Dirección:</strong> {serviceReview.service.location.address} {serviceReview.service.location.address_extra}, {serviceReview.service.location.city} {serviceReview.service.location.department}
						</td>
						<td colSpan={3}>
							<strong>Sede:</strong> {serviceReview.service.location.name}
						</td>
						<td colSpan={2}>
							<strong>Encargado del cliente:</strong> {serviceReview.service.employee.name}
						</td>
					</tr>
					<tr>
						<td colSpan={3}>
							<strong>Nombre de quien realiza el diagnóstico:</strong> {serviceReview.technician.name}
						</td>
						<td colSpan={3}>
							<strong>Fecha de diagnóstico:</strong> {serviceReview.review_at}
						</td>
						<td colSpan={2}>
							<strong>Fecha próximo diagnóstico:</strong> {serviceReview.next_review_at}
						</td>
					</tr>
					<tr>
						<td colSpan={3}>
							<strong>Objetivo: </strong> {serviceReview.objective}
						</td>
						<td colSpan={5}>
							<strong>Alcance: </strong> {serviceReview.reach}
						</td>
					</tr>
				</tbody>
			</table>
			{ resultadosFisicos.length > 0 && (
				<table>
					<thead className='headerTint1'>
						<tr>
							<th width="70px">Área.</th>
							<th colSpan={2}>Condiciones físicas.</th>
							<th>Actividades a realizar.</th>
							<th>Descripción ¿Cómo?</th>
							<th>Responsable.</th>
							<th>Elementos requeridos para el control.</th>
						</tr>
					</thead>
					<tbody>
						{ resultadosFisicos.map(result => (
							<tr key={result.id}>
								<td>{result.area}</td>
								<td>
									{ result.condition_img1_url && <img src={result.condition_img1_url} alt="Evidencia" width="140px" /> }
									{ result.condition_img2_url && <img src={result.condition_img2_url} alt="Evidencia" width="140px" /> }
									{ result.condition_img3_url && <img src={result.condition_img3_url} alt="Evidencia" width="140px" /> }
								</td>
								<td>{result.condition_desc}</td>
								<td>{result.activity}</td>
								<td>{result.description}</td>
								<td>{result.responsable}</td>
								<td>{result.elements}</td>
							</tr>
						)) }
					</tbody>
				</table>
			)}
			{ resultadosCulturales.length > 0 && (
				<table>
					<thead className='headerTint2'>
						<tr>
							<th width="70px">Área.</th>
							<th colSpan={2}>Condiciones culturales.</th>
							<th>Actividades a realizar.</th>
							<th>Descripción ¿Cómo?</th>
							<th>Responsable.</th>
							<th>Elementos requeridos para el control.</th>
						</tr>
					</thead>
					<tbody>
						{ resultadosCulturales.map(result => (
							<tr key={result.id}>
								<td>{result.area}</td>
								<td>
									{ result.condition_img1_url && <img src={result.condition_img1_url} alt="Evidencia" width="140px" /> }
									{ result.condition_img2_url && <img src={result.condition_img2_url} alt="Evidencia" width="140px" /> }
									{ result.condition_img3_url && <img src={result.condition_img3_url} alt="Evidencia" width="140px" /> }
								</td>
								<td>{result.condition_desc}</td>
								<td>{result.activity}</td>
								<td>{result.description}</td>
								<td>{result.responsable}</td>
								<td>{result.elements}</td>
							</tr>
						)) }
					</tbody>
				</table>
			)}
			{ resultadosPeriodicidad.length > 0 && (
				<table>
					<thead className='headerTint3'>
						<tr>
							<th colSpan={7}>Periodicidad de ejecución de los servicios.</th>
						</tr>
					</thead>
					<tbody>
						{ resultadosPeriodicidad.map(result => (
							<tr key={result.id}>
								<td width="70px">{result.area}</td>
								<td colSpan={3}>{result.activity}</td>
								<td>{result.description}</td>
								<td>{result.responsable}</td>
								<td>{result.elements}</td>
							</tr>
						)) }
						<tr>
							<td colSpan={7} className="text-center bold py-10">Nota: De ser requerida la realización de controles físicos nuestra empresa se encuentra a completa disposición para cotizar y llevar a cabo estas labores.</td>										
						</tr>
					</tbody>
				</table>
			)}
		</React.Fragment>
	)
}