import React, { useState } from 'react'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Divider, message, Popconfirm, Tooltip } from 'antd'

import EditResultActivityModal from './EditResultActivityModal'
import ListItemCard from '../../../components/ListItemCard'

import { deleteResultActivity } from '../services'
import { handleError } from '../../../helpers'

export default function ResultActivityCard({ resultActivity, reload, serviceResult }) {
	const [showEditModal, setShowEditModal] = useState(false)

	const handleDelete = () => {
		deleteResultActivity(resultActivity.id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(handleError)
	}

	return (
		<React.Fragment>
			<ListItemCard>
				<div className='float-right ml-10'>
					<Tooltip title="Editar" onClick={() => setShowEditModal(true)}>
						<FontAwesomeIcon className='text-link' icon={faEdit} />
					</Tooltip>
					<Divider type="horizontal" className='my-5' />
					<Tooltip title="Eliminar" placement='bottom'>
						<Popconfirm
							title="Esta acción no se puede revertir"
							okText="Eliminar"
							cancelText="Cancelar"
							okButtonProps={{ danger:true }}
							onConfirm={handleDelete}
						>
							<FontAwesomeIcon className='text-link' icon={faTrash} />
						</Popconfirm>
					</Tooltip>
				</div>
				<p className='mb-10'>({resultActivity.order}) {resultActivity.description}</p>
				<p className='mb-0 text-muted small'>
					Actividad registrada el {resultActivity.created_at}
				</p>
			</ListItemCard>
			{ showEditModal && (
				<EditResultActivityModal 
					visible
					onClose={() => setShowEditModal(false)}
					resultActivity={resultActivity}
					reload={reload}
					serviceResult={serviceResult}
				/>
			)}
		</React.Fragment>
	)
}