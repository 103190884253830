import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import VehiclesList from './VehiclesList'

export default function Vehicles() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/vehicles" component={VehiclesList} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}