import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass, faPrint, faTrash } from '@fortawesome/free-solid-svg-icons'
import { Divider, Empty, message, Popconfirm, Table, Tooltip } from 'antd'

import Pagination from '../../../components/Pagination'

import { deleteServiceReport } from '../services'
import { handleError } from '../../../helpers'

export default function ServiceReportsTable({ serviceReports, reload, pagination, setPagination }) {
	const columns = [
		{
			title: 'Consecutivo',
			dataIndex: 'sku',
			render: (t, r) => <Link to={`/service_reports/${r.id}`}>{t}</Link>
		},
		{
			title: 'Empresa',
			dataIndex: 'service',
			render: t => t.location.company.legal_name
		},
		{
			title: 'Sede',
			dataIndex: 'service',
			render: t => t.location.name
		},
		{
			title: 'Tipo de Reporte',
			dataIndex: 'report_format',
			render: t => t.name
		},
		{
			title: 'Informe de Servicio',
			dataIndex: 'service_result',
			render: t => t ? `#0${t.id}` : <em>Sin informe generado</em>
		},
		{
			title: 'Fecha del reporte',
			dataIndex: 'report_at',
		},
		{
			title: 'Acciones',
			width: '125px',
			render: (t, r) => (
				<React.Fragment>
					<Tooltip title="Abrir Reporte">
						<React.Fragment>
							<Link to={`/service_reports/${r.id}`}>
								<FontAwesomeIcon icon={faMagnifyingGlass} className="text-link" />
							</Link>
						</React.Fragment>
					</Tooltip>
					<Divider type='vertical' />
					<Tooltip title="Imprimir Reporte">
						<React.Fragment>
							<Link to={`/service_reports/preview/${r.id}`} target="_blank">
								<FontAwesomeIcon icon={faPrint} className="text-link" />
							</Link>
						</React.Fragment>
					</Tooltip>
					<Divider type='vertical' />
					<Tooltip title="Eliminar">
						<React.Fragment>
							<Popconfirm
								title="Esta acción no se puede revertir"
								okText="Eliminar"
								cancelText="Cancelar"
								okButtonProps={{ danger:true }}
								onConfirm={() => handleDelete(r.id)}
							>
								<FontAwesomeIcon icon={faTrash} className="text-link" />
							</Popconfirm>
						</React.Fragment>
					</Tooltip>
				</React.Fragment>
			)
		}
	]

	const handleDelete = id => {
		deleteServiceReport(id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(handleError)
	}
	
	return (
		<React.Fragment>
			<Table 
				dataSource={serviceReports}
				rowKey={r => r.id}
				pagination={false}
				columns={columns}
				loading={!serviceReports}
				size="small"
				locale={{ emptyText: (
					<Empty
						description="No se encontraron registros" 
						imageStyle={{ height: '80px' }}
					/> 
				)}}
			/>
			<Pagination 
				pagination={pagination}
				setPagination={setPagination}
				reload={reload}
				className="mb-0"
			/>
		</React.Fragment>
	)
}