import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
import { Spinner } from 'react-bootstrap'
import styled from 'styled-components'

import ServiceReviewProfileTopbar from './partials/ServiceReviewProfileTopbar'
import ServiceReviewDocument from './partials/ServiceReviewDocument'
import ReviewInfoWidget from './partials/widgets/ReviewInfoWidget'
import ReviewTasksWidget from '../ReviewTasks/ReviewTasksWidget'
import ReviewResultsWidget from '../ReviewResults/ReviewResultsWidget'

import { getServiceReview } from './services'
import { handleError } from '../../helpers'

export default function ServiceReviewProfile(props) {
	const serviceReviewId = props.match.params.id
	const [serviceReview, setServiceReview] = useState(null)

	useEffect(() => { 
		if(serviceReview && serviceReview.id !== parseInt(serviceReviewId)) 
			setServiceReview(null) 
	}, [ serviceReview, serviceReviewId ])

	useEffect(() => {
		!serviceReview && getServiceReview(serviceReviewId, {
			include: 'reviewFormat,service.serviceFormat,service.location.company,reviewTasks,technician,reviewResults,service.employee'
		})
			.then(res => setServiceReview(res.data.data))
			.catch(handleError)
	}, [ serviceReviewId, serviceReview ])

	if(!serviceReview) return <Spinner animation='border' />

	return (
		<React.Fragment>
			<Card>
				<CardBody>
					<ServiceReviewProfileTopbar serviceReview={serviceReview} />
				</CardBody>
			</Card>
			<Row>
				<Col sm="12" md="5" lg="4">
					<Card>
						<CardHeader>Detalles del Diagnósitico</CardHeader>
						<CardBody>
							<ReviewInfoWidget 
								serviceReview={serviceReview} 
								reload={() => setServiceReview(null)}
							/>
						</CardBody>
					</Card>
					{ serviceReview.review_format.has_review_tasks === 1 && (
						<Card>
							<CardHeader>Actividades Realizadas</CardHeader>
							<CardBody>
								<ReviewTasksWidget
									serviceReview={serviceReview} 
									reload={() => setServiceReview(null)}
								/>
							</CardBody>
						</Card>
					)}
					{ serviceReview.review_format.has_review_results === 1 && (
						<Card>
							<CardHeader>Resultados Registrados</CardHeader>
							<CardBody>
								<ReviewResultsWidget
									serviceReview={serviceReview} 
									reload={() => setServiceReview(null)}
								/>
							</CardBody>
						</Card>
					)}
				</Col>
				<Col sm="12" md="7" lg="8">
					<Card>
						<CardHeader>Vista Previa</CardHeader>
						<CardBody className='p-5'>
							<PreviewContainer>
								<ServiceReviewDocument serviceReview={serviceReview} />
							</PreviewContainer>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</React.Fragment>
	)
}

const PreviewContainer = styled.div`
	background-color: #F8F9FA;
	padding: 10px;
	max-width: 100%;
	overflow-x: scroll;
`