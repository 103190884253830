import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'

export default function ItemTypeForm({ register, errors, editable }) {
	return (
		<React.Fragment>
			<Row>
				<Form.Group className='form-group' as={Col}>
					<Form.Label>Categoría <span className='text-danger'>*</span></Form.Label>
					<Form.Control
						as="select"
						{...register('scope', { required: true })}
						disabled={editable}
					>
						<option value="">:: Seleccione una opción ::</option>
						<option value="product">Producto</option>
						<option value="service">Servicio</option>
					</Form.Control>
					{ errors.scope && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
				</Form.Group>
				<Form.Group className='form-group' as={Col}>
					<Form.Label>Estado <span className='text-danger'>*</span></Form.Label>
					<Form.Control
						as="select"
						{...register('is_active', { required: true })}
					>
						<option value="">:: Seleccione una opción ::</option>
						<option value="1">Activo</option>
						<option value="0">Inactivo</option>
					</Form.Control>
					{ errors.is_active && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
				</Form.Group>
			</Row>
			<Form.Group className='form-group'>
				<Form.Label>Nombre del Producto/Servicio <span className='text-danger'>*</span></Form.Label>
				<Form.Control
					{...register('name', { required: true })}
					placeholder='Escribe aquí...'
					disabled={editable}
				/>
				{ errors.name && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
			</Form.Group>
			<Form.Group className='form-group'>
				<Form.Label>Lote No <em className='small'>(opcional)</em></Form.Label>
				<Form.Control 
					{...register('default_lote_no', {})}
					placeholder="Escriba aquí..."
				/>
			</Form.Group>
			<Row>
				<Form.Group className='form-group' as={Col}>
					<Form.Label>Fecha Fabricación <em className='small'>(opcional)</em></Form.Label>
					<Form.Control 
						type="date"
						{...register('default_lote_ff', {})}
					/>
				</Form.Group>
				<Form.Group className='form-group' as={Col}>
					<Form.Label>Fecha Vencimiento <em className='small'>(opcional)</em></Form.Label>
					<Form.Control 
						type="date"
						{...register('default_lote_fv', {})}
					/>
				</Form.Group>
			</Row>
		</React.Fragment>
	)
}