import ingealimentosApi, { getDomain } from '../../services/ingealimentosApi'

export const getServices = params => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.get('/services', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getService = (id, params) => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.get(`/services/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeService = data => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.post('/services', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateService = (id, data) => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.put(`/services/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteService = id => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.delete(`/services/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchServices = async (term, token) => (
	fetch( getDomain()+`/services?filter[name]=${term}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(item => ({
				label: item.name,
				value: item.id,
			})),
		)
)

export const searchServiceFormats = async (term, token) => (
	fetch( getDomain()+`/service_formats?filter[name]=${term}&sort=name`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(item => ({
				label: item.name,
				value: item.id,
			})),
		)
)
