import React from 'react'
import { Descriptions } from 'antd'

const { Item } = Descriptions

export default function ServiceDetailsWidget({ service }) {
	return (
		<React.Fragment>
			<Descriptions column={6} bordered size="small">
				<Item label="Empresa" span={1}>{service.brand_name}</Item>
				<Item label="Id Plataforma" span={1}>{service.id}</Item>
				<Item label="Consecutivo" span={1}>{service.sku}</Item>
				{ service.location && service.location.company && (
					<React.Fragment>
						<Item label="Código" span={1}>{service.location.company.code}</Item>
						<Item label="Cliente" span={2}>{service.location.company.legal_name} ({service.location.company.commercial_name})</Item>
						<Item label="NIT" span={1}>{service.location.company.document}</Item>				
						<Item label="Sede Asociada" span={2}>{service.location.name} ({service.location.id})</Item>
						<Item label="Dirección" span={2}>
							{service.location.address} {service.location.address_extra ? service.location.address_extra : ''}
						</Item>
					</React.Fragment>
				)}
				<Item label="Asesor Asignado" span={1}>{service.user.name}</Item>
			</Descriptions>
		</React.Fragment>
	)
}