import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Spinner, Col, Row } from 'react-bootstrap'
import 'moment/locale/es'

import ReportDocumentHeader from './document/ReportDocumentHeader'
import ReportDocDetails from './document/ReportDocDetails'
import ReportDocServiceItems from './document/ReportDocServiceItems'
import ReportDocProductItems from './document/ReportDocProductItems'
import ReportDocFooter from './document/ReportDocFooter'
import ReportDocBlockText from './document/ReportDocBlockText'
import ReportDocResultsDetails from './document/ReportDocResultsDetails'
import ReportDocEquipments from './document/ReportDocEquipments'
import ReportDocSecurityElements from './document/ReportDocSecurityElements'
import ReportDocTreatmentAreas from './document/ReportDocTreatmentAreas'
import ReportDocControlPlagasFooter from './document/ReportDocControlPlagasFooter'

import { getPublicFormatTypes } from '../../FormatTypes/services'
import { handleError } from '../../../helpers'

export default function ServiceReportDocument({ serviceReport }) {
	const [formatSettings, setFormatSettings] = useState(null)

	useEffect(() => {
		!formatSettings && serviceReport && getPublicFormatTypes({
			'filter[format_type]': 'report_format',
			'filter[format_id]': serviceReport.report_format.id,
			include: 'setting'
		})
			.then(res => setFormatSettings(res.data.data))
			.catch(handleError)
	}, [ formatSettings, serviceReport ])

	if(!serviceReport || !formatSettings) 
		return <Spinner animation='border' />

	return (
		<React.Fragment>
			<Document>
				<ReportDocumentHeader serviceReport={serviceReport} />
				<ReportDocDetails serviceReport={serviceReport} />

				{ serviceReport.service.service_format.has_services_items === 1 && (
					<ReportDocServiceItems 
						serviceReport={serviceReport} 
						formatSettings={formatSettings} 
					/>
				)}

				{ serviceReport.report_format.slug === 'control_plagas' && (
					<React.Fragment>
						<p className='detailLabel bold m-10 mb-0'>EQUIPOS UTILIZADOS</p>
						<ReportDocEquipments 
							serviceReport={serviceReport}
							formatSettings={formatSettings}
						/>
					</React.Fragment>
				)}

				{ serviceReport.service.service_format.has_products_items === 1 && (
					<ReportDocProductItems 
						serviceReport={serviceReport} 
						formatSettings={formatSettings} 
					/>
				)}

				{ serviceReport.report_format.slug === 'control_plagas' && (
					<ReportDocTreatmentAreas 
						serviceReport={serviceReport} 
						formatSettings={formatSettings} 
					/>
				)}

				<ReportDocResultsDetails 
					serviceReport={serviceReport}
					formatSettings={formatSettings}
				/>

				{ serviceReport.report_format.has_job_description === 1 && (
					<ReportDocBlockText 
						label="TRABAJO RELACIONADO"
						value={serviceReport.job_description}
					/>
				)}

				{ serviceReport.report_format.slug === 'limpieza_tanques_potable' && (
					<Row>
						<Col xs={6}>
							<p className='detailLabel bold m-10 mb-0'>EQUIPOS UTILIZADOS</p>
							<ReportDocEquipments 
								serviceReport={serviceReport}
								formatSettings={formatSettings}
							/>
							<ReportDocBlockText 
								label="RECOMENDACIONES"
								value={serviceReport.final_recomendations}
							>
								<p className='detailInfo'></p>
								<p className='detailInfo'></p>
								<p className='detailInfo'></p>
							</ReportDocBlockText>
						</Col>
						<Col className="p-0">
							<p className='detailLabel bold m-10'>ELEMENTOS DE PROTECCIÓN PERSONAL UTILIZADOS</p>
							<ReportDocSecurityElements 
								serviceReport={serviceReport}
								formatSettings={formatSettings}
							/>
						</Col>
					</Row>
				)}

				{ serviceReport.report_format.has_final_recomendations === 1 && (
					<ReportDocBlockText 
						label="RECOMENDACIONES"
						value={serviceReport.final_recomendations}
					>
						<p className='detailInfo'></p>
						<p className='detailInfo'></p>
					</ReportDocBlockText>
				)}
				
				{ serviceReport.report_format.slug === 'control_plagas' && (
					<ReportDocControlPlagasFooter serviceReport={serviceReport} />
				)}

				{ serviceReport.report_format.slug === 'limpieza_tanques_potable' && (
					<React.Fragment>
						<ReportDocBlockText 
							label="OBSERVACIONES DEL TÉCNICO"
							value={serviceReport.technician_observations}
						>
							<p className='detailInfo'></p>
							<p className='detailInfo'></p>
						</ReportDocBlockText>
					</React.Fragment>
				)}

				{ serviceReport.report_format.has_client_observations === 1 && (
					<React.Fragment>
						<ReportDocBlockText 
							label="OBSERVACIONES DEL TÉCNICO"
							value={serviceReport.technician_observations}
						>
							<p className='detailInfo'></p>
							<p className='detailInfo'></p>
						</ReportDocBlockText>
						<ReportDocBlockText 
							label="OBSERVACIONES DEL CLIENTE"
							value={serviceReport.client_observations}
						>
							<p className='detailInfo'></p>
						</ReportDocBlockText>
					</React.Fragment>
				)}

				<ReportDocFooter
					serviceReport={serviceReport}
					formatSettings={formatSettings}
				/>
			</Document>
		</React.Fragment>
	)
}

const Document = styled.div`
	width: 210mm;
	min-height: 330mm;
	padding: 0.55in 0.75in 0 0.75in;
	background-color: #fff;
	font-family: "Arial Narrow", Arial, sans-serif;
	h1 {
		font-size: 16px;
		margin-bottom: 0px;
		line-height: 16px;
	}
	h2 {
		font-size: 16px;
	}
	h5 {
		font-size: 14px;
		margin-bottom: 0px;
	}
	p {
		font-size: 14px;
		margin-bottom: 0px;
	}
	p.small {
		font-size: 13px;
	}
	p.xs {
		font-size: 12px;
	}
	td, th {
		font-size: 13px;
		height: 15px;
	}
	.sm-width {
		max-width: 550px;
		margin: auto;
	}
	.border {
		border: 1px solid #919191!important;
  	}
  	.logoDiv {
		background-color: white;
		border: 4px solid white;
		position: relative;
		top: -15px;
  	}
	.titleDiv {
		margin: auto;
		text-align: center;
	}
	.detailsDiv {
		border: 1px solid #888;
		border-radius: 10px;
		padding: 7px 10px;
		margin-bottom: 5px;
	}
	.detailRow {
		margin-bottom: 10px;		
	}
	.detailLabel {
		float: left;
		min-width: 70px;
		margin-right: 10px;
		font-size: 15px;
	}
	.detailInfo {
		width: 100%;
		border-bottom: 1px solid #e8e8e8;
		text-align: left;
		font-weight: bold;
		font-size: 15px;
		min-height: 20px;
	}
	.detailInfoMultiline {
		width: 100%;
		text-align: left;
		font-weight: 400;
		font-size: 15px;
		min-height: 18px;
		background-color: #fcfcfc;
		padding: 5px;
	}
	.footer {
		width: 176mm;
		bottom: 70px;
		margin-bottom: 20px;
	}
`