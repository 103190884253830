import React from 'react'
import styled from 'styled-components'

export default function ListItemCard({ children }) {
	return (
		<Card>
			{ children }
		</Card>
	)
}

const Card = styled.div`
	border: 1px solid #e8e8e8;
	padding: 7px 10px;
	background-color: #f8f8f8;
	margin-bottom: 5px;
`