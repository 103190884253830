import React from 'react'
import styled from 'styled-components'
import { Spinner } from 'react-bootstrap'
import moment from 'moment'
import 'moment/locale/es'
import { Alert } from 'reactstrap'

import fondoAndina from '../../../assets/images/certificado_andina.jpg'
import fondoIngealimentos from '../../../assets/images/certificado_ingealimentos.jpg'

export default function CertificateDocument({ certificate }) {
	
	if(!certificate) return <Spinner animation='border' />

	const location = certificate.service_report.service.location

	if(!location) return <Alert color="warning">La sede asociada al servicio ha sido eliminada o desactivada.</Alert>

	const completeAddress = `${location.address}${location.address_extra ? ` ${location.address_extra}` : ''}${location.sap_sku ? ` CÓDIGO ${location.sap_sku}` : ''}, ${location.city} (${location.department})`

	const companyName = `${location.company.legal_name} sede ${location.name}`
	const serviceName = certificate.service_report.service.name
	const serviceDate = moment(certificate.service_report.service.service_at).format('DD [de] MMMM [de] YYYY')

	return (
		<React.Fragment>
			<div style={{ width: '279mm', height: '215mm' }} id="certificatePreviewContainer">
				<img alt="fondo" src={certificate.service_report.service.brand_slug === 'andina' ? fondoAndina : fondoIngealimentos} style={{ width: '279mm', height: '215mm', position: 'absolute' }}  crossOrigin='anonymous' />
				<Document company={certificate.service_report.service.brand_slug}>
					<div className='sku'>Nº 0{certificate.sku}</div>

					{ certificate.service_report.service.service_format.slug === 'evacuacion_aguas_domesticas' && (
						<React.Fragment>
							<p>A las instalaciones <strong>{companyName}</strong> ubicadas en la dirección <strong>{completeAddress}</strong>, se le realizó <strong>{serviceName}</strong>, el día {serviceDate}</p>
						</React.Fragment>
					)}
					{ certificate.service_report.service.service_format.slug === 'limpieza_tanques_potable' && (
						<React.Fragment>
							<p>A las instalaciones de <strong>{companyName}</strong> ubicadas en la dirección <strong>{completeAddress}</strong>, se realizó la <strong>LIMPIEZA Y DESINFECCIÓN DE TANQUES DE AGUA POTABLE</strong>, el día <strong>{serviceDate}</strong>.</p>
							<p>Con base en la Resolución 2190 de 1991. Capitulo II Art. 2 Los tanques de almacenamiento domiciliario deberán ser sometidos a lavado y desinfección mínimo 2 veces al año</p>
						</React.Fragment>
					)}
					{ certificate.service_report.service.service_format.slug === 'control_plagas' && (
						<React.Fragment>
							<p>En las instalaciones de <strong>{companyName}</strong> ubicada en la dirección <strong>{completeAddress}</strong>, se realizó <strong>SERVICIO DE INSPECCIÓN, MONITOREO Y SERVICIO{" "}{serviceName.toUpperCase()}</strong>, el día <strong>{serviceDate}</strong>.</p>
						</React.Fragment>
					)}
					
					<div className='mb-30 inline-block full-width'>
						{ certificate.published_at ? (
							<p>Se expide el {moment(certificate.service_report.service.service_at).format('DD [de] MMMM [de] YYYY')}.</p>
						) : (
							<p>Se expide el: CERTIFICADO SIN EXPEDIR</p>
						)}
					</div>

					<div className='mb-20 inline-block full-width'>
						<p className='mb-0'><strong>Cordialmente,</strong></p>
					</div>

					<img alt="sello" src={certificate.service_report.service.brand_slug === 'andina' ? 'https://smart4cdn.s3.amazonaws.com/ingealimentos/sello_andina.png' : 'https://smart4cdn.s3.amazonaws.com/ingealimentos/sello_ingealimientos.png'} style={{ width: '100px', height: 'auto' }}  crossOrigin='anonymous' />
					<div className='mt-0 inline-block full-width'>
						<p><strong>Ing. José Leonardo Orjuela Cruz</strong></p>
					</div>
				</Document>
			</div>
		</React.Fragment>
	)
}

const Document = styled.div`
	width: 279mm;
	height: 215mm;
	padding: 3.5in 1.5in 0.5in 1.5in;
	font-family: "Arial Narrow", Arial, sans-serif;
	background-color: transparent;
	position: absolute;
	p {
		font-size: 21px;
		line-height: 24px;
	}
	.sku {
		position: absolute;
		top: 65mm; 
		left: 252mm;
		color: #931313;
		font-size: 21px;
		font-weight: bold;
	}
`
