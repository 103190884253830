import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { Col, Row } from 'reactstrap'

export default function ReportDocFooter({ serviceReport, formatSettings }) {
	const deliveredDocumentOpts = formatSettings.filter(r => r.setting_type === 'delivered_document_type')

	const isChecked = setting => {
		return serviceReport.service.service_delivered_documents.filter(i => i.delivered_document_type_id === setting.id).length > 0
	}

	return (
		<React.Fragment>
			<div className='footer'>
				{ serviceReport.report_format.slug === 'evacuacion_aguas_domesticas' && (
					<p className='small mb-5'>NOTA: El Proveedor o dueño de las sustancias o residuos transportados debe asegurarse de informar al transportador sobre el origen de las aguas transportadas y las características fisicoquímicas de las mismas.</p>
				)}
				{ serviceReport.report_format.slug === 'limpieza_tanques_potable' && (
					<p className='xs mb-0'>NOTA: El alcance del servicio realizado por nuestra empresa es el proceso de limpieza y desinfección del colector de aguê, de ninguna Daveudi manera nuestra organización se hace responsable de las características fisicoquímicas y microbiológicas del agua que se encuentre en las tuberías tras la apertura de las válvulas de entrada; Adicionalmente nuestros técnicos no manipularan, registros, flotadores y/o equipos de bombas, de tal manera que la manipulación será de responsabilidad del personal de la empresa contratante como también el recibir a conformidad y funcionalidad el tanque de agua, por tal motivo INGEALIMENTOS no asumirá ningún costo por efectos de inundaciones, obstrucción de tuberías o daños causados por cambios de presión y otros. Se Recomienda que el sistema de circulación de agua debe ser constante de tal manera que permita garantizar la renovación permanente y así evitar la alteración de las propiedades físicas del agua.</p>
				)}
				<div className='px-20 mt-10 mb-0 text-center'>
					<h1 className='bold inline-block'>DOCUMENTACIÓN ENTREGADA:</h1>
					{ deliveredDocumentOpts.map(opt => (
						<h2 key={opt.setting.id} className='inline-block ml-30 mb-0'>
							<FontAwesomeIcon 
								color={isChecked(opt.setting) ? '#333' : '#ccc'} 
								icon={isChecked(opt.setting) ? faCircleCheck : faCircle} 
								className="mr-5" 
							/>
							{opt.setting.name}
						</h2>
					)) }
				</div>
				<div className='detailsDiv mt-10'>
					<Row>
						<Col xs="5">
							<p className='bold mb-20 small'>TÉCNICO</p>
							<p className='mb-0'>
								Firma:{" "}
								{ serviceReport.service.service_technicians && serviceReport.service.service_technicians.map(t => `${t.technician ? t.technician.name : ''} `) }
							</p>
						</Col>
						<Col xs="7">
							<p className='bold mb-20 small'>RECIBÍ A CONFORMIDAD: SERVICIO Y RECOMENDACIONES</p>
							<p className='mb-0'>Nombre: {serviceReport.client_signed_name ? `${serviceReport.client_signed_name}. CC. ${serviceReport.client_signed_document}. Tel: ${serviceReport.client_signed_mobile}` : <em>Sin firma registrada</em>}</p>
						</Col>
					</Row>
				</div>
			</div>
		</React.Fragment>
	)
}