import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { faFileDownload } from '@fortawesome/free-solid-svg-icons'

import DownloadReportModal from './partials/DownloadReportModal'

import PageTitle from '../../components/PageTitle'
import MenuWidget from '../../components/MenuWidget'

export default function ReportsDashboard() {
	const [reportModal, setReportModal] = useState(null)

	const reports = [
		{
			title: 'INFORME DE CLIENTES Y PROVEEDORES',
			slug: 'companies',
		},
		{
			title: 'INFORME DE SERVICIOS (TODOS)',
			slug: 'services',
			requireFromDate: true,
			requireUntilDate: true,
		},
		{
			title: 'INFORME DE SERVICIOS PROGRAMADOS PARA UNA FECHA',
			slug: 'servicesByDate',
			requireFromDate: true,
		},
		{
			title: 'INFORME DE SERVICIOS POR CLIENTE',
			slug: 'servicesByCompany',
			requireCompanyId: true,
			requireFromDate: true,
			requireUntilDate: true,
		},
		{
			title: 'INFORME SERVICIOS DE EVACUACIÓN Y TRANSPORTE DE AGUA',
			slug: 'servicesEvacuationTransport',
			requireFromDate: true,
			requireUntilDate: true,
		},
		{
			title: 'INFORME DE USO DE TODOS LOS VEHÍCULOS',
			slug: 'vehicles'
		},
	]


	return (
		<React.Fragment>
			<PageTitle title="Reportes Administradores" subtitle="Descarga de reportes administradores de la Plataforma Ingealimentos" />
			<Row>
				{ reports.map( report => (
					<Col sm="12" md="6" lg="4" className="mb-20" key={report.slug}>
						<MenuWidget
							title={report.title}
							subtitle="Descargar informe en excel"
							icon={faFileDownload}
							onClick={() => setReportModal(report)}
						/>
					</Col>
				)) }
			</Row>
			{ reportModal && (
				<DownloadReportModal 
					visible
					onClose={() => setReportModal(null)}
					data={reportModal}
				/>
			)}
		</React.Fragment>
	)
}