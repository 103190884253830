import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { Divider, Empty, message, Popconfirm, Table, Tooltip } from 'antd'

import EditEmployeeModal from './EditEmployeeModal'
import EmployeeLocationsModal from './EmployeeLocationsModal'
import Pagination from '../../../components/Pagination'

import { deleteEmployee } from '../services'
import { handleError } from '../../../helpers'

export default function EmployeesTable({ employees, reload, pagination, setPagination }) {
	const [editRecordModal, setEditRecordModal] = useState(null)
	const [employeeLocationsModal, setEmployeeLocationsModal] = useState(null)
	
	const columns = [
		{
			title: 'Empresa',
			dataIndex: 'company',
			render: t => t.commercial_name ? t.commercial_name : t.legal_name
		},
		{
			title: 'Nombre del Contacto',
			dataIndex: 'name',
		},
		{
			title: 'Cargo',
			dataIndex: 'position',
		},
		{
			title: 'Correo Electrónico',
			dataIndex: 'email',
			render: t => t ? t : <em>Sin registro</em>
		},
		{
			title: 'Celular',
			dataIndex: 'mobile',
		},
		{
			title: 'Otros telefonos',
			render: (t, r) => `${t.phone1 ? t.phone1 : ''} ${t.phone2 ? t.phone2 : ''} ${t.phone3 ? t.phone3 : ''}`
		},
		{
			title: 'Acciones',
			render: (t, r) => (
				<React.Fragment>
					<Tooltip title="Editar" onClick={() => setEditRecordModal(r)}>
						<React.Fragment>
							<FontAwesomeIcon icon={faEdit} className="text-link" />
						</React.Fragment>
					</Tooltip>
					<Divider type='vertical' />
					<Tooltip title="Sedes" onClick={() => setEmployeeLocationsModal(r)}>
						<React.Fragment>
							<FontAwesomeIcon icon={faBuilding} className="text-link" />
						</React.Fragment>
					</Tooltip>
					<Divider type='vertical' />
					<Tooltip title="Eliminar">
						<React.Fragment>
							<Popconfirm
								title="Esta acción no se puede revertir"
								okText="Eliminar"
								cancelText="Cancelar"
								okButtonProps={{ danger:true }}
								onConfirm={() => handleDelete(r.id)}
							>
								<FontAwesomeIcon icon={faTrash} className="text-link" />
							</Popconfirm>
						</React.Fragment>
					</Tooltip>
				</React.Fragment>
			)
		}
	]

	const handleDelete = id => {
		deleteEmployee(id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(handleError)
	}
	
	return (
		<React.Fragment>
			<Table 
				dataSource={employees}
				rowKey={r => r.id}
				pagination={false}
				columns={columns}
				loading={!employees}
				size="small"
				locale={{ emptyText: (
					<Empty
						description="No se encontraron registros" 
						imageStyle={{ height: '80px' }}
					/> 
				)}}
			/>
			<Pagination 
				pagination={pagination}
				setPagination={setPagination}
				reload={reload}
				className="mb-0"
			/>
			{ editRecordModal && (
				<EditEmployeeModal 
					visible
					onClose={() => setEditRecordModal(null)}
					employee={editRecordModal}
					reload={reload}
				/>
			)}
			{ employeeLocationsModal && (
				<EmployeeLocationsModal 
					visible
					onClose={() => setEmployeeLocationsModal(null)}
					employee={employeeLocationsModal}
				/>
			)}
		</React.Fragment>
	)
}