import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, CardSubtitle, CardTitle } from 'reactstrap'

import NewServiceResultModal from '../../ServiceResults/partials/NewServiceResultModal'
import NewCertificateModal from '../../Certificates/partials/NewCertificateModal'

export default function ServiceReportProfileTopbar({ serviceReport }) {
	const [showNewServiceResultModal, setShowNewServiceResultModal] = useState(false)
	const [showNewCertificateModal, setShowNewCertificateModal] = useState(false)

	if(!serviceReport) return null

	return (
		<React.Fragment>
			<div className='float-right pt-5'>
				{ serviceReport.service_result ? (
					<Link to={`/service_results/${serviceReport.service_result.id}`}>
						<Button className="ml-7" color='primary' outline>
							Informe de Servicio
						</Button>
					</Link>
				) : serviceReport.result_format_id && (
					<Button
						className="ml-7" 
						color='primary'	
						onClick={() => setShowNewServiceResultModal(true)}							
					>
						Crear Informe de Servicio
					</Button>
				)}
				{ serviceReport.certificate ? (
					<Link to={`/certificates/preview/${serviceReport.certificate.id}`}>
						<Button className="ml-7" color='primary' outline>
							Ver Certificado
						</Button>
					</Link>
				) : (
					<Button
						className="ml-7" 
						color='primary'	
						onClick={() => setShowNewCertificateModal(true)}							
					>
						Crear Certificado
					</Button>
				)}
				<Link to={`/services/${serviceReport.service.id}`}>
					<Button color='primary' outline className='ml-7'>
						Servicio Asociado
					</Button>
				</Link>
				<Link to={`/ver/reportes/${serviceReport.id}`}>
					<Button color='primary' outline className='ml-7'>
						Imprimir
					</Button>
				</Link>
			</div>

			<CardTitle>Reporte de Servicio #{serviceReport.sku} - {serviceReport.service.location.company.legal_name}</CardTitle>
			<CardSubtitle>{serviceReport.service.name}</CardSubtitle>
			
			{ showNewServiceResultModal && (
				<NewServiceResultModal
					visible
					onClose={() => setShowNewServiceResultModal(false)}
					serviceReport={serviceReport}
				/>
			)}
			
			{ showNewCertificateModal && (
				<NewCertificateModal
					visible
					onClose={() => setShowNewCertificateModal(false)}
					serviceReport={serviceReport}
				/>
			)}
		</React.Fragment>
	)
}