import React, { useState } from 'react'
import { Button } from 'reactstrap'
import { Spinner } from 'react-bootstrap'
import { Divider, Empty, Popconfirm, message } from 'antd'

import { notifyCertificate } from '../../../Certificates/services'
import { notifyServiceReport } from '../../services'
import { handleError } from '../../../../helpers'

export default function NotificationLogsWidget({ serviceReport, reload }) {
	const [loading, setLoading] = useState(false)

	const sendServiceReport = () => {
		setLoading(true)
		notifyServiceReport(serviceReport.id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error, setLoading))
			.finally(() => setLoading(false))
	}
	
	const sendCertificate = () => {
		setLoading(true)
		notifyCertificate(serviceReport.certificate.id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error, setLoading))
			.finally(() => setLoading(false))
	}

	if(!serviceReport) return <Spinner animation="border" size="sm" />

	return (
		<React.Fragment>
			{ serviceReport.notification_logs.length > 0 ? (
				<React.Fragment>
					{ serviceReport.notification_logs.map(notification => (
						<div key={notification.id}>
							<p className='mb-0'>
								{notification.description}
							</p>
							<p className='small text-muted'>
								Para: {notification.email} |{" "}
								Fecha: {notification.created_at}
							</p>
						</div>
					)) }
				</React.Fragment>
			) : (
				<Empty 
					description="Aún no se han enviado notificaciones"
					imageStyle={{ maxHeight: '75px' }}
				/>
			)}
			<Divider className='my-15' />
			<Popconfirm
				title="Se enviara vía correo el reporte"
				okText="Enviar"
				cancelText="Cancelar"
				onConfirm={sendServiceReport}
			>
				<Button color='primary' className='mb-10' disabled={loading} size="sm">
					Enviar Reporte de Servicio{" "}
					{ loading && <Spinner animation='border' size="sm" /> }
				</Button>
			</Popconfirm>
			{ serviceReport.certificate && (
				<React.Fragment>
					<Popconfirm
						title="Se enviara vía correo el certificado"
						okText="Enviar"
						cancelText="Cancelar"
						onConfirm={sendCertificate}
					>
						<Button color='primary' outline disabled={loading} size="sm">
							Enviar Certificado{" "}
							{ loading && <Spinner animation='border' size="sm" /> }
						</Button>
					</Popconfirm>
				</React.Fragment>
			)}
		</React.Fragment>
	)
}