import React, { useEffect, useState } from 'react'
import { Spinner, Button } from 'react-bootstrap'
import { Divider, Empty } from 'antd'

import ServiceItemCard from './partials/ServiceItemCard'
import NewServiceItemModal from './partials/NewServiceItemModal'

import { getServiceItems } from './services'
import { handleError } from '../../helpers'
import ToggleServiceItemsModal from './partials/ToggleServiceItemModal'

export default function ServiceItemsWidget({ service, scope }) {
	const [serviceItems, setServiceItems] = useState(null)
	const [showNewRecordModal, setShowNewRecordModal] = useState(false)
	const [showToggleRecordsModal, setShowToggleRecordsModal] = useState(false)

	useEffect(() => {
		!serviceItems && getServiceItems({
			'filter[service_id]': service.id,
			'filter[itemType.scope]': scope,
			include: 'itemType'
		})
			.then(res => setServiceItems(res.data.data))
			.catch(handleError)
	}, [ serviceItems, service, scope ])

	if(!serviceItems) 
		return <Spinner animation='border' size="sm" />

	return (
		<React.Fragment>
			{ serviceItems.length > 0 ? (
				<React.Fragment>
					{ serviceItems.map(item => (
						<ServiceItemCard 
							key={item.id}
							serviceItem={item}
							reload={() => setServiceItems(null)}
							scope={scope}
						/>
					)) }
				</React.Fragment>
			) : (
				<Empty 
					description={`No tiene ${scope === 'product' ? 'productos' : 'servicios'} asociados`}
					imageStyle={{ maxHeight: '75px' }}
				/>
			)}
			<Divider className='my-15' />
			<div className='text-center'>
				<Button 
					variant='outline-primary' 
					size="sm" 
					onClick={() => (scope === 'product') ? setShowNewRecordModal(true) : setShowToggleRecordsModal(true)}
				>
					{scope === 'product' ? 'Asociar Producto al Servicio' : 'Administrar Servicios Utilizados'}
				</Button>
			</div>
			{ showNewRecordModal && (
				<NewServiceItemModal 
					visible
					onClose={() => setShowNewRecordModal(false)}
					reload={() => setServiceItems(null)}
					service={service}
					scope={scope}
				/>
			)}
			{ showToggleRecordsModal && (
				<ToggleServiceItemsModal 
					visible
					onClose={() => setShowToggleRecordsModal(false)}
					reload={() => setServiceItems(null)}
					service={service}
					serviceItems={serviceItems}
					scope={scope}
				/>
			)}
		</React.Fragment>
	)
}