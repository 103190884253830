import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

import EmployeesList from '../../Employees/EmployeesList'

export default function CompanyEmployeesModal({ visible, onClose, company }) {
	return (
		<Modal isOpen={visible} toggle={onClose} size="xl">
			<ModalHeader toggle={onClose}>Listado de Contactos Cliente</ModalHeader>
			<ModalBody>
				<EmployeesList company={company} />
			</ModalBody>
		</Modal>
	)
}