import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
import styled from 'styled-components'

import ServiceResultProfileTopbar from './partials/ServiceResultProfileTopbar'
import ServiceResultDocument from './partials/ServiceResultDocument'
import ServiceResultDocumentJM from './partials/ServiceResultDocumentJM'
import ResultInfoWidget from './partials/widgets/ResultInfoWidget'
import ResultActivitiesWidget from '../ResultActivities/ResultActivitiesWidget'
import ResultAttachmentsWidget from '../ResultAttachments/ResultAttachmentsWidget'
import ServiceVehiclesWidget from '../ServiceVehicles/ServiceVehiclesWidget'

import { getServiceResult } from './services'
import { handleError } from '../../helpers'

export default function ServiceResultProfile(props) {
	const serviceResultId = props.match.params.id
	const [serviceResult, setServiceResult] = useState(null)

	useEffect(() => { 
		if(serviceResult && serviceResult.id !== parseInt(serviceResultId)) 
			setServiceResult(null) 
	}, [ serviceResult, serviceResultId ])

	useEffect(() => {
		!serviceResult && getServiceResult(serviceResultId, {
			include: 'serviceReport.service.serviceFormat,serviceReport.service.location.company,resultAttachments,serviceReport.reportFormat,resultActivities,serviceReport.service.serviceTechnicians.technician,resultFormat,serviceReport.service.serviceVehicles,serviceReport.service.serviceEquipments,serviceReport.service.serviceSecurityElements,serviceReport.service.serviceItems,serviceReport.service.serviceTreatmentAreas,serviceReport.service.serviceDeliveredDocuments,serviceReport.reportDetails,serviceReport.service.employee'
		})
			.then(res => setServiceResult(res.data.data))
			.catch(handleError)
	}, [ serviceResultId, serviceResult ])

	return (
		<React.Fragment>
			<Card>
				<CardBody>
					<ServiceResultProfileTopbar serviceResult={serviceResult} />
				</CardBody>
			</Card>
			<Row>
				<Col sm="12" md="5" lg="4">
					<Card>
						<CardHeader>Detalles del Informe</CardHeader>
						<CardBody>
							<ResultInfoWidget 
								serviceResult={serviceResult} 
								reload={() => setServiceResult(null)}
							/>
						</CardBody>
					</Card>
					{ serviceResult && serviceResult.result_format.has_result_activities === 1 && (
						<Card>
							<CardHeader>Actividades</CardHeader>
							<CardBody>
								<ResultActivitiesWidget
									serviceResult={serviceResult} 
									reload={() => setServiceResult(null)}
								/>
							</CardBody>
						</Card>
					)}
					{ serviceResult && serviceResult.result_format.has_result_attachments === 1 && (
						<Card>
							<CardHeader>Evidencia Fotográfica</CardHeader>
							<CardBody>
								<ResultAttachmentsWidget
									serviceResult={serviceResult} 
									reload={() => setServiceResult(null)}
								/>
							</CardBody>
						</Card>
					)}
					{ serviceResult && serviceResult.service_report.service.service_format.has_vehicles === 1 && (
						<Card>
							<CardHeader>Vehículos Asignados</CardHeader>
							<CardBody>
								<ServiceVehiclesWidget serviceId={serviceResult.service_report.service.id} />
							</CardBody>
						</Card>
					)}
				</Col>
				<Col sm="12" md="7" lg="8">
					<Card>
						<CardHeader>Vista Previa</CardHeader>
						<CardBody className='p-5'>
							<PreviewContainer>
								{ serviceResult && serviceResult.result_format.code.includes('GO') && (
									<ServiceResultDocument serviceResult={serviceResult} />
								)}
								{ serviceResult && serviceResult.result_format.code.includes('JM') && (
									<ServiceResultDocumentJM serviceResult={serviceResult} />
								)}
							</PreviewContainer>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</React.Fragment>
	)
}

const PreviewContainer = styled.div`
	background-color: #F8F9FA;
	padding: 10px;
	max-width: 100%;
	overflow-x: scroll;
`