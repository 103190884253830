import ingealimentosApi, { getDomain } from '../../services/ingealimentosApi'

export const getReviewResults = params => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.get('/review_results', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getReviewResult = (id, params) => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.get(`/review_results/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeReviewResult = data => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.post('/review_results', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateReviewResult = (id, data) => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.put(`/review_results/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteReviewResult = id => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.delete(`/review_results/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchReviewResults = async (term, token) => (
	fetch( getDomain()+`/review_results?filter[name]=${term}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(item => ({
				label: item.name,
				value: item.id,
			})),
		)
)