import React, { useState, useEffect } from 'react'
import { Card, CardBody } from 'reactstrap'

import ListTopBar from '../../components/ListTopBar'

import VehiclesTable from './partials/VehiclesTable'
import NewVehicleModal from './partials/NewVehicleModal'

import { handleError, parsePagination } from '../../helpers'
import { getVehicles } from './services'

export default function VehiclesList() {
	const [vehicles, setVehicles] = useState(null)
	const [filterType, setFilterType] = useState('placas')
	const [filterValue, setFilterValue] = useState('')
	const [filterScope, setFilterScope] = useState('active')
	const [pagination, setPagination] = useState({page: 1, per_page: 50, total: 0})
	const [showNewRecordModal, setShowNewRecordModal] = useState(false)

	useEffect(() => {
		!vehicles && getVehicles({
			...pagination,
			[`filter[${filterType}]`]: filterValue,
			[filterScope ? `filter[${filterScope}]` : 'noscope']: 1,
			include: ''
		})
			.then(res => {
				setVehicles(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [ vehicles, pagination, filterType, filterValue, filterScope ])

	return (
		<React.Fragment>
			<ListTopBar 
				title="Vehículos Vinculados"
				subtitle="Listado de vehículos vinculados a Ingealimentos y Andina de Servicios"
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				filterScope={filterScope}
				setFilterScope={setFilterScope}
				reload={() => setVehicles(null)}
				options={[
					{ label: 'Buscar por placas', value: 'placas' },
					{ label: 'Buscar por nombre', value: 'name' },
				]}
				scopes={[
					{ label: 'Vehículos Activos', value: 'active' },
					{ label: 'Vehículos Inactivos', value: 'inactive' },
				]}
				ctaText="Agregar Registro"
				handleCtaClick={() => setShowNewRecordModal(true)}
			/>
			<Card>
				<CardBody>
					<VehiclesTable 
						vehicles={vehicles}
						reload={() => setVehicles(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
			{ showNewRecordModal && (
				<NewVehicleModal 
					visible
					onClose={() => setShowNewRecordModal(false)}
					reload={() => setVehicles(null)}
				/>
			)}
		</React.Fragment>
	)
}