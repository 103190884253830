import React, { useState } from 'react'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form, Spinner } from 'react-bootstrap'
import { message } from 'antd'

import ServerSideSelect from '../../../components/ServerSideSelect'

import { attachEmployeeLocation, searchEmployees } from '../../Employees/services'
import { handleError } from '../../../helpers'

export default function AttachEmployeeToLocationModal({ visible, onClose, reload, location }) {
    const [selectedEmployee, setSelectedEmployee] = useState([])
    const [loading, setLoading] = useState(false)

    const onSubmit = () => {
        if(!selectedEmployee)
            return message.warning('Debe seleccionar primer un contacto')
        
        setLoading(true)
        attachEmployeeLocation(selectedEmployee.value, location.id)
            .then(res => {
                message.success(res.data.message)
                reload()
                onClose()
            })
            .catch(error => handleError(error, setLoading))
    }

    return (
        <Modal isOpen={visible} toggle={onClose}>
            <ModalHeader toggle={onClose}>Asociar Contacto a Sede</ModalHeader>
            <ModalBody>
                <Form.Group className="form-group">
                    <Form.Label>Buscar contacto <span className='text-danger'>*</span></Form.Label>
                    <ServerSideSelect
						value={selectedEmployee}
						placeholder="Buscar por nombre de contacto"
						fetchOptions={searchEmployees}
                        scopeId={location.company_id}
						onChange={record => setSelectedEmployee(record)}
						className="full-width"
					/>
                </Form.Group>
                <Button
                    color="primary"
                    outline
                    onClick={onSubmit}
                    disabled={loading}
                >
                    { loading && <Spinner animation='border' size="sm" /> }{" "}
                    Asociar Contacto
                </Button>
            </ModalBody>
        </Modal>
    )
}