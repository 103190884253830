import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faCircleCheck } from '@fortawesome/free-solid-svg-icons'

export default function ReportDocSecurityElements({ serviceReport, formatSettings }) {
	const serviceSecurityElementOpts = formatSettings.filter(r => r.setting_type === 'security_element_type')

	const isRelatedToService = setting => {
		return serviceReport.service.service_security_elements.filter(i => i.security_element_type_id === setting.id).length > 0
	}

	return (
		<React.Fragment>
			<div className='detailsDiv mb-0'>
				{ serviceSecurityElementOpts.map( opt => (
					<h2 className='inline-block ml-10 mb-0' key={opt.id}>
						<FontAwesomeIcon 
							color={isRelatedToService(opt.setting) ? '#333' : '#ccc'} 
							icon={isRelatedToService(opt.setting) ? faCircleCheck : faCircle} 
							className="mr-5" 
						/>
						<small>{ opt.setting.name }</small>
					</h2>
				)) }
			</div>
		</React.Fragment>
	)
}