import ingealimentosApi, { getDomain } from '../../services/ingealimentosApi'

export const getServiceSecurityElements = params => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.get('/service_security_elements', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getServiceSecurityElement = (id, params) => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.get(`/service_security_elements/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeServiceSecurityElement = data => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.post('/service_security_elements', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateServiceSecurityElement = (id, data) => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.put(`/service_security_elements/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteServiceSecurityElement = id => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.delete(`/service_security_elements/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchServiceSecurityElements = async (term, token) => (
	fetch( getDomain()+`/service_security_elements?filter[name]=${term}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(item => ({
				label: item.name,
				value: item.id,
			})),
		)
)