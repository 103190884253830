import React, { useEffect, useState } from 'react'
import { Spinner, Button } from 'react-bootstrap'
import { Divider, Empty } from 'antd'

import ServiceSecurityElementCard from './partials/ServiceSecurityElementCard'
import ToggleServiceSecurityElementsModal from './partials/ToggleServiceSecurityElementsModal'

import { getServiceSecurityElements } from './services'
import { handleError } from '../../helpers'

export default function ServiceSecurityElementsWidget({ service }) {
	const [serviceSecurityElements, setServiceSecurityElements] = useState(null)
	const [showToggleRecordsModal, setShowToggleRecordsModal] = useState(false)

	useEffect(() => {
		!serviceSecurityElements && getServiceSecurityElements({
			'filter[service_id]': service.id,
			include: 'securityElementType'
		})
			.then(res => setServiceSecurityElements(res.data.data))
			.catch(handleError)
	}, [ serviceSecurityElements, service ])

	if(!serviceSecurityElements) 
		return <Spinner animation='border' size="sm" />

	return (
		<React.Fragment>
			{ serviceSecurityElements.length > 0 ? (
				<React.Fragment>
					{ serviceSecurityElements.map(item => (
						<ServiceSecurityElementCard 
							key={item.id}
							serviceSecurityElement={item}
							reload={() => setServiceSecurityElements(null)}
						/>
					)) }
				</React.Fragment>
			) : (
				<Empty 
					description={`No se tienen elementos registrados`}
					imageStyle={{ maxHeight: '75px' }}
				/>
			)}
			<Divider className='my-15' />
			<div className='text-center'>
				<Button 
					variant='outline-primary' 
					size="sm" 
					onClick={() => setShowToggleRecordsModal(true)}
				>
					Administrar Elementos de Protección
				</Button>
			</div>
			{ showToggleRecordsModal && (
				<ToggleServiceSecurityElementsModal 
					visible
					onClose={() => setShowToggleRecordsModal(false)}
					reload={() => setServiceSecurityElements(null)}
					service={service}
					serviceSecurityElements={serviceSecurityElements}
				/>
			)}
		</React.Fragment>
	)
}