import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form } from 'react-bootstrap'
import { message } from 'antd'

import { updateResultAttachment } from '../services'
import { handleError } from '../../../helpers'

export default function EditResultAttachmentModal({ visible, onClose, resultAttachment, reload }) {
	const [loading, setLoading] = useState(false)
	const { handleSubmit, register, formState: { errors } } = useForm({
		defaultValues: { order: resultAttachment.order }
	})

	const onSubmit = values => {
		updateResultAttachment(resultAttachment.id, values)
			.then(res => {
				message.success(res.data.message)
				reload()
				onClose()
			})
			.catch(error => handleError(error, setLoading))
	}

	return (
		<Modal isOpen={visible} toggle={onClose}>
			<ModalHeader toggle={onClose}>Actualizar Evidencia</ModalHeader>
			<ModalBody>
				<Form onSubmit={handleSubmit(onSubmit)}>
					<Form.Group className='form-group'>
						<Form.Label>Posición <span className='text-danger'>*</span></Form.Label>
						<Form.Control 
							type="number"
							{...register('order', { required: true })}
							placeholder="Escribe aquí..."
						/>
						{ errors.order && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
					</Form.Group>
					<Button color="primary" type="submit" disabled={loading}>
						Actualizar Registro
					</Button>
				</Form>
			</ModalBody>
		</Modal>
	)
}