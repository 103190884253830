import ingealimentosApi, { getDomain } from '../../services/ingealimentosApi'

export const getCompanies = params => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.get('/companies', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getCompany = (id, params) => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.get(`/companies/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeCompany = data => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.post('/companies', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateCompany = (id, data) => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.put(`/companies/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteCompany = id => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.delete(`/companies/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchCompanies = async (term, token) => (
	fetch( getDomain()+`/companies?filter[legal_name]=${term}&filter[active]=1&filter[type]=cliente`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(item => ({
				label: `${item.legal_name} (${item.document})`,
				value: item.id,
			})),
		)
)