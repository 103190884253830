import React, { useState } from 'react'
import { Spinner, Button } from 'react-bootstrap'
import { Divider, Empty } from 'antd'

import ReviewTaskCard from './partials/ReviewTaskCard'
import NewReviewTaskModal from './partials/NewReviewTaskModal'

export default function ReviewTasksWidget({ serviceReview, reload }) {	
	const [showNewRecordModal, setShowNewRecordModal] = useState(false)

	if(!serviceReview) 
		return <Spinner animation='border' size="sm" />

	const reviewTasks = serviceReview.review_tasks

	return (
		<React.Fragment>
			{ reviewTasks.length > 0 ? (
				<React.Fragment>
					{ reviewTasks.map(item => (
						<ReviewTaskCard 
							key={item.id}
							reviewTask={item}
							reload={reload}
						/>
					)) }
				</React.Fragment>
			) : (
				<Empty 
					description={`No se tiene actividades registradas`}
					imageStyle={{ maxHeight: '75px' }}
				/>
			)}
			<Divider className='my-15' />
			<div className='text-center'>
				<Button 
					variant='outline-primary' 
					size="sm" 
					onClick={() => setShowNewRecordModal(true)}
				>
					Registrar Actividad
				</Button>
			</div>
			{ showNewRecordModal && (
				<NewReviewTaskModal 
					visible
					onClose={() => setShowNewRecordModal(false)}
					reload={reload}
					serviceReview={serviceReview}
				/>
			)}
		</React.Fragment>
	)
}