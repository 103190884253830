import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { Form, Spinner } from 'react-bootstrap'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { message } from 'antd'

import ServiceReportForm from './ServiceReportForm'
import { storeServiceReport } from '../services'

import { handleError } from '../../../helpers'

export default function NewServiceReportModal({ visible, onClose, service }) {
	const [loading, setLoading] = useState(false)
	const history = useHistory()
	const { register, handleSubmit, formState: { errors } } = useForm({
		defaultValues: {
			service_id: service.id,
			report_format_id: service.service_format.report_format_id
		}
	})

	const onSubmit = values => {
		setLoading(true)
		storeServiceReport(values)	
			.then(res => {
				message.success(res.data.message)
				history.push(`/service_reports/${res.data.data.id}`)
			})
			.catch(error => handleError(error, setLoading))
	}

	return (
		<Modal isOpen={visible} toggle={onClose}>
			<ModalHeader toggle={onClose}>Crear Reporte de Servicio</ModalHeader>
			<ModalBody>
				<Form onSubmit={handleSubmit(onSubmit)}>
					<ServiceReportForm 
						register={register}
						errors={errors}
					/>
					<Button color="primary" type="submit" disabled={loading}>
						{ loading && <Spinner animation='border' size="sm" /> }
						Crear Reporte de Servicio
					</Button>
				</Form>
			</ModalBody>
		</Modal>
	)
}