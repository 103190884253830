import React from 'react'
import { Form } from 'react-bootstrap'
import { Col, Row } from 'reactstrap'
import { numberFormat } from '../../../helpers'

export default function ResultActivityForm({ register, errors, watch, editable, serviceResult }) {	
	return (
		<React.Fragment>
			<Form.Group className="form-group">
				<Form.Label>Numeral / Orden <span className='text-danger'>*</span></Form.Label>
				<Form.Control 
					type="number"
					{...register('order', { required:true })}
					placeholder="0"
				/>
				{ errors.order && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
			<Form.Group className='form-group'>
				<Form.Label>Descripción <span className='text-danger'>*</span></Form.Label>
				<Form.Control 
					as="textarea"
					{...register('description', { required:true })}
					placeholder="Escriba aquí..."
					style={{ height: '100px' }}
				/>
				{ errors.description && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
			{ serviceResult && serviceResult.result_format.code.includes('JM') && (
				<React.Fragment>
					<Row>
						<Form.Group className='form-group' as={Col}>
							<Form.Label>Cantidad <span className='text-warning'>*</span></Form.Label>
							<Form.Control 
								type="number"
								{...register('quantity', { required:true })}
							/>
							{ errors.quantity && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
						</Form.Group>
						<Form.Group className='form-group' as={Col}>
							<Form.Label>Volumen <span className='text-warning'>*</span></Form.Label>
							<Form.Control 
								type="number"
								{...register('volume', { required:true })}
							/>
							{ errors.volume && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
						</Form.Group>
					</Row>
					<Row>
						<Form.Group className='form-group' as={Col}>
							<Form.Label>Valor Unidad <span className='text-warning'>*</span></Form.Label>
							<Form.Control 
								type="number"
								{...register('value_per_unit', { required:true })}
							/>
							{ errors.value_per_unit && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
						</Form.Group>
						<Form.Group className='form-group' as={Col}>
							<Form.Label>Valor Total</Form.Label>
							<Form.Control 
								disabled
								value={numberFormat(parseInt(watch('value_per_unit'))*watch('quantity'))}
							/>
						</Form.Group>
						<Form.Group className='form-group' as={Col}>
							<Form.Label>IVA Valor Total</Form.Label>
							<Form.Control 
								disabled
								value={numberFormat(parseInt(watch('value_per_unit'))*watch('quantity')*.19)}
							/>
						</Form.Group>
					</Row>
				</React.Fragment>
			)}
		</React.Fragment>
	)
}