import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import ServiceResultDocument from './partials/ServiceResultDocument'
import ServiceResultDocumentJM from './partials/ServiceResultDocumentJM'

import { getPublicServiceResult } from './services'
import { handleError } from '../../helpers'

export default function ServiceResultPreview(props) {
	const serviceResultId = props.match.params.id
	const [serviceResult, setServiceResult] = useState(null)

	useEffect(() => {
		setTimeout(() => window.print(), 5000);
	}, [])

	useEffect(() => { 
		if(serviceResult && serviceResult.id !== parseInt(serviceResultId)) 
			setServiceResult(null) 
	}, [ serviceResult, serviceResultId ])

	useEffect(() => {
		!serviceResult && getPublicServiceResult(serviceResultId, {
			include: 'serviceReport.service.serviceFormat,serviceReport.service.location.company,resultAttachments,serviceReport.reportFormat,resultActivities,serviceReport.service.serviceTechnicians.technician,resultFormat,serviceReport.service.serviceVehicles,serviceReport.service.serviceEquipments,serviceReport.service.serviceSecurityElements,serviceReport.service.serviceItems,serviceReport.service.serviceTreatmentAreas,serviceReport.service.serviceDeliveredDocuments,serviceReport.reportDetails,serviceReport.service.employee'
		})
			.then(res => setServiceResult(res.data.data))
			.catch(handleError)
	}, [ serviceResultId, serviceResult ])

	return (
		<React.Fragment>
			<PreviewContainer className='printFormatResults'>
				{ serviceResult && serviceResult.result_format.code.includes('GO') && (
					<ServiceResultDocument serviceResult={serviceResult} />
				)}
				{ serviceResult && serviceResult.result_format.code.includes('JM') && (
					<ServiceResultDocumentJM serviceResult={serviceResult} />
				)}
			</PreviewContainer>
		</React.Fragment>
	)
}

const PreviewContainer = styled.div`
	background-color: #F8F9FA;
	transform: scale(0.95);
	max-width: 100%;
	overflow-x: scroll;
`