import React, { useEffect, useState } from 'react'
import { Spinner, Button } from 'react-bootstrap'
import { Divider, Empty } from 'antd'

import ServiceTreatmentAreaCard from './partials/ServiceTreatmentAreaCard'
import ToggleServiceTreatmentAreasModal from './partials/ToggleServiceSecurityElementsModal'

import { getServiceTreatmentAreas } from './services'
import { handleError } from '../../helpers'

export default function ServiceTreatmentAreasWidget({ service }) {
	const [serviceTreatmentAreas, setServiceTreatmentAreas] = useState(null)
	const [showToggleRecordsModal, setShowToggleRecordsModal] = useState(false)

	useEffect(() => {
		!serviceTreatmentAreas && getServiceTreatmentAreas({
			'filter[service_id]': service.id,
			include: 'treatmentAreaType'
		})
			.then(res => setServiceTreatmentAreas(res.data.data))
			.catch(handleError)
	}, [ serviceTreatmentAreas, service ])

	if(!serviceTreatmentAreas) 
		return <Spinner animation='border' size="sm" />

	return (
		<React.Fragment>
			{ serviceTreatmentAreas.length > 0 ? (
				<React.Fragment>
					{ serviceTreatmentAreas.map(item => (
						<ServiceTreatmentAreaCard 
							key={item.id}
							serviceTreatmentArea={item}
							reload={() => setServiceTreatmentAreas(null)}
						/>
					)) }
				</React.Fragment>
			) : (
				<Empty 
					description={`No se tienen areas tratadas asociadas`}
					imageStyle={{ maxHeight: '75px' }}
				/>
			)}
			<Divider className='my-15' />
			<div className='text-center'>
				<Button 
					variant='outline-primary' 
					size="sm" 
					onClick={() => setShowToggleRecordsModal(true)}
				>
					Registrar Area de Tratamiento
				</Button>
			</div>
			{ showToggleRecordsModal && (
				<ToggleServiceTreatmentAreasModal
					visible
					onClose={() => setShowToggleRecordsModal(false)}
					reload={() => setServiceTreatmentAreas(null)}
					service={service}
					serviceTreatmentAreas={serviceTreatmentAreas}
				/>
			)}
		</React.Fragment>
	)
}