import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import ServicesList from './ServicesList'
import ServiceProfile from './ServiceProfile'

export default function Services() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/services" component={ServicesList} />
				<Route exact path="/services/:id" render={props => <ServiceProfile serviceId={props.match.params.id} />} />
				
				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}