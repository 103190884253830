import React, { useState } from 'react'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Divider, message, Popconfirm, Tooltip } from 'antd'

import EditReviewTaskModal from './EditReviewTaskModal'
import ListItemCard from '../../../components/ListItemCard'

import { deleteReviewTask } from '../services'
import { handleError } from '../../../helpers'

export default function ReviewTaskCard({ reviewTask, reload }) {
	const [showEditModal, setShowEditModal] = useState(false)

	const handleDelete = () => {
		deleteReviewTask(reviewTask.id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(handleError)
	}

	return (
		<React.Fragment>
			<ListItemCard>
				<div className='float-right'>
					<Tooltip title="Editar" onClick={() => setShowEditModal(true)}>
						<FontAwesomeIcon className='text-link' icon={faEdit} />
					</Tooltip>
					<Divider type="horizontal" className='my-5' />
					<Tooltip title="Eliminar" placement='bottom'>
						<Popconfirm
							title="Esta acción no se puede revertir"
							okText="Eliminar"
							cancelText="Cancelar"
							okButtonProps={{ danger:true }}
							onConfirm={handleDelete}
						>
							<FontAwesomeIcon className='text-link' icon={faTrash} />
						</Popconfirm>
					</Tooltip>
				</div>
				<p className='bold mb-3'>{reviewTask.task_name}</p>
				<p className='mb-0 text-muted small'>
					Orden: {reviewTask.task_order} | Resultado: {reviewTask.is_meet ? 'Cumple' : (reviewTask.is_meet === 0 ? 'No Cumple' : 'Sin registro')}
				</p>
				<p className='mb-0 text-muted small'>
					Observaciones: {reviewTask.observations ? reviewTask.observations : <em>Sin detalle registrado</em>}
				</p>
			</ListItemCard>
			{ showEditModal && (
				<EditReviewTaskModal 
					visible
					onClose={() => setShowEditModal(false)}
					reviewTask={reviewTask}
					reload={reload}
				/>
			)}
		</React.Fragment>
	)
}