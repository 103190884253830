import React, { useState, useEffect } from 'react'
import { Card, CardBody } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedo } from '@fortawesome/free-solid-svg-icons'

import ServicesTable from './partials/ServicesTable'
import NewServiceModal from './partials/NewServiceModal'

import ListTopBar from '../../components/ListTopBar'

import { getServices } from './services'
import { handleError, parsePagination } from '../../helpers'

export default function ServicesList() {
	const [services, setServices] = useState(null)
	const [filterType, setFilterType] = useState('id')
	const [filterValue, setFilterValue] = useState('')
	const [filterScope, setFilterScope] = useState('')
	const [pagination, setPagination] = useState({page: 1, per_page: 50, total: 0})
	const [showNewRecordModal, setShowNewRecordModal] = useState(false)

	useEffect(() => {
		!services && getServices({
			...pagination,
			[`filter[${filterType}]`]: filterValue,
			[filterScope ? `filter[${filterScope}]` : 'noscope']: 1,
			include: 'location.company,serviceFormat,user,serviceReport',
			sort: '-service_at'
		})
			.then(res => {
				setServices(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [ services, pagination, filterType, filterValue, filterScope ])

	const reloadOnlyServices = () => {
		getServices({
			...pagination,
			page: pagination.current_page,
			[`filter[${filterType}]`]: filterValue,
			[filterScope ? `filter[${filterScope}]` : 'noscope']: 1,
			include: 'location.company,serviceFormat,user,serviceReport',
			sort: '-service_at'
		})
			.then(res => setServices(res.data.data))
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<ListTopBar 
				title="Programación y Gestión de Servicios"
				subtitle="Listado de servicios registrados en la plataforma"
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				filterScope={filterScope}
				setFilterScope={setFilterScope}
				reload={() => setServices(null)}
				options={[
					{ label: 'Buscar por consecutivo', value: 'id' },
					{ label: 'Buscar por razón social', value: 'location.company.legal_name' },
					{ label: 'Buscar por NIT de cliente', value: 'location.company.document' },
					{ label: 'Buscar nombre del servicio', value: 'serviceFormat.name' },
					{ label: 'Buscar fecha del servicio (YYYY-MM-DD)', value: 'service_at' },
					{ label: 'Buscar por nombre del técnico asignado', value: 'serviceTechnicians.technician.name' },
					{ label: 'Buscar por cedula del técnico asignado', value: 'serviceTechnicians.technician.document' },
					{ label: 'Buscar por placas de vehículo asociado', value: 'serviceVehicles.vehicle.placas' },
					{ label: 'Buscar por asesor asignado', value: 'user.name' },
				]}
				scopes={[
					{ label: 'Servicios Activos', value: 'active' },
					{ label: 'Servicios Vencidos', value: 'expired' },
				]}
				ctaText="Programar Servicio"
				handleCtaClick={() => setShowNewRecordModal(true)}
				secundaryCtaText={(
					<FontAwesomeIcon icon={faRedo} color="white" spin={!services} />
				)}
				handleSecundaryCtaClick={() => setServices(null)}
			/>
			<Card>
				<CardBody>
					<ServicesTable 
						services={services}
						reload={() => setServices(null)}
						reloadOnlyServices={reloadOnlyServices}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
			{ showNewRecordModal && (
				<NewServiceModal 
					visible
					onClose={() => setShowNewRecordModal(false)}
					reload={() => setServices(null)}
				/>
			)}
		</React.Fragment>
	)
}