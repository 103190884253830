import React, { useState } from 'react'
import { Divider } from 'antd'
import { Spinner } from 'react-bootstrap'
import { Button } from 'reactstrap'

import EditServiceReportModal from '../EditServiceReportModal'

export default function ReportObservationsWidget({ serviceReport, reload }) {
	const [showEditModal, setShowEditModal] = useState(false)

	if(!serviceReport) 
		return <Spinner animation='border' size="sm" />

	return (
		<React.Fragment>
			{/* <p className='item-label'>Observaciones del Técnico:</p>
			<p>{serviceReport.technician_observations ? serviceReport.technician_observations : <em>sin registro</em>}</p> */}
			{ serviceReport.report_format.has_client_observations === 1 && (
				<React.Fragment>
					<p className='item-label'>Observaciones del Cliente:</p>
					<p>{serviceReport.client_observations ? serviceReport.client_observations : <em>sin registro</em>}</p>
				</React.Fragment>
			)}
			{ serviceReport.report_format.has_physic_recomendations === 1 && (
				<React.Fragment>
					<p className='item-label'>Recomendación de Control Físico:</p>
					<p>{serviceReport.physic_recomendations ? serviceReport.physic_recomendations : <em>sin registro</em>}</p>
				</React.Fragment>
			)}
			{ serviceReport.report_format.has_ambient_recomendations === 1 && (
				<React.Fragment>
					<p className='item-label'>Recomendación de Control Ambiental:</p>
					<p>{serviceReport.ambient_recomendations ? serviceReport.ambient_recomendations : <em>sin registro</em>}</p>
				</React.Fragment>
			)}
			<p className='item-label'>Recomendaciones Finales:</p>
			<p>{serviceReport.final_recomendations ? serviceReport.final_recomendations : <em>sin registro</em>}</p>
			<Divider className='my-10' />
			<div className='text-center'>
				<Button
					color='primary'
					outline
					size="sm" 
					onClick={() => setShowEditModal(true)}
				>
					Actualizar Observaciones
				</Button>
			</div>
			{ showEditModal && (
				<EditServiceReportModal 
					visible
					onClose={() => setShowEditModal(false)}
					serviceReport={serviceReport}
					reload={reload}
					editable={{
						technician_observations: true,
						client_observations: serviceReport.report_format.has_client_observations === 1,
						physic_recomendations: serviceReport.report_format.has_physic_recomendations === 1,
						ambient_recomendations: serviceReport.report_format.has_ambient_recomendations === 1,
						final_recomendations: true,
					}}
				/>
			)}
		</React.Fragment>
	)
}