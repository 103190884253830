import ingealimentosApi, { getDomain } from '../../services/ingealimentosApi'

export const getLocations = params => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.get('/locations', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getLocation = (id, params) => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.get(`/locations/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeLocation = data => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.post('/locations', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateLocation = (id, data) => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.put(`/locations/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteLocation = id => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.delete(`/locations/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchLocations = async (term, token, scope) => (
	fetch( getDomain()+`/locations?filter[name]=${term}&filter[company_id]=${scope ? scope : ''}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(item => ({
				label: item.name,
				value: item.id,
			})),
		)
)