import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
import styled from 'styled-components'

import ServiceReportProfileTopbar from './partials/ServiceReportProfileTopbar'
import ServiceReportDocument from './partials/ServiceReportDocument'
import ReportInfoWidget from './partials/widgets/ReportInfoWidget'
import ReportObservationsWidget from './partials/widgets/ReportObservationsWidget'
import ReportDetailsWidget from '../ReportDetails/ReportDetailsWidget'

import { getServiceReport } from './services'
import { handleError } from '../../helpers'
import NotificationLogsWidget from './partials/widgets/NotificationLogsWidget'

export default function ServiceReportProfile(props) {
	const serviceReportId = props.match.params.id
	const [serviceReport, setServiceReport] = useState(null)

	useEffect(() => { 
		if(serviceReport && serviceReport.id !== parseInt(serviceReportId)) 
			setServiceReport(null) 
	}, [ serviceReport, serviceReportId ])

	useEffect(() => {
		!serviceReport && getServiceReport(serviceReportId, {
			include: 'reportFormat,service.serviceFormat,service.location.company,certificate,service.serviceTechnicians.technician,service.serviceVehicles,service.serviceEquipments,service.serviceSecurityElements,service.serviceItems,service.serviceTreatmentAreas,service.serviceDeliveredDocuments,reportDetails,service.employee,serviceResult,notificationLogs'
		})
			.then(res => setServiceReport(res.data.data))
			.catch(handleError)
	}, [ serviceReportId, serviceReport ])

	return (
		<React.Fragment>
			<Card>
				<CardBody>
					<ServiceReportProfileTopbar serviceReport={serviceReport} />
				</CardBody>
			</Card>
			<Row>
				<Col sm="12" md="4" lg="3">
					<Card>
						<CardHeader>Detalles del Reporte</CardHeader>
						<CardBody>
							<ReportInfoWidget 
								serviceReport={serviceReport} 
								reload={() => setServiceReport(null)}
							/>
						</CardBody>
					</Card>
					<Card>
						<CardHeader>Observaciones y Recomendaciones</CardHeader>
						<CardBody>
							<ReportObservationsWidget 
								serviceReport={serviceReport} 
								reload={() => setServiceReport(null)}
							/>
						</CardBody>
					</Card>
					{ serviceReport && serviceReport.report_format.has_report_details === 1 && (
						<Card>
							<CardHeader>Resultados del Servicio</CardHeader>
							<CardBody>
								<ReportDetailsWidget
									serviceReport={serviceReport} 
									reload={() => setServiceReport(null)}
								/>
							</CardBody>
						</Card>
					)}
					<Card>
						<CardHeader>Notificaciones Enviadas</CardHeader>
						<CardBody>
							<NotificationLogsWidget 
								serviceReport={serviceReport}
								reload={() => setServiceReport(null)}
							/>
						</CardBody>
					</Card>
				</Col>
				<Col sm="12" md="8" lg="9">
					<Card>
						<CardHeader>Vista Previa</CardHeader>
						<CardBody className='p-5'>
							<PreviewContainer>
								<ServiceReportDocument serviceReport={serviceReport} />
							</PreviewContainer>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</React.Fragment>
	)
}

const PreviewContainer = styled.div`
	background-color: #F8F9FA;
	padding: 10px;
	max-width: 100%;
	overflow-x: scroll;
`