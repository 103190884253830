import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import CertificateDocument from './partials/CertificateDocument'
import CertificatesProfileTopbar from './partials/CertificatesProfileTopbar'

import { getPublicCertificate } from './services'
import { handleError } from '../../helpers'
import { Card, CardBody } from 'reactstrap'

export default function CertificatePreview(props) {
	const certificateId = props.match.params.id
	const [certificate, setCertificate] = useState(null)

	useEffect(() => {
		!props.isAdmin && setTimeout(() => window.print(), 2500);
	}, [props])

	useEffect(() => { 
		if(certificate && certificate.id !== parseInt(certificateId)) 
			setCertificate(null) 
	}, [ certificate, certificateId ])

	useEffect(() => {
		!certificate && getPublicCertificate(certificateId, {
			include: 'serviceReport.service.location.company,serviceReport.service.serviceFormat'
		})
			.then(res => setCertificate(res.data.data))
			.catch(handleError)
	}, [ certificateId, certificate ])

	return (
		<React.Fragment>
			{ props.isAdmin && (
				<Card className='no-print'>
					<CardBody>
						<CertificatesProfileTopbar certificate={certificate} />
					</CardBody>
				</Card>
			)}
			<PreviewContainer className='printFormatContainer'>
				<CertificateDocument 
					certificate={certificate} 
				/>
			</PreviewContainer>
		</React.Fragment>
	)
}

const PreviewContainer = styled.div`
	background-color: #F8F9FA;
	padding: 10px;
	max-width: 100%;
	overflow-x: scroll;
`