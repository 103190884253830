import React, { useState, useEffect } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Spinner } from 'react-bootstrap'
import { Empty, Switch, message } from 'antd'

import { deleteServiceTreatmentArea, storeServiceTreatmentArea } from '../services'
import { getFormatTypes } from '../../FormatTypes/services'
import { handleError } from '../../../helpers'

export default function ToggleServiceTreatmentAreasModal({ 
	visible, onClose, reload, service, serviceTreatmentAreas 
}) {
	const [options, setOptions] = useState(null)
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		!options && service && getFormatTypes({
			'filter[format_type]':'report_format',
			'filter[format_id]': service.service_format.report_format_id,
			'filter[setting_type]': 'treatment_area_type',
			include: 'setting'
		})
			.then(res => setOptions(res.data.data))
			.catch(handleError)
	}, [ options, service ])

	const handleStore = id => {
		setLoading(true)
		storeServiceTreatmentArea({
			service_id: service.id,
			treatment_area_type_id: id
		})
			.then(res => {
				message.success(res.data.message)
				reload()
				setLoading(false)
			})
			.catch(error => handleError(error, setLoading))
	}

	const handleDelete = id => {
		setLoading(true)
		let serviceTreatmentArea = serviceTreatmentAreas.filter(s => s.treatment_area_type.id === id)[0]
		deleteServiceTreatmentArea(serviceTreatmentArea.id)
			.then(res => {
				message.success(res.data.message)
				reload()
				setLoading(false)
			})
			.catch(handleError, setLoading)
	}

	const isChecked = id => {
		let opt = serviceTreatmentAreas.filter(s => s.treatment_area_type.id === id)
		return (opt.length > 0) ? true : false
	}

	return (
		<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Areas Tratadas</ModalHeader>
				<ModalBody>
					{ options ? (
						<React.Fragment>
							{ options.length > 0 ? (
								<React.Fragment>
									{ options.map(opt => (
										<div key={opt.setting.id} className='mb-10'>
											<Switch
												loading={loading}
												checked={isChecked(opt.setting.id)}
												onChange={val => val ? (
													handleStore(opt.setting.id) 
												) : (
													handleDelete(opt.setting.id)
												)}
											/>
											<span className='ml-5'>{opt.setting.name}</span>
										</div>
									)) }
								</React.Fragment>
							) : <Empty description="No existen opciones para este formato" /> }
						</React.Fragment>
					) : <Spinner animation="border" size="sm" /> }
				</ModalBody>
		</Modal>
	)
}