import React from 'react'
import styled from 'styled-components'
import { Spinner } from 'react-bootstrap'
import 'moment/locale/es'
import ReviewDocHeader from './document/ReviewDocHeader'
import ReviewDocWithTasks from './document/ReviewDocWithTasks'
import ReviewDocWithResults from './document/ReviewDocWithResults'

export default function ServiceReviewDocument({ serviceReview }) {
	
	if(!serviceReview) return <Spinner animation='border' />

	return (
		<React.Fragment>
			<Document>
				{ serviceReview.review_format.slug === 'condiciones_obra_civil' && (
					<VerticalLayout>
						<table>
							<thead>
								<tr>
									<th /><th /><th /><th /><th /><th /><th /><th /><th /><th /><th /><th /><th /><th /><th /><th /><th /><th /><th /><th /><th /><th /><th /><th /><th /><th /><th /><th /><th /><th /><th /><th /><th /><th />
								</tr>
							</thead>
							<tbody>
								<ReviewDocHeader serviceReview={serviceReview} />
								<tr><td colSpan={34} className="hr"></td></tr>

								{ serviceReview.review_format.has_review_tasks === 1 && (
									<ReviewDocWithTasks serviceReview={serviceReview} />
								)}
							</tbody>
						</table>
					</VerticalLayout>
				)}
				{ serviceReview.review_format.slug === 'control_de_plagas' && (
					<HorizontalLayout>						
						<ReviewDocWithResults serviceReview={serviceReview} />
					</HorizontalLayout>
				)}
			</Document>
		</React.Fragment>
	)
}

const Document = styled.div`	
	font-family: "Arial Narrow", Arial, sans-serif;
	h1 {
		font-size: 16px;
		margin-bottom: 0px;
		line-height: 16px;
	}
	h2 {
		font-size: 16px;
	}
	h5 {
		font-size: 14px;
		margin-bottom: 0px;
	}
	p {
		font-size: 14px;
		margin-bottom: 0px;
	}
	p.small {
		font-size: 13px;
	}
	p.xs {
		font-size: 12px;
	}
	td, th {
		font-size: 14px;
		height: 15px;
	}
	.sm-width {
		max-width: 550px;
		margin: auto;
	}
	.border {
		border: 1px solid #919191!important;
  	}
  	.logoDiv {
		background-color: white;
		border: 4px solid white;
		position: relative;
		top: -15px;
  	}
	.titleDiv {
		margin: auto;
		text-align: center;
	}
	.detailsDiv {
		border: 1px solid #888;
		border-radius: 10px;
		padding: 7px 10px;
		margin-bottom: 5px;
	}
	table td {
		border: 1px solid #888;
		padding-left: 10px;
		padding-right: 10px;
	}
	tr td.hr {
		background-color: #d9e2f3;
		height: 5px !important;
	}
	table, tr {
		width: 100%;
	}
	table th {
		height: 0px;
	}
`

const VerticalLayout = styled.div`
	width: 216mm;
	min-height: 279mm;
	padding: 0.4in 0.4in 0.5in 0.4in;
	background-color: #fff;
`

const HorizontalLayout = styled.div`
	width: 279mm;
	min-height: 215mm;
	padding: 0.3in 0.3in 0.3in 0.3in;
	background-color: #fff;
	tr, td {
		padding: 0px;
	}
	.headerTint1 th, .headerTint2 th, .headerTint3 th {
		height: 15px;
		border: 1px solid #888;
		text-align: center;
		font-weight: bold;
	}
	.headerTint1 th {
		background-color: #fbe4d5;
	}
	.headerTint2 th {
		background-color: #e2efd9;
	}
	.headerTint3 th {
		background-color: #deeaf6;
	}
`