import React from "react"
import {useSelector} from "react-redux"
import { Switch, Route, Redirect } from "react-router-dom"

import MainLayout from "./layouts/MainLayout"

import Authentication from './containers/Authentication/'
import Error404 from './containers/ErrorsPages/Error404'

import Services from './containers/Services'
import ServiceReviews from './containers/ServiceReviews'
import ServiceReports from './containers/ServiceReports'
import ServiceResults from './containers/ServiceResults'
import Certificates from './containers/Certificates'
import Companies from './containers/Companies'
import Employees from "./containers/Employees"
import Locations from './containers/Locations'
import Technicians from './containers/Technicians'
import Vehicles from './containers/Vehicles'
import Users from './containers/Users'
import Reports from "./containers/Reports"
import ItemTypes from "./containers/ItemTypes"
import PublicPreview from "./containers/PublicPreview"

export default function App() {	
	const token = useSelector(state => state.auth.token)
	
	return (
		<React.Fragment>			
			<Switch>
				<Redirect exact path="/" to="/services" />
				
				<Route path="/ver" component={PublicPreview} />

				<Route path="/auth" component={Authentication} />
				{!token && <Redirect to="/auth/login" />}

				<MainLayout>
					<Route path="/services" component={Services} />
					<Route path="/service_reviews" component={ServiceReviews} />
					<Route path="/service_reports" component={ServiceReports} />
					<Route path="/service_results" component={ServiceResults} />
					<Route path="/certificates" component={Certificates} />
					<Route path="/companies" component={Companies} />
					<Route path="/employees" component={Employees} />
					<Route path="/locations" component={Locations} />
					<Route path="/technicians" component={Technicians} />
					<Route path="/vehicles" component={Vehicles} />
					<Route path="/users" component={Users} />
					<Route path="/item_types" component={ItemTypes} />
					<Route path="/reports" component={Reports} />
					
					<Route path="/404" component={Error404} />
				</MainLayout>
			</Switch>
		</React.Fragment>
	);	
}