import React, { useState } from 'react'
import { Spinner, Button } from 'react-bootstrap'
import { Divider, Empty } from 'antd'

import ResultAttachmentCard from './partials/ResultAttachmentCard'
import NewResultAttachmentModal from './partials/NewResultAttachmentModal'

export default function ResultAttachmentsWidget({ serviceResult, reload }) {	
	const [showNewRecordModal, setShowNewRecordModal] = useState(false)

	if(!serviceResult) 
		return <Spinner animation='border' size="sm" />

	const resultAttachments = serviceResult.result_attachments

	return (
		<React.Fragment>
			{ resultAttachments.length > 0 ? (
				<React.Fragment>
					{ resultAttachments.map(item => (
						<ResultAttachmentCard 
							key={item.id}
							resultAttachment={item}
							reload={reload}
						/>
					)) }
				</React.Fragment>
			) : (
				<Empty 
					description={`No se tiene evidencia adjunta`}
					imageStyle={{ maxHeight: '75px' }}
				/>
			)}
			<Divider className='my-15' />
			<div className='text-center'>
				<Button 
					variant='outline-primary' 
					size="sm" 
					onClick={() => setShowNewRecordModal(true)}
				>
					Adjuntar Nueva Evidencia
				</Button>
			</div>
			{ showNewRecordModal && (
				<NewResultAttachmentModal 
					visible
					onClose={() => setShowNewRecordModal(false)}
					reload={reload}
					serviceResult={serviceResult}
				/>
			)}
		</React.Fragment>
	)
}