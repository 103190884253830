import ingealimentosApi, { getDomain } from '../../services/ingealimentosApi'

export const getReportDetails = params => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.get('/report_details', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getReportDetail = (id, params) => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.get(`/report_details/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeReportDetail = data => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.post('/report_details', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateReportDetail = (id, data) => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.put(`/report_details/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteReportDetail = id => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.delete(`/report_details/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchReportDetails = async (term, token) => (
	fetch( getDomain()+`/report_details?filter[name]=${term}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(item => ({
				label: item.name,
				value: item.id,
			})),
		)
)