import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, CardSubtitle, CardTitle } from 'reactstrap'
import { Divider } from 'antd'
import { Spinner } from 'react-bootstrap'
import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'

export default function CertificatesProfileTopbar({ certificate }) {
	const [loading, setLoading] = useState(false)

	if(!certificate) return null

	const printDocument = () => {
		setLoading(true)
		const input = document.getElementById('certificatePreviewContainer')
		html2canvas(input, { 
			useCORS: true,
			allowTaint: false,
			imageTimeout: 120000,
		})
		  	.then((canvas) => {
				const imgData = canvas.toDataURL('image/png')
				
				const pdf = new jsPDF('l', 'mm', 'letter', true)

				var position = 0
				var pageHeight = pdf.internal.pageSize.getHeight()
				var imgWidth = pdf.internal.pageSize.getWidth()
				var imgHeight = canvas.height * imgWidth / canvas.width
				var heightLeft = imgHeight
				
				pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
				heightLeft -= pageHeight;
				
				while (heightLeft >= 0) {
					position = heightLeft - imgHeight;
					pdf.addPage('letter ')
					pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
					heightLeft -= pageHeight
				}
			 	
			 	pdf.save(`Certificado #${certificate.sku}.pdf`)
		  })
		  .then(() => setLoading(false))
		;
	}

	return (
		<React.Fragment>
			<div className='float-right pt-5'>
				<Link to={`/ver/certificados/${certificate.id}`}>
					<Button color='primary' outline className='ml-7'>
						Imprimir
					</Button>
				</Link>
				<Divider type="vertical" />
				<Button 
					color='primary' 
					outline 
					onClick={printDocument}
					disabled={loading}
				>
					{ loading && <Spinner animation='border' size="sm" className='mr-1' /> }
					Exportar PDF
				</Button>
			</div>

			<CardTitle>
				Certificado #{certificate.sku}
			</CardTitle>
			<CardSubtitle>
				{certificate.service_report.service.location.company.legal_name} - {certificate.service_report.service.name}
			</CardSubtitle>
		</React.Fragment>
	)
}