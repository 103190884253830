import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import ServiceReportPreview from '../ServiceReports/ServiceReportPreview'
import CertificatePreview from '../Certificates/CertificatePreview'
import ServiceResultPreview from '../ServiceResults/ServiceResultPreview'

export default function PublicPreview() {
	return (
		<React.Fragment>
			<Switch>
				<div style={{ minHeight: '100vh', position:'relative', top: '50px', left: '5px' }}>
					<Route exact path="/ver/reportes/:id" component={ServiceReportPreview} />
					<Route exact path="/ver/certificados/:id" component={CertificatePreview} />
					<Route exact path="/ver/informes/:id" component={ServiceResultPreview} />
				</div>

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}