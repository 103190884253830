import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import ServiceReportDocument from './partials/ServiceReportDocument'

import { getPublicServiceReport } from './services'
import { handleError } from '../../helpers'

export default function ServiceReportPreview(props) {
	const serviceReportId = props.match.params.id
	const [serviceReport, setServiceReport] = useState(null)

	useEffect(() => {
		setTimeout(() => window.print(), 1500);
	}, [])

	useEffect(() => { 
		if(serviceReport && serviceReport.id !== parseInt(serviceReportId)) 
			setServiceReport(null) 
	}, [ serviceReport, serviceReportId ])

	useEffect(() => {
		!serviceReport && getPublicServiceReport(serviceReportId, {
			include: 'reportFormat,service.serviceFormat,service.location.company,serviceResult,certificate,service.serviceTechnicians.technician,service.serviceVehicles,service.serviceEquipments,service.serviceSecurityElements,service.serviceItems,service.serviceTreatmentAreas,service.serviceDeliveredDocuments,reportDetails,service.employee'
		})
			.then(res => setServiceReport(res.data.data))
			.catch(handleError)
	}, [ serviceReportId, serviceReport ])

	return (
		<React.Fragment>
			<PreviewContainer className='printFormatContainer'>
				<ServiceReportDocument serviceReport={serviceReport} />
			</PreviewContainer>
		</React.Fragment>
	)
}

const PreviewContainer = styled.div`
	background-color: #F8F9FA;
	padding: 30px 10px 10px;
	max-width: 100%;
	overflow-x: scroll;
`