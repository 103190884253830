import React, { useState, useEffect } from 'react'
import { Spinner } from 'react-bootstrap'
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap'

import ServiceProfileTopbar from './partials/ServiceProfileTopbar'
import ServiceDetailsWidget from './partials/ServiceDetailsWidget'
import ServiceTechniciansWidget from '../ServiceTechnicians/ServiceTechniciansWidget'
import ServiceVehiclesWidget from '../ServiceVehicles/ServiceVehiclesWidget'
import ServiceItemWidget from '../ServiceItems/ServiceItemWidget'
import ServiceEquipmentWidget from '../ServiceEquipments/ServiceEquipmentWidget'
import ServiceTreatmentAreaWidget from '../ServiceTreatmentAreas/ServiceTreatmentAreaWidget'
import ServiceSecurityElementWidget from '../ServiceSecurityElements/ServiceSecurityElementWidget'
import ServiceDeliveredDocumentWidget from '../ServiceDeliveredDocuments/ServiceDeliveredDocumentWidget'

import { getService } from './services'
import { handleError } from '../../helpers'

export default function ServiceProfile({ serviceId }) {
	const [service, setService] = useState(null)

	useEffect(() => { 
		if(service && service.id !== parseInt(serviceId)) 
			setService(null) 
	}, [ service, serviceId ])

	useEffect(() => {
		!service && getService(serviceId, {
			include: 'serviceFormat,location.company,serviceReport.certificate,serviceReview,user'
		})
			.then(res => setService(res.data.data))
			.catch(handleError)
	}, [ serviceId, service ])

	if(!service) return <Spinner animation='border' />

	return (
		<React.Fragment>
			<Card>
				<CardBody className='pb-0'>
					<ServiceProfileTopbar service={service} />
				</CardBody>
				<CardBody className='p-10'>
					<ServiceDetailsWidget service={service} />
				</CardBody>
			</Card>
			<Row>
				<Col lg="4" md="6" sm="12">
					{ service.service_format.has_technicians === 1 && (
						<Card>
							<CardHeader>Tecnicos Asociados</CardHeader>
							<CardBody>
								<ServiceTechniciansWidget serviceId={service.id} />
							</CardBody>
						</Card>
					)}
					{ service.service_format.has_vehicles === 1 && (
						<Card>
							<CardHeader>Vehículos Asignados</CardHeader>
							<CardBody>
								<ServiceVehiclesWidget serviceId={service.id} />
							</CardBody>
						</Card>
					)}
				</Col>
				<Col lg="4" md="6" sm="12">
					{ service.service_format.has_services_items === 1 && (
						<Card>
							<CardHeader>Servicios Utilizados</CardHeader>
							<CardBody>
								<ServiceItemWidget service={service} scope="service" />
							</CardBody>
						</Card>
					)}
					{ service.service_format.has_products_items === 1 && (
						<Card>
							<CardHeader>Productos Utilizados</CardHeader>
							<CardBody>
								<ServiceItemWidget service={service} scope="product" />
							</CardBody>
						</Card>
					)}
					{ service.service_format.has_equipments === 1 && (
						<Card>
							<CardHeader>Equipos Utilizados</CardHeader>
							<CardBody>
								<ServiceEquipmentWidget service={service} />
							</CardBody>
						</Card>
					)}
				</Col>
				<Col lg="4" md="6" sm="12">
					{ service.service_format.has_treatment_areas === 1 && (
						<Card>
							<CardHeader>Areas Tratadas</CardHeader>
							<CardBody>
								<ServiceTreatmentAreaWidget service={service} />
							</CardBody>
						</Card>
					)}
					{ service.service_format.has_security_elements === 1 && (
						<Card>
							<CardHeader>Elementos de Protección Personal</CardHeader>
							<CardBody>
								<ServiceSecurityElementWidget service={service} />
							</CardBody>
						</Card>
					)}
					{ service.service_format.has_delivered_documents === 1 && (
						<Card>
							<CardHeader>Documentos Entregados</CardHeader>
							<CardBody>
								<ServiceDeliveredDocumentWidget service={service} />
							</CardBody>
						</Card>
					)}
				</Col>
			</Row>
		</React.Fragment>
	)
}