import ingealimentosApi, { getDomain } from '../../services/ingealimentosApi'

export const getServiceTreatmentAreas = params => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.get('/service_treatment_areas', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getServiceTreatmentArea = (id, params) => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.get(`/service_treatment_areas/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeServiceTreatmentArea = data => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.post('/service_treatment_areas', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateServiceTreatmentArea = (id, data) => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.put(`/service_treatment_areas/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteServiceTreatmentArea = id => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.delete(`/service_treatment_areas/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchServiceTreatmentAreas = async (term, token) => (
	fetch( getDomain()+`/service_treatment_areas?filter[name]=${term}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(item => ({
				label: item.name,
				value: item.id,
			})),
		)
)