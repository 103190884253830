import React from 'react'
import { Form } from 'react-bootstrap'

export default function ReviewResultForm({ register, errors, editable, serviceReview, watch }) {

	return (
		<React.Fragment>
			<Form.Group className="form-group">
				<Form.Label>Tipo de Resultado <span className='text-danger'>*</span></Form.Label>
				<Form.Control 
					as="select"
					{...register('group', { required:true })}
				>
					<option value="">:: Seleccione una opción ::</option>
					<option value="fisica">Condiciones Físicas</option>
					<option value="cultural">Condiciones Culturales</option>
					<option value="periodicidad">Periodicidad de Ejecución de los Servicios</option>
				</Form.Control>
				{ errors.group && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
			{ watch('group') && (
				<React.Fragment>
					<Form.Group className='form-group'>
						<Form.Label>Área <span className='text-danger'>*</span></Form.Label>
						<Form.Control 
							{...register('area', { required:true })}
							placeholder="Escriba aquí..."
						/>
						{ errors.area && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
					</Form.Group>
					{ watch('group') !== 'periodicidad' && (
						<React.Fragment>
								<Form.Group className='form-group'>
									<Form.Label>Descripción de Condición <span className='text-danger'>*</span></Form.Label>
									<Form.Control 
										as="textarea"
										{...register('condition_desc', { required:true })}
										placeholder="Escriba aquí..."
										style={{ height: '60px' }}
									/>
									{ errors.condition_desc && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
								</Form.Group>
						</React.Fragment>
					)}
					<Form.Group className='form-group'>
						<Form.Label>Actividades a realizar <span className='text-danger'>*</span></Form.Label>
						<Form.Control 
							as="textarea"
							{...register('activity', { required:true })}
							placeholder="Escriba aquí..."
							style={{ height: '60px' }}
						/>
						{ errors.activity && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
					</Form.Group>
					<Form.Group className='form-group'>
						<Form.Label>Descripción ¿Cómo? <span className='text-danger'>*</span></Form.Label>
						<Form.Control 
							as="textarea"
							{...register('description', { required:true })}
							placeholder="Escriba aquí..."
							style={{ height: '60px' }}
						/>
						{ errors.description && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
					</Form.Group>
					<Form.Group className='form-group'>
						<Form.Label>Responsable <span className='text-danger'>*</span></Form.Label>
						<Form.Control 
							{...register('responsable', { required:true })}
							placeholder="Escriba aquí..."
						/>
						{ errors.responsable && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
					</Form.Group>
					<Form.Group className='form-group'>
						<Form.Label>Elementos requeridos para el control <span className='text-danger'>*</span></Form.Label>
						<Form.Control 
							{...register('elements', { required:true })}
							placeholder="Escriba aquí..."
						/>
						{ errors.elements && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
					</Form.Group>
				</React.Fragment>
			)}
		</React.Fragment>
	)
}