import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Form, Spinner } from 'react-bootstrap'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { message } from 'antd'

import ItemTypeForm from './ItemTypeForm'

import { storeItemType } from '../services'
import { handleError } from '../../../helpers'

export default function NewItemTypeModal({ visible, onClose, reload }) {
    const { handleSubmit, register, formState: { errors } } = useForm({})
    const [loading, setLoading] = useState(false)

    const onSubmit = values => {
        setLoading(true)
        storeItemType(values)
            .then(res => {
                message.success(res.data.message)
                reload()
                onClose()
            })
            .catch(error => handleError(error, setLoading))
    }

    return (
        <Modal isOpen={visible} toggle={onClose}>
            <ModalHeader toggle={onClose}>Crear Producto/Servicio Nuevo</ModalHeader>
            <ModalBody>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <ItemTypeForm 
                        register={register}
                        errors={errors}
                    />
                    <Button color='primary' type="submit" disabled={loading}>
                        { loading && <Spinner animation='border' size="sm" className='mr-1' /> }
                        Agregar Registro
                    </Button>
                </Form>
            </ModalBody>
        </Modal>
    )
}