import React from 'react'
import styled from 'styled-components'
import { Spinner } from 'react-bootstrap'
import 'moment/locale/es'

import ResultDocHeader from './document/ResultDocHeader'
import { Col, Row } from 'reactstrap'

export default function ServiceResultDocument({ serviceResult }) {
	
	if(!serviceResult) return <Spinner animation='border' />

	return (
		<React.Fragment>
			<Document>
				<div id="reportPreviewContainer">
					<table>
						<thead><tr><th /><th /><th /><th /></tr></thead>
						<tbody>
							<ResultDocHeader serviceResult={serviceResult} />
						</tbody>
					</table>
					<table>
						<thead><tr><th /><th /></tr></thead>
						<tbody>
							<tr><td colSpan={2} className="hr"></td></tr>
							<tr>
								<td colSpan={2}>
									<strong>FECHA: </strong>{serviceResult.service_report.service.service_at}
								</td>
							</tr>
							<tr>
								<td colSpan={2}>
									<strong>EMPRESA: </strong>{serviceResult.service_report.service.location.company.legal_name} ({serviceResult.service_report.service.location.name})
								</td>
							</tr>
							<tr>
								<td colSpan={2}>
									<strong>SERVICIO: </strong>{serviceResult.service_report.service.name}
								</td>
							</tr>
							<tr>
								<td colSpan={2}>
									<strong>TÉCNICOS QUE REALIZARON EL SERVICIO: </strong>{serviceResult.service_report.service.service_technicians.map(r => r.technician.name).join(' - ')}
								</td>
							</tr>
							<tr>
								<td colSpan={2}>
									<strong>RECEPCIÓN DEL SERVICIO: </strong>
									{serviceResult.service_report.client_signed_name}
								</td>
							</tr>
							<tr><td colSpan={2} className="hr"></td></tr>
							<tr>
								<td colSpan={1} className='bold'>
									{ serviceResult.service_report.service.service_format.slug !== 'control_plagas' ? 'ACTIVIDAD' : 'CONDICIÓN' } 
								</td>
								<td colSpan={1} className='bold'>REGISTRO FOTOGRÁFICO</td>
							</tr>
							<tr>
								<td colSpan={1} className='pr-20 pt-20' width="370px">
									{ serviceResult.result_activities.length > 0 && (
										<ol>
											{ serviceResult.result_activities.map( resultActivity =>  (
												<li key={resultActivity.id} className="mb-10">
													{resultActivity.description}
												</li>
											)) }
										</ol>
									)}
								</td>
								<td colSpan={1} className='p-10'>
									{ serviceResult.result_attachments.length > 0 && (
										<Row>
											{ serviceResult.result_attachments.map(resultAttachment => (
												<Col xs={6} key={resultAttachment.id} className="p-10 text-center">
													<img src={`${resultAttachment.url}?v=${new Date().getTime()}`} alt="Evidencia" width="160px" crossOrigin='anonymous' />
												</Col>
											)) }
										</Row>
									)}
								</td>
							</tr>
							<tr><td colSpan={2} className="hr"></td></tr>
							<tr>
								<td colSpan={2}>
									<strong>RECOMENDACIONES: </strong>{serviceResult.final_recomendations}
								</td>
							</tr>
							<tr>
								<td colSpan={2}>
									<strong>REGISTRO: </strong>REPORTE DE SERVICIO {serviceResult.service_report.sku}, CERTIFICADO, EVIDENCIA FOTOGRAFICA.
								</td>
							</tr>
							<tr>
								<td colSpan={2}>
									<strong>NOTA: </strong>{serviceResult.note}
								</td>
							</tr>
							<tr>
								<td colSpan={2}>
									<strong>ELABORADO POR: </strong>{serviceResult.elaborate_by}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</Document>
		</React.Fragment>
	)
}

const Document = styled.div`
	width: 216mm;
	min-height: 279mm;
	padding: 0.4in 0.4in 0.5in 0.4in;
	background-color: #fff;
	font-family: "Arial Narrow", Arial, sans-serif;
	h1 {
		font-size: 16px;
		margin-bottom: 0px;
		line-height: 16px;
	}
	h2 {
		font-size: 16px;
	}
	h5 {
		font-size: 14px;
		margin-bottom: 0px;
	}
	p {
		font-size: 14px;
		margin-bottom: 0px;
	}
	p.small {
		font-size: 13px;
	}
	p.xs {
		font-size: 12px;
	}
	td, th {
		font-size: 14px;
		height: 15px;
	}
	.sm-width {
		max-width: 550px;
		margin: auto;
	}
	.border {
		border: 1px solid #919191!important;
  	}
  	.logoDiv {
		background-color: white;
		border: 4px solid white;
		position: relative;
		top: -15px;
  	}
	.titleDiv {
		margin: auto;
		text-align: center;
	}
	.detailsDiv {
		border: 1px solid #888;
		border-radius: 10px;
		padding: 7px 10px;
		margin-bottom: 5px;
	}
	table td {
		border: 1px solid #888;
		padding-left: 10px;
		padding-right: 10px;
		vertical-align: top;
	}
	tr td.hr {
		background-color: #d9e2f3;
		height: 5px !important;
	}
	table {
		width: 100%;
	}
`