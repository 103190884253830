import React from 'react'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { message, Popconfirm, Tooltip } from 'antd'

import ListItemCard from '../../../components/ListItemCard'

import { deleteServiceTreatmentArea } from '../services'
import { handleError } from '../../../helpers'

export default function ServiceTreatmentAreaCard({ serviceTreatmentArea, reload }) {

	const handleDelete = () => {
		deleteServiceTreatmentArea(serviceTreatmentArea.id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(handleError)
	}

	return (
		<React.Fragment>
			<ListItemCard>
				<div className='float-right'>
					<Tooltip title="Eliminar" placement='bottom'>
						<Popconfirm
							title="Esta acción no se puede revertir"
							okText="Eliminar"
							cancelText="Cancelar"
							okButtonProps={{ danger:true }}
							onConfirm={handleDelete}
						>
							<FontAwesomeIcon className='text-link' icon={faTrash} />
						</Popconfirm>
					</Tooltip>
				</div>
				<p className='bold mb-3'>{serviceTreatmentArea.treatment_area_type.name}</p>
				<p className='mb-0 text-muted small'>
					Registro creado el {serviceTreatmentArea.created_at}
				</p>
			</ListItemCard>
		</React.Fragment>
	)
}