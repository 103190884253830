import React, { useState, useEffect } from 'react'
import { Card, CardBody } from 'reactstrap'

import ListTopBar from '../../components/ListTopBar'

import UsersTable from './partials/UsersTable'
import NewUserModal from './partials/NewUserModal'

import { handleError, parsePagination } from '../../helpers'
import { getUsers } from './services'

export default function UsersList() {
	const [users, setUsers] = useState(null)
	const [filterType, setFilterType] = useState('name')
	const [filterValue, setFilterValue] = useState('')
	const [filterScope, setFilterScope] = useState('active')
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})
	const [showNewRecordModal, setShowNewRecordModal] = useState(false)

	useEffect(() => {
		!users && getUsers({
			...pagination,
			[`filter[${filterType}]`]: filterValue,
			[filterScope ? `filter[${filterScope}]` : 'noscope']: 1,
			include: '',
			sort: '-name'
		})
			.then(res => {
				setUsers(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [ users, pagination, filterType, filterValue, filterScope ])

	return (
		<React.Fragment>
			<ListTopBar 
				title="Usuarios Administradores"
				subtitle="Listado de usuarios con acceso al administrador"
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				filterScope={filterScope}
				setFilterScope={setFilterScope}
				reload={() => setUsers(null)}
				options={[
					{ label: 'Buscar por nombre', value: 'name' },
					{ label: 'Buscar por cédula', value: 'document' },
					{ label: 'Buscar por correo electrónico', value: 'email' },
				]}
				scopes={[
					{ label: 'Usuarios Activos', value: 'active' },
					{ label: 'Usuarios Inactivos', value: 'inactive' },
				]}
				ctaText="Agregar Registro"
				handleCtaClick={() => setShowNewRecordModal(true)}
			/>
			<Card>
				<CardBody>
					<UsersTable 
						users={users}
						reload={() => setUsers(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
			{ showNewRecordModal && (
				<NewUserModal 
					visible
					onClose={() => setShowNewRecordModal(false)}
					reload={() => setUsers(null)}
				/>
			)}
		</React.Fragment>
	)
}