import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import TechniciansList from './TechniciansList'

export default function Technicians() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/technicians" component={TechniciansList} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}