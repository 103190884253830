import ingealimentosApi, { getDomain } from '../../services/ingealimentosApi'

export const getItemTypes = params => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.get('/item_types', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getItemType = (id, params) => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.get(`/item_types/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeItemType = data => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.post('/item_types', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateItemType = (id, data) => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.put(`/item_types/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteItemType = id => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.delete(`/item_types/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchItemTypes = async (term, token, scopeId) => (
	fetch( getDomain()+`/item_types?filter[name]=${term}&filter[company_id]=${scopeId}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(item => ({
				label: item.name,
				value: item.id,
			})),
		)
)