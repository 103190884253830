import React, { useEffect, useState } from 'react'
import { Spinner, Button } from 'react-bootstrap'
import { Divider, Empty } from 'antd'

import ReportDetailCard from './partials/ReportDetailCard'
import NewReportDetailModal from './partials/NewReportDetailModal'

import { getReportDetails } from './services'
import { handleError } from '../../helpers'

export default function ReportDetailsWidget({ serviceReport }) {
	const [reportDetails, setReportDetails] = useState(null)
	const [showNewRecordModal, setShowNewRecordModal] = useState(false)

	useEffect(() => {
		!reportDetails && serviceReport && getReportDetails({
			'filter[service_report_id]': serviceReport.id,
			include: 'detailType'
		})
			.then(res => setReportDetails(res.data.data))
			.catch(handleError)
	}, [ reportDetails, serviceReport ])

	if(!reportDetails) 
		return <Spinner animation='border' size="sm" />

	return (
		<React.Fragment>
			{ reportDetails.length > 0 ? (
				<React.Fragment>
					{ reportDetails.map(item => (
						<ReportDetailCard 
							key={item.id}
							reportDetail={item}
							reload={() => setReportDetails(null)}
						/>
					)) }
				</React.Fragment>
			) : (
				<Empty 
					description={`No se tienen resultados registrados`}
					imageStyle={{ maxHeight: '75px' }}
				/>
			)}
			<Divider className='my-15' />
			<div className='text-center'>
				<Button 
					variant='outline-primary' 
					size="sm" 
					onClick={() => setShowNewRecordModal(true)}
				>
					Registrar Resultado
				</Button>
			</div>
			{ showNewRecordModal && (
				<NewReportDetailModal 
					visible
					onClose={() => setShowNewRecordModal(false)}
					reload={() => setReportDetails(null)}
					serviceReport={serviceReport}
				/>
			)}
		</React.Fragment>
	)
}