import ingealimentosApi, { getDomain } from '../../services/ingealimentosApi'

export const getTechnicians = params => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.get('/technicians', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getTechnician = (id, params) => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.get(`/technicians/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeTechnician = data => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.post('/technicians', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateTechnician = (id, data) => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.put(`/technicians/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteTechnician = id => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.delete(`/technicians/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchTechnicians = async (term, token) => (
	fetch( getDomain()+`/technicians?filter[name]=${term}&filter[active]=1`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(item => ({
				label: item.name,
				value: item.id,
			})),
		)
)