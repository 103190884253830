import React, { useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'

import ServerSideSelect from '../../../components/ServerSideSelect'
import { searchCompanies } from '../../Companies/services'

export default function EmployeeForm({ register, errors, setValue, editable, company }) {
	const [selectedCompany, setSelectedCompany] = useState([])

	return (
		<React.Fragment>
			{ !editable && !company && (
				<Form.Group className='form-group'>
					<Form.Label>Empresa Asociada <span className='text-danger'>*</span></Form.Label>
					<ServerSideSelect 
						value={selectedCompany}
						placeholder="Buscar por nombre de empresa"
						fetchOptions={searchCompanies}
						onChange={record => {
							setSelectedCompany(record)
							setValue('company_id', record.value)
						}}
						className="full-width"
					/>
					<Form.Control {...register('company_id', { required: true })} style={{ display:'none' }} />
					{ errors.company_id && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
			)}
			<Form.Group className='form-group'>
				<Form.Label>Nombre del Contacto <span className='text-danger'>*</span></Form.Label>
				<Form.Control 
					{...register('name', { required: true })}
					placeholder="Escriba aquí..."
				/>
				{ errors.name && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
			<Row>
				<Form.Group as={Col} className='form-group'>
					<Form.Label>Correo Electrónico <span className='text-danger'>*</span></Form.Label>
					<Form.Control 
						{...register('email', { required: true })}
						placeholder="Escriba aquí..."
					/>
					{ errors.email && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
				<Form.Group as={Col} className='form-group'>
					<Form.Label>Cargo <span className='text-danger'>*</span></Form.Label>
					<Form.Control 
						{...register('position', { required: true })}
						placeholder="Escriba aquí..."
					/>
					{ errors.position && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
			</Row>
			<Row>
				<Form.Group className='form-group' as={Col}>
					<Form.Label>Celular <span className='text-danger'>*</span></Form.Label>
					<Form.Control 
						type="number"
						{...register('mobile', { required: true })}
						placeholder="Escriba aquí..."
					/>
					{ errors.mobile && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
				<Form.Group className='form-group' as={Col}>
					<Form.Label>Teléfono 1 <small>(opcional)</small></Form.Label>
					<Form.Control 
						type="number"
						{...register('phone1', {})}
						placeholder="Escriba aquí..."
					/>
				</Form.Group>
			</Row>
			<Row>
				<Form.Group as={Col} className='form-group'>
					<Form.Label>Teléfono 2 <small>(opcional)</small></Form.Label>
					<Form.Control 
						type="number"
						{...register('phone2', {})}
						placeholder="Escriba aquí..."
					/>
				</Form.Group>
				<Form.Group as={Col} className='form-group'>
					<Form.Label>Teléfono 3 <small>(opcional)</small></Form.Label>
					<Form.Control 
						type="number"
						{...register('phone3', {})}
						placeholder="Escriba aquí..."
					/>
				</Form.Group>
			</Row>
		</React.Fragment>
	)
}