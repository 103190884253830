import React, { useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'

import ServerSideSelect from '../../../components/ServerSideSelect'
import { searchCompanies } from '../../Companies/services'

export default function LocationForm({ register, errors, setValue, editable, company }) {
	const [selectedCompany, setSelectedCompany] = useState([])

	return (
		<React.Fragment>
			{ !editable && !company && (
				<Form.Group className='form-group'>
					<Form.Label>Empresa Asociada <span className='text-danger'>*</span></Form.Label>
					<ServerSideSelect 
						value={selectedCompany}
						placeholder="Buscar por nombre de empresa"
						fetchOptions={searchCompanies}
						onChange={record => {
							setSelectedCompany(record)
							setValue('company_id', record.value)
						}}
						className="full-width"
					/>
					<Form.Control {...register('company_id', { required: true })} style={{ display:'none' }} />
					{ errors.company_id && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
			)}
			<Form.Group className='form-group'>
				<Form.Label>Nombre de la Sede <span className='text-danger'>*</span></Form.Label>
				<Form.Control 
					{...register('name', { required: true })}
					placeholder="Escriba aquí..."
				/>
				{ errors.name && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
			<Form.Group className='form-group'>
				<Form.Label>Dirección <span className='text-danger'>*</span></Form.Label>
				<Form.Control 
					{...register('address', { required: true })}
					placeholder="Escriba aquí..."
				/>
				{ errors.address && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
			<Form.Group className='form-group'>
				<Form.Label>Complemento de Dirección <small>(opcional)</small></Form.Label>
				<Form.Control 
					{...register('address_extra', {})}
					placeholder="Escriba aquí..."
				/>
			</Form.Group>
			<Row>
				<Form.Group className='form-group' as={Col}>
					<Form.Label>Ciudad <span className='text-danger'>*</span></Form.Label>
					<Form.Control 
						{...register('city', { required: true })}
						placeholder="Escriba aquí..."
					/>
					{ errors.city && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
				<Form.Group className='form-group' as={Col}>
					<Form.Label>Departamento <span className='text-danger'>*</span></Form.Label>
					<Form.Control 
						{...register('department', { required: true })}
						placeholder="Escriba aquí..."
					/>
					{ errors.department && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
			</Row>
			<Form.Group className='form-group'>
				<Form.Label>SAP Código de Tienda <small>(opcional)</small></Form.Label>
				<Form.Control 
					{...register('sap_sku', {})}
					placeholder="Escriba aquí..."
				/>
			</Form.Group>
		</React.Fragment>
	)
}