import React, { useState } from 'react'
import { Col, Form, InputGroup, Row } from 'react-bootstrap'

import ServerSideSelect from '../../../components/ServerSideSelect'
import NewVehicleModal from '../../Vehicles/partials/NewVehicleModal'
import { searchVehicles } from '../../Vehicles/services'

export default function ServiceVehicleForm({ register, errors, setValue, editable }) {
	const [selectedVehicle, setSelectedVehicle] = useState([])
	const [showNewVehicleModal, setShowNewVehicleModal] = useState(false)

	return (
		<React.Fragment>
			{ !editable && (
				<Form.Group className='form-group'>
					<Form.Label>Placas del Vehículo <span className='text-danger'>*</span></Form.Label>
					<ServerSideSelect 
						value={selectedVehicle}
						placeholder="Buscar por placas del vehículo"
						fetchOptions={searchVehicles}
						onChange={record => {
							setSelectedVehicle(record)
							setValue('vehicle_id', record.value)
						}}
						className="full-width"
					/>
					<Form.Text className='text-link' onClick={() => setShowNewVehicleModal(true)}>Crear nuevo vehículo</Form.Text>
					<Form.Control {...register('vehicle_id', { required: true })} style={{ display:'none' }} />
					{ errors.vehicle_id && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
			)}
			<Form.Group className='form-group'>
				<Form.Label>Tiempo Planeado <small>(opcional)</small></Form.Label>
				<InputGroup>
					<Form.Control 
						{...register('minutes_planned', {})}
						placeholder="Escriba aquí..."
						type="number"
					/>
					<InputGroup.Text>minutos</InputGroup.Text>
				</InputGroup>
			</Form.Group>
			<Row>
				<Form.Group as={Col} className='form-group'>
					<Form.Label>Tiempo Usado <small>(opcional)</small></Form.Label>
					<InputGroup>
						<Form.Control 
							type="number"
							{...register('minutes_used', {})}
							placeholder="0"
						/>
						<InputGroup.Text>minutos</InputGroup.Text>
					</InputGroup>
				</Form.Group>
				<Form.Group as={Col} className='form-group'>
					<Form.Label>Kilometros Usados <small>(opcional)</small></Form.Label>
					<InputGroup>
						<Form.Control 
							type="number"
							{...register('kilometers_used', {})}
						/>
						<InputGroup.Text>km</InputGroup.Text>
					</InputGroup>
				</Form.Group>
			</Row>
			{ showNewVehicleModal && (
				<NewVehicleModal 
					visible
					onClose={() => setShowNewVehicleModal(false)}
					reload={() => setSelectedVehicle([])}
				/>
			)}
		</React.Fragment>
	)
}