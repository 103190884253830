import React, { useState } from 'react'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Divider, message, Popconfirm, Tooltip } from 'antd'

import { deleteResultAttachment } from '../services'
import { handleError } from '../../../helpers'
import EditResultAttachmentModal from './EditResultAttachmentModal'

export default function ResultAttachmentCard({ resultAttachment, reload }) {
	const [showEditModal, setShowEditModal] = useState(false)

	const handleDelete = () => {
		deleteResultAttachment(resultAttachment.id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(handleError)
	}

	return (
		<React.Fragment>
			<div className='inline-block p-10 bg-light ml-4 mr-3 mb-7'>
				<p className='bold mb-5 small'>Evidencia ID-0{resultAttachment.id}</p>
				
				<img src={resultAttachment.url} width="90px" alt="Adjunto" />

				<div className='mt-10'>
					<Tooltip title="Editar" onClick={() => setShowEditModal(true)}>
						<FontAwesomeIcon className='text-link' icon={faEdit} />
					</Tooltip>
					<Divider type="vertical" />
					<Tooltip title="Eliminar" placement='bottom'>
						<Popconfirm
							title="Esta acción no se puede revertir"
							okText="Eliminar"
							cancelText="Cancelar"
							okButtonProps={{ danger:true }}
							onConfirm={handleDelete}
						>
							<FontAwesomeIcon className='text-link' icon={faTrash} />
						</Popconfirm>
					</Tooltip>
					<Divider type="vertical" />
					<span className='small'>Pos. #{resultAttachment.order}</span>
				</div>				
			</div>
			{ showEditModal && (
				<EditResultAttachmentModal 
					visible
					onClose={() => setShowEditModal(false)}
					resultAttachment={resultAttachment}
					reload={reload}
				/>
			)}
		</React.Fragment>
	)
}