import React, { useEffect, useState } from 'react'
import { Spinner, Button } from 'react-bootstrap'
import { Divider, Empty } from 'antd'

import ServiceTechnicianCard from './partials/ServiceTechnicianCard'
import NewServiceTechnicianModal from './partials/NewServiceTechnicianModal'

import { getServiceTechnicians } from './services'
import { handleError } from '../../helpers'

export default function ServiceTechniciansWidget({ serviceId }) {
	const [serviceTechnicians, setServiceTechnicians] = useState(null)
	const [showNewRecordModal, setShowNewRecordModal] = useState(false)

	useEffect(() => {
		!serviceTechnicians && getServiceTechnicians({
			'filter[service_id]': serviceId,
			include: 'technician'
		})
			.then(res => setServiceTechnicians(res.data.data))
			.catch(handleError)
	}, [ serviceTechnicians, serviceId ])

	if(!serviceTechnicians) 
		return <Spinner animation='border' size="sm" />

	return (
		<React.Fragment>
			{ serviceTechnicians.length > 0 ? (
				<React.Fragment>
					{ serviceTechnicians.map(technician => (
						<ServiceTechnicianCard 
							key={technician.id}
							serviceTechnician={technician}
							reload={() => setServiceTechnicians(null)}
						/>
					)) }
				</React.Fragment>
			) : (
				<Empty 
					description="No tiene técnicos asociados"
					imageStyle={{ maxHeight: '75px' }}
				/>
			)}
			<Divider className='my-15' />
			<div className='text-center'>
				<Button 
					variant='outline-primary' 
					size="sm" 
					onClick={() => setShowNewRecordModal(true)}
				>
					Asociar Técnico al Servicio
				</Button>
			</div>
			{ showNewRecordModal && (
				<NewServiceTechnicianModal 
					visible
					onClose={() => setShowNewRecordModal(false)}
					reload={() => setServiceTechnicians(null)}
					serviceId={serviceId}
				/>
			)}
		</React.Fragment>
	)
}