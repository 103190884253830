import React from 'react'
import { Form } from 'react-bootstrap'

export default function ServiceResultForm({ register, errors, editable, serviceResult }) {
	return (
		<React.Fragment>
			<Form.Group className='form-group'>
				<Form.Label>Elaborado por <span className='text-danger'>*</span></Form.Label>
				<Form.Control 
					{...register('elaborate_by', { required:true })}
					placeholder="Escriba aquí..."
				/>
				{ errors.elaborate_by && <Form.Text className='text-danger'>Este campo es requerido</Form.Text> }
			</Form.Group>
			<Form.Group className='form-group'>
				<Form.Label>Nota / Desc. Falla <small>(opcional)</small></Form.Label>
				<Form.Control 
					as="textarea"
					{...register('note', {})}
					placeholder="Escriba aquí..."
					style={{ height: '100px' }}
				/>
			</Form.Group>
			{ serviceResult && !serviceResult.result_format.code.includes('JM') ? (
				<React.Fragment>
					<Form.Group className='form-group'>
						<Form.Label>Recomendaciones <small>(opcional)</small></Form.Label>
						<Form.Control 
							as="textarea"
							{...register('final_recomendations', {})}
							placeholder="Escriba aquí..."
							style={{ height: '100px' }}
						/>
					</Form.Group>
				</React.Fragment>
			) : (
				<React.Fragment>
					<Form.Group className='form-group'>
						<Form.Label>Número de Ticket <small>(opcional)</small></Form.Label>
						<Form.Control 
							{...register('external_sku', {})}
							placeholder="Escriba aquí..."
						/>
					</Form.Group>
				</React.Fragment>
			)}
			{ !editable && (
				<Form.Group className='form-group'>
					<Form.Label>Actividades por defecto <span className='text-primary'>*</span></Form.Label>
					<Form.Control
						as="select"
						{...register('default_activities_group', { required:true })}
					>
						<option value="ninguna">:: Sin actividades por defecto ::</option>
						<option value="limpieza_tanques">Actividads para Limpieza de Tanques</option>
						<option value="trampa_grasa">Actividades para Trampas de Grasa</option>
						<option value="sondeo_tuberia">Actividades para Sondeo de Tuberias</option>
					</Form.Control>
					{ errors.default_activities_group && <Form.Text className='text-danger'>Este campo es requerido</Form.Text> }
				</Form.Group>
			)}
		</React.Fragment>
	)
}