import React, { useState } from 'react'
import { Descriptions, Divider } from 'antd'
import { Spinner } from 'react-bootstrap'
import { Button } from 'reactstrap'

import EditServiceResultModal from '../EditServiceResultModal'

const { Item } = Descriptions

export default function ResultInfoWidget({ serviceResult, reload }) {
	const [showEditModal, setShowEditModal] = useState(false)

	if(!serviceResult) 
		return <Spinner animation='border' size="sm" />

	return (
		<React.Fragment>
			<Descriptions column={1}>
				<Item label="Elaborado por">{serviceResult.elaborate_by}</Item>
			</Descriptions>
			{ serviceResult && !serviceResult.result_format.code.includes('JM') ? (
				<React.Fragment>
					<p className='item-label'>Recomendaciones:</p>
					<p>{serviceResult.final_recomendations ? serviceResult.final_recomendations : <em>sin registro</em>}</p>
					<p className='item-label'>Nota:</p>
					<p>{serviceResult.note ? serviceResult.note : <em>sin registro</em>}</p>
				</React.Fragment>
			) : (
				<React.Fragment>
					<p className='item-label'>Descripción de la falla:</p>
					<p>{serviceResult.note ? serviceResult.note : <em>sin registro</em>}</p>
					<p className='item-label'>Número de Ticket:</p>
					<p>{serviceResult.external_sku ? serviceResult.external_sku : <em>sin registro</em>}</p>
				</React.Fragment>
			)}
			<Divider className='my-15' />
			<div className='text-center'>
				<Button
					color='primary'
					outline
					size="sm" 
					onClick={() => setShowEditModal(true)}
				>
					Actualizar Detalles del Informe
				</Button>
			</div>
			{ showEditModal && (
				<EditServiceResultModal 
					visible
					onClose={() => setShowEditModal(false)}
					serviceResult={serviceResult}
					reload={reload}
					editable
				/>
			)}
		</React.Fragment>
	)
}