import ingealimentosApi, { getDomain } from '../../services/ingealimentosApi'

export const getServiceDeliveredDocuments = params => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.get('/service_delivered_documents', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getServiceDeliveredDocument = (id, params) => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.get(`/service_delivered_documents/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeServiceDeliveredDocument = data => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.post('/service_delivered_documents', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateServiceDeliveredDocument = (id, data) => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.put(`/service_delivered_documents/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteServiceDeliveredDocument = id => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.delete(`/service_delivered_documents/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchServiceDeliveredDocuments = async (term, token) => (
	fetch( getDomain()+`/service_delivered_documents?filter[name]=${term}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(item => ({
				label: item.name,
				value: item.id,
			})),
		)
)