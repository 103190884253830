import React, { useState, useEffect } from 'react'
import { Card, CardBody } from 'reactstrap'

import ListTopBar from '../../components/ListTopBar'

import ServiceResultsTable from './partials/ServiceResultsTable'

import { getServiceResults } from './services'
import { handleError, parsePagination } from '../../helpers'

export default function ServiceResultsList() {
	const [serviceResults, setServiceResults] = useState(null)
	const [filterType, setFilterType] = useState('id')
	const [filterValue, setFilterValue] = useState('')
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})

	useEffect(() => {
		!serviceResults && getServiceResults({
			...pagination,
			[`filter[${filterType}]`]: filterValue,
			include: 'serviceReport.service.location.company'
		})
			.then(res => {
				setServiceResults(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [ serviceResults, pagination, filterType, filterValue ])

	return (
		<React.Fragment>
			<ListTopBar 
				title="Informes de Servicios"
				subtitle="Listado de informes de servicio registrados en la plataforma"
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				reload={() => setServiceResults(null)}
				options={[
					{ label: 'Buscar por id del informe', value: 'id' },
					{ label: 'Buscar por razón social', value: 'serviceReport.service.location.company.legal_name' },
					{ label: 'Buscar por NIT de cliente', value: 'serviceReport.service.location.company.document' },
					{ label: 'Buscar nombre del servicio', value: 'serviceReport.service.serviceFormat.name' },
					{ label: 'Buscar fecha del servicio (YYYY-MM-DD)', value: 'serviceReport.service.service_at' },
					{ label: 'Buscar por nombre del técnico asignado', value: 'serviceReport.service.serviceTechnicians.technician.name' },
					{ label: 'Buscar por cedula del técnico asignado', value: 'serviceReport.service.serviceTechnicians.technician.document' },
					{ label: 'Buscar por placas de vehículo asociado', value: 'serviceReport.service.serviceVehicles.vehicle.placas' },
					{ label: 'Buscar por asesor asignado', value: 'serviceReport.service.user.name' },
				]}
			/>
			<Card>
				<CardBody>
					<ServiceResultsTable 
						serviceResults={serviceResults}
						reload={() => setServiceResults(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
		</React.Fragment>
	)
}