import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Form, Spinner } from 'react-bootstrap'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { message } from 'antd'

import ServiceForm from './ServiceForm'

import { updateService } from '../services'
import { handleError } from '../../../helpers'
import moment from 'moment/moment'

export default function EditServiceModal({ visible, onClose, service, reload }) {
	const { handleSubmit, register, formState: { errors }, watch } = useForm({ 
		defaultValues: {
			...service,
			service_at_day: moment(service.service_at).format('YYYY-MM-DD'),
			service_at_time: moment(service.service_at).format('HH:mm'),
		}
	})
	const [loading, setLoading] = useState(false)

	const onSubmit = values => {
		setLoading(true)
		if(values.sku === service.sku) delete values.sku
		delete values.charged_at
		delete values.invoiced_at
		values.service_at = moment(`${values.service_at_day} ${values.service_at_time}:00`).format('YYYY-MM-DD HH:mm:ss')
		updateService(service.id, values)
			.then(res => {
				message.success(res.data.message)
				reload()
				onClose()
			})
			.catch(error => handleError(error, setLoading))
	}

	return (
		<Modal isOpen={visible} toggle={onClose}>
			<ModalHeader toggle={onClose}>Actualizar Servicio</ModalHeader>
			<ModalBody>
				<Form onSubmit={handleSubmit(onSubmit)}>
					<ServiceForm 
						register={register}
						errors={errors}
						watch={watch}
						editable
					/>
					<Button color='primary' type="submit" disabled={loading}>
						{ loading && <Spinner animation="border" size="sm" className='mr-1' /> }
						Actualizar Servicio
					</Button>
				</Form>
			</ModalBody>
		</Modal>
	)
}