import React, { useState, useEffect } from 'react'
import { Card, CardBody } from 'reactstrap'

import ListTopBar from '../../components/ListTopBar'

import LocationsTable from './partials/LocationsTable'
import NewLocationModal from './partials/NewLocationModal'

import { handleError, parsePagination } from '../../helpers'
import { getLocations } from './services'

export default function LocationsList({ company, employee, hideTopbar }) {
	const [locations, setLocations] = useState(null)
	const [filterType, setFilterType] = useState('name')
	const [filterValue, setFilterValue] = useState('')
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})
	const [showNewRecordModal, setShowNewRecordModal] = useState(false)

	useEffect(() => {
		!locations && getLocations({
			...pagination,
			[`filter[${filterType}]`]: filterValue,
			'filter[company_id]': company ? company.id : '',
			'filter[employees.id]': employee ? employee.id : '',
			include: 'company'
		})
			.then(res => {
				setLocations(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [ locations, pagination, filterType, filterValue, company, employee ])

	return (
		<React.Fragment>
			<ListTopBar 
				title={!company ? "Directorio de sedes" : `Sedes ${company.legal_name}`}
				subtitle={!company ? "Sedes asociadas a clientes de Ingealimentos y Andina de Servicios" : ''}
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				reload={() => setLocations(null)}
				options={[
					{ label: 'Buscar por nombre', value: 'name' },
					{ label: 'Buscar por ciudad', value: 'city' },
				]}
				ctaText="Agregar Registro"
				handleCtaClick={() => setShowNewRecordModal(true)}
				hide={hideTopbar}
			/>
			<Card className={hideTopbar ? `card-empty` : ''}>
				<CardBody>
					<LocationsTable 
						locations={locations}
						reload={() => setLocations(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
			{ showNewRecordModal && (
				<NewLocationModal 
					visible
					onClose={() => setShowNewRecordModal(false)}
					reload={() => setLocations(null)}
					company={company}
				/>
			)}
		</React.Fragment>
	)
}