import React, { useState, useEffect } from 'react'
import { Card, CardBody } from 'reactstrap'

import ListTopBar from '../../components/ListTopBar'

import TechniciansTable from './partials/TechniciansTable'
import NewTechnicianModal from './partials/NewTechnicianModal'

import { handleError, parsePagination } from '../../helpers'
import { getTechnicians } from './services'

export default function TechniciansList() {
	const [technicians, setTechnicians] = useState(null)
	const [filterType, setFilterType] = useState('name')
	const [filterValue, setFilterValue] = useState('')
	const [filterScope, setFilterScope] = useState('active')
	const [pagination, setPagination] = useState({page: 1, per_page: 50, total: 0})
	const [showNewRecordModal, setShowNewRecordModal] = useState(false)

	useEffect(() => {
		!technicians && getTechnicians({
			...pagination,
			[`filter[${filterType}]`]: filterValue,
			[filterScope ? `filter[${filterScope}]` : 'noscope']: 1,
			include: ''
		})
			.then(res => {
				setTechnicians(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [ technicians, pagination, filterType, filterValue, filterScope ])

	return (
		<React.Fragment>
			<ListTopBar 
				title="Técnicos Vinculados"
				subtitle="Listado de técnicos vinculados a Ingealimentos y Andina de Servicios"
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				filterScope={filterScope}
				setFilterScope={setFilterScope}
				reload={() => setTechnicians(null)}
				options={[
					{ label: 'Buscar por nombre', value: 'name' },
					{ label: 'Buscar por documento', value: 'document' },
				]}
				scopes={[
					{ label: 'Técnicos Activos', value: 'active' },
					{ label: 'Técnicos Inactivos', value: 'inactive' },
				]}
				ctaText="Agregar Registro"
				handleCtaClick={() => setShowNewRecordModal(true)}
			/>
			<Card>
				<CardBody>
					<TechniciansTable 
						technicians={technicians}
						reload={() => setTechnicians(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
			{ showNewRecordModal && (
				<NewTechnicianModal 
					visible
					onClose={() => setShowNewRecordModal(false)}
					reload={() => setTechnicians(null)}
				/>
			)}
		</React.Fragment>
	)
}