import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faCircleCheck } from '@fortawesome/free-solid-svg-icons'

export default function ReportDocServiceItems({ serviceReport, formatSettings }) {
	const serviceItemOpts = formatSettings.filter(r => r.setting.scope === 'service')

	const isRelatedToService = setting => {
		return serviceReport.service.service_items.filter(i => i.item_type_id === setting.id).length > 0
	}

	return (
		<React.Fragment>
			<div className='detailsDiv mb-5'>
				{ serviceItemOpts.map( opt => (
					<h2 className='inline-block ml-30 mb-0' key={opt.id}>
						<FontAwesomeIcon 
							color={isRelatedToService(opt.setting) ? '#333' : '#ccc'} 
							icon={isRelatedToService(opt.setting) ? faCircleCheck : faCircle} 
							className="mr-5" 
						/>
						{ opt.setting.name }
					</h2>
				)) }
			</div>
		</React.Fragment>
	)
}