import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faCircleCheck } from '@fortawesome/free-solid-svg-icons'

export default function ReportDocEquipments({ serviceReport, formatSettings }) {
	const serviceEquipmentOpts = formatSettings.filter(r => r.setting_type === 'equipment_type')

	const isRelatedToService = setting => {
		return serviceReport.service.service_equipments.filter(i => i.equipment_type_id === setting.id).length > 0
	}

	return (
		<React.Fragment>
			<div className='detailsDiv mb-5'>
				{ serviceEquipmentOpts.map( opt => (
					<h2 className='inline-block ml-10 mb-0' key={opt.id}>
						<FontAwesomeIcon 
							color={isRelatedToService(opt.setting) ? '#333' : '#ccc'} 
							icon={isRelatedToService(opt.setting) ? faCircleCheck : faCircle} 
							className="mr-5" 
						/>
						<small>{ opt.setting.name }</small>
					</h2>
				)) }
			</div>
		</React.Fragment>
	)
}