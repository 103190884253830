import ingealimentosApi, { getDomain } from '../../services/ingealimentosApi'

export const getReviewTasks = params => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.get('/review_tasks', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getReviewTask = (id, params) => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.get(`/review_tasks/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeReviewTask = data => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.post('/review_tasks', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateReviewTask = (id, data) => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.put(`/review_tasks/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteReviewTask = id => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.delete(`/review_tasks/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchReviewTasks = async (term, token) => (
	fetch( getDomain()+`/review_tasks?filter[name]=${term}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(item => ({
				label: item.name,
				value: item.id,
			})),
		)
)