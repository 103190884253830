import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import ServiceResultsList from './ServiceResultsList'
import ServiceResultProfile from './ServiceResultProfile'
import ServiceResultPreview from './ServiceResultPreview'

export default function ServiceResults() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/service_results" component={ServiceResultsList} />
				<Route exact path="/service_results/:id" component={ServiceResultProfile} />
				<Route exact path="/service_results/preview/:id" component={ServiceResultPreview} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}