import React, { useState } from 'react'
import { Spinner, Button } from 'react-bootstrap'
import { Divider, Empty } from 'antd'

import ReviewResultCard from './partials/ReviewResultCard'
import NewReviewResultModal from './partials/NewReviewResultModal'

export default function ReviewResultsWidget({ serviceReview, reload }) {	
	const [showNewRecordModal, setShowNewRecordModal] = useState(false)

	if(!serviceReview) 
		return <Spinner animation='border' size="sm" />

	const reviewResults = serviceReview.review_results

	return (
		<React.Fragment>
			{ reviewResults.length > 0 ? (
				<React.Fragment>
					{ reviewResults.map(item => (
						<ReviewResultCard 
							key={item.id}
							reviewResult={item}
							reload={reload}
						/>
					)) }
				</React.Fragment>
			) : (
				<Empty 
					description={`No se tiene resultados registradas`}
					imageStyle={{ maxHeight: '75px' }}
				/>
			)}
			<Divider className='my-15' />
			<div className='text-center'>
				<Button 
					variant='outline-primary' 
					size="sm" 
					onClick={() => setShowNewRecordModal(true)}
				>
					Registrar Resultado
				</Button>
			</div>
			{ showNewRecordModal && (
				<NewReviewResultModal 
					visible
					onClose={() => setShowNewRecordModal(false)}
					reload={reload}
					serviceReview={serviceReview}
				/>
			)}
		</React.Fragment>
	)
}