import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Form, Spinner } from 'react-bootstrap'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { message } from 'antd'

import ReportDetailForm from './ReportDetailForm'

import { storeReportDetail } from '../services'
import { handleError } from '../../../helpers'

export default function NewReportDetailModal({ visible, onClose, reload, serviceReport }) {
	const [loading, setLoading] = useState(false)
	const { handleSubmit, register, formState: { errors } } = useForm({
		defaultValues: { service_report_id: serviceReport.id }
	})

	const onSubmit = values => {
		setLoading(true)
		storeReportDetail(values)
			.then(res => {
				message.success(res.data.message)
				reload()
				onClose()
			})
			.catch(error => handleError(error, setLoading))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Registrar resultado en reporte</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<ReportDetailForm 
							register={register}
							errors={errors}
							serviceReport={serviceReport}
						/>
						<Button color='primary' type="submit" disabled={loading}>
							{ loading && <Spinner animation='border' size="sm" className='mr-1' /> }
							Registrar Resultado
						</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}