import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

import ResultAttachmentForm from './ResultAttachmentForm'

export default function NewResultAttachmentModal({ visible, onClose, reload, serviceResult }) {
	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Registrar nueva evidencia</ModalHeader>
				<ModalBody>
					<ResultAttachmentForm 
						serviceResultId={serviceResult.id}
						reload={reload}
					/>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}