import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Form, Spinner } from 'react-bootstrap'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { message } from 'antd'

import ServiceItemForm from './ServiceItemForm'

import { updateServiceItem } from '../services'
import { handleError } from '../../../helpers'

export default function EditServiceItemModal({ visible, onClose, serviceItem, reload, scope }) {
	const { handleSubmit, register, formState: { errors } } = useForm({ 
		defaultValues: { 
			lote_no: serviceItem.lote_no,
			lote_ff: serviceItem.lote_ff,
			lote_fv: serviceItem.lote_fv,
		}
	})
	const [loading, setLoading] = useState(false)

	const onSubmit = values => {
		setLoading(true)
		updateServiceItem(serviceItem.id, values)
			.then(res => {
				message.success(res.data.message)
				reload()
				onClose()
			})
			.catch(error => handleError(error, setLoading))
	}

	return (
		<Modal isOpen={visible} toggle={onClose}>
			<ModalHeader toggle={onClose}>Actualizar Registro</ModalHeader>
			<ModalBody>
				<Form onSubmit={handleSubmit(onSubmit)}>
					<ServiceItemForm 
						register={register}
						errors={errors}
						scope={scope}
						editable
					/>
					<Button color='primary' type="submit" disabled={loading}>
						{ loading && <Spinner animation="border" size="sm" className='mr-1' /> }
						Actualizar Registro
					</Button>
				</Form>
			</ModalBody>
		</Modal>
	)
}