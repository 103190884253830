import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Badge, Button, CardSubtitle, CardTitle } from 'reactstrap'

import NewServiceReportModal from '../../ServiceReports/partials/NewServiceReportModal'
import NewServiceReviewModal from '../../ServiceReviews/partials/NewServiceReviewModal'

export default function ServiceProfileTopbar({ service }) {
	const [showNewServiceReviewModal, setShowNewServiceReviewModal] = useState(false)
	const [showNewServiceReportModal, setShowNewServiceReportModal] = useState(false)

	return (
		<React.Fragment>
			<div className='float-right pt-5'>
				{ service.service_review ? (
					<Link to={`/service_reviews/${service.service_review.id}`}>
						<Button className="ml-7" color='primary' outline>
							Diagnóstico de Servicio
						</Button>
					</Link>
				) : service.service_format.review_format_id && (
					<Button
						className="ml-7"
						color='primary'
						onClick={() => setShowNewServiceReviewModal(true)}
					>
							Crear Diagnóstico de Servicio
					</Button>
				)}
				{ service.service_report ? (
					<React.Fragment>
						<Link to={`/service_reports/${service.service_report.id}`}>
							<Button className="ml-7" color='primary' outline>
								Reporte de Servicio
							</Button>
						</Link>
					</React.Fragment>
				) : (
					<Button
						className="ml-7" 
						color='primary'
						outline={service.service_format.review_format_id === 1}
						onClick={() => setShowNewServiceReportModal(true)}
					>
						Crear Reporte de Servicio
					</Button>
				)}	
			</div>
			
			<CardTitle>
				Servicio #0{service.id} - {service.location ? service.location.company.legal_name : <em>Sin sede</em>}
				{" "}
				<Badge color="dark" className='ml-5' style={{ fontSize:'10px', position: 'relative', bottom: '3px' }}>
					{service.is_corrective ? 'CORRECTIVO' : 'PREVENTIVO'}
				</Badge>
			</CardTitle>
			<CardSubtitle>{service.name}</CardSubtitle>

			{ showNewServiceReviewModal && (
				<NewServiceReviewModal 
					visible
					onClose={() => setShowNewServiceReviewModal(false)}
					service={service}
				/>
			)}
			{ showNewServiceReportModal && (
				<NewServiceReportModal 
					visible
					onClose={() => setShowNewServiceReportModal(false)}
					service={service}
				/>
			)}
		</React.Fragment>
	)
}