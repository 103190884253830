import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Form, Spinner } from 'react-bootstrap'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { message } from 'antd'

import ResultActivityForm from './ResultActivityForm'

import { storeResultActivity } from '../services'
import { handleError } from '../../../helpers'

export default function NewResultActivityModal({ visible, onClose, reload, serviceResult }) {
	const [loading, setLoading] = useState(false)
	const { handleSubmit, register, formState: { errors }, watch } = useForm({
		defaultValues: { service_result_id: serviceResult.id }
	})

	const onSubmit = values => {
		setLoading(true)
		storeResultActivity(values)
			.then(res => {
				message.success(res.data.message)
				reload()
				onClose()
			})
			.catch(error => handleError(error, setLoading))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Registrar actividad en informe</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<ResultActivityForm 
							register={register}
							errors={errors}
							watch={watch}
							serviceResult={serviceResult}
						/>
						<Button color='primary' type="submit" disabled={loading}>
							{ loading && <Spinner animation='border' size="sm" className='mr-1' /> }
							Registrar Actividad
						</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}