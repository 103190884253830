import React, { useState } from 'react'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Divider, message, Popconfirm, Tooltip } from 'antd'

import EditServiceEquipmentModal from './EditServiceEquipmentModal'
import ListItemCard from '../../../components/ListItemCard'

import { deleteServiceEquipment } from '../services'
import { handleError } from '../../../helpers'

export default function ServiceEquipmentCard({ serviceEquipment, reload }) {
	const [showEditModal, setShowEditModal] = useState(false)

	const handleDelete = () => {
		deleteServiceEquipment(serviceEquipment.id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(handleError)
	}

	return (
		<React.Fragment>
			<ListItemCard>
				<div className='float-right'>
					<Tooltip title="Editar" onClick={() => setShowEditModal(true)}>
						<FontAwesomeIcon className='text-link' icon={faEdit} />
					</Tooltip>
					<Divider type="horizontal" className='my-5' />
					<Tooltip title="Eliminar" placement='bottom'>
						<Popconfirm
							title="Esta acción no se puede revertir"
							okText="Eliminar"
							cancelText="Cancelar"
							okButtonProps={{ danger:true }}
							onConfirm={handleDelete}
						>
							<FontAwesomeIcon className='text-link' icon={faTrash} />
						</Popconfirm>
					</Tooltip>
				</div>
				<p className='bold mb-3'>{serviceEquipment.equipment_type.name}</p>
				<p className='mb-0 text-muted small'>
					Dosificación: {serviceEquipment.dosification ? serviceEquipment.dosification : <em>sin registro</em>}
				</p>
				<p className='mb-0 text-muted small'>
					Registro creado el {serviceEquipment.created_at}
				</p>
			</ListItemCard>
			{ showEditModal && (
				<EditServiceEquipmentModal 
					visible
					onClose={() => setShowEditModal(false)}
					serviceEquipment={serviceEquipment}
					reload={reload}
				/>
			)}
		</React.Fragment>
	)
}