import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Form, Spinner } from 'react-bootstrap'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { message } from 'antd'

import ServiceReportForm from './ServiceReportForm'

import { updateServiceReport } from '../services'
import { handleError } from '../../../helpers'

export default function EditServiceReportModal({ visible, onClose, serviceReport, reload, editable = {} }) {
	const { handleSubmit, register, formState: { errors } } = useForm({ 
		defaultValues: serviceReport
	})
	const [loading, setLoading] = useState(false)

	const onSubmit = values => {
		setLoading(true)
		delete values.sku
		updateServiceReport(serviceReport.id, values)
			.then(res => {
				message.success(res.data.message)
				reload()
				onClose()
			})
			.catch(error => handleError(error, setLoading))
	}

	return (
		<Modal isOpen={visible} toggle={onClose}>
			<ModalHeader toggle={onClose}>Actualizar Registro</ModalHeader>
			<ModalBody>
				<Form onSubmit={handleSubmit(onSubmit)}>
					<ServiceReportForm 
						register={register}
						errors={errors}
						editable={editable}
					/>
					<Button color='primary' type="submit" disabled={loading}>
						{ loading && <Spinner animation="border" size="sm" className='mr-1' /> }
						Actualizar Registro
					</Button>
				</Form>
			</ModalBody>
		</Modal>
	)
}