import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import ItemTypesList from './ItemTypesList'

export default function ItemTypes() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/item_types" component={ItemTypesList} />
				
				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}