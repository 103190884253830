import ingealimentosApi, { getDomain } from '../../services/ingealimentosApi'

export const getCertificates = params => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.get('/certificates', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getCertificate = (id, params) => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.get(`/certificates/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getPublicCertificate = (id, params) => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.get(`/public/certificates/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeCertificate = data => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.post('/certificates', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateCertificate = (id, data) => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.put(`/certificates/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteCertificate = id => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.delete(`/certificates/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const notifyCertificate = id => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.post(`/certificates/notify/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchCertificates = async (term, token) => (
	fetch( getDomain()+`/certificates?filter[name]=${term}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(item => ({
				label: item.name,
				value: item.id,
			})),
		)
)