import React from 'react'

export default function ReportDocBlockText({ label, value, children }) {
	return (
		<React.Fragment>
			<div className='mt-10 mb-10'>
				<p className='detailLabel bold'>{label}</p>
				{ value ? (
					<React.Fragment>
						<p className='detailInfo'></p>
						<p className='detailInfoMultiline'>
							{value}
						</p>
					</React.Fragment>
				) : (
					<React.Fragment>
						<p className='detailInfo'></p>
						<p className='detailInfo'></p>
						<p className='detailInfo'></p>
					</React.Fragment>
				)}
				{ children ? children : '' }
			</div>
		</React.Fragment>
	)
}