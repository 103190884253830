import React, { useEffect, useState } from 'react'
import { Col, Form, Row, Spinner } from 'react-bootstrap'

import { getFormatTypes } from '../../FormatTypes/services'
import { handleError } from '../../../helpers'

export default function ServiceItemForm({ register, errors, editable, service, scope, setValue }) {
	const [options, setOptions] = useState(null)

	useEffect(() => {
		!options && service && getFormatTypes({
			'filter[format_type]':'report_format',
			'filter[format_id]': service.service_format.report_format_id,
			'filter[setting_type]': 'item_type',
			include: 'setting'
		})
			.then(res => setOptions(res.data.data.filter(opt => opt.setting.scope === scope)))
			.catch(handleError)
	}, [ options, service, scope ])

	if(!options && service) return <div className='mb-10'><Spinner animation='border' size="sm" /></div>

	const onChange = e => {
		setValue('item_type_id', e.target.value)
		let opts = options.filter(o => o.setting.id === parseInt(e.target.value))
		let selectedOpt = opts.length > 0 ? opts[0].setting : {}
		setValue('lote_no', selectedOpt.default_lote_no)
		setValue('lote_ff', selectedOpt.default_lote_ff)
		setValue('lote_fv', selectedOpt.default_lote_fv)
	}

	return (
		<React.Fragment>
			{ !editable && (
				<Form.Group className='form-group'>
					<Form.Label>{scope === 'product' ? 'Producto' : 'Servicio'} <span className='text-danger'>*</span></Form.Label>
					<Form.Control 
						as="select"
						onChange={onChange}
					>
						<option value="">:: Seleccione una opción ::</option>
						{ options.map(opt => (
							<option key={opt.setting.id} value={opt.setting.id}>
								{opt.setting.name}
							</option>
						)) }
					</Form.Control>
					{ errors.item_type_id && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
			)}
			{ scope === 'product' && (
				<React.Fragment>
					<Form.Group className='form-group'>
						<Form.Label>Lote No <small>(opcional)</small></Form.Label>
						<Form.Control 
							{...register('lote_no', { })}
							placeholder="Escriba aquí..."
						/>
					</Form.Group>
					<Row>
						<Form.Group className='form-group' as={Col}>
							<Form.Label>Fecha Fabricación <small>(opcional)</small></Form.Label>
							<Form.Control 
								type="date"
								{...register('lote_ff', { })}
							/>
						</Form.Group>
						<Form.Group className='form-group' as={Col}>
							<Form.Label>Fecha Vencimiento <small>(opcional)</small></Form.Label>
							<Form.Control 
								type="date"
								{...register('lote_fv', { })}
							/>
						</Form.Group>
					</Row>
				</React.Fragment>
			)}
		</React.Fragment>
	)
}