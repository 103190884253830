import React, { useState } from 'react'
import { Badge } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faEdit, faToggleOff, faToggleOn, faTrash, faUsers } from '@fortawesome/free-solid-svg-icons'
import { Divider, Empty, message, Popconfirm, Table, Tooltip } from 'antd'

import EditCompanyModal from './EditCompanyModal'
import CompanyLocationsModal from './CompanyLocationsModal'
import CompanyEmployeesModal from './CompanyEmployeesModal'

import Pagination from '../../../components/Pagination'

import { deleteCompany, updateCompany } from '../services'
import { handleError } from '../../../helpers'

export default function CompaniesTable({ companies, reload, pagination, setPagination }) {
	const [editModalRecord, setEditModalRecord] = useState(null)
	const [companyLocationsModal, setCompanyLocationsModal] = useState(null)
	const [companyEmployeesModal, setCompanyEmployeesModal] = useState(null)
	
	const columns = [
		{
			title: 'Tipo',
			dataIndex: 'type',
			render: t => <Badge color='dark'>{t.toUpperCase()}</Badge>
		},
		{
			title: 'Razón Social',
			dataIndex: 'legal_name',
		},
		{
			title: 'Nombre Comercial',
			dataIndex: 'commercial_name',
			render: t => t ? t : <em className='small text-muted'>Sin registro</em>
		},
		{
			title: 'Documento',
			dataIndex: 'document',
			render: (t, r) => `${t}-${r.document_suffix}`
		},
		{
			title: 'Regimen',
			dataIndex: 'regimen',
		},
		{
			title: 'Estado',
			dataIndex: 'status',
			render: t => <Badge color={t.color}>{t.label}</Badge>
		},
		{
			title: 'Acciones',
			render: (t, r) => (
				<React.Fragment>
					<Tooltip title="Sedes" onClick={() => setCompanyLocationsModal(r)}>
						<React.Fragment>
							<FontAwesomeIcon icon={faBuilding} className="text-link" />
						</React.Fragment>
					</Tooltip>
					<Divider type='vertical' />
					<Tooltip title="Contactos Cliente" onClick={() => setCompanyEmployeesModal(r)}>
						<React.Fragment>
							<FontAwesomeIcon icon={faUsers} className="text-link" />
						</React.Fragment>
					</Tooltip>
					<Divider type='vertical' />
					<Tooltip title="Editar" onClick={() => setEditModalRecord(r)}>
						<React.Fragment>
							<FontAwesomeIcon icon={faEdit} className="text-link" />
						</React.Fragment>
					</Tooltip>
					<Divider type='vertical' />
					<Tooltip title={r.status.key === 'active' ? 'Inactivar' : 'Activar'}>
						<React.Fragment>
							<FontAwesomeIcon 
								icon={r.status.key === 'active' ? faToggleOff : faToggleOn} 
								className="text-link"
								onClick={() => handleToggle(r.id, r.status.key === 'inactive')}
							/>
						</React.Fragment>
					</Tooltip>
					<Divider type='vertical' />
					<Tooltip title="Eliminar">
						<React.Fragment>
							<Popconfirm
								title="Esta acción no se puede revertir"
								okText="Eliminar"
								cancelText="Cancelar"
								okButtonProps={{ danger:true }}
								onConfirm={() => handleDelete(r.id)}
							>
								<FontAwesomeIcon icon={faTrash} className="text-link" />
							</Popconfirm>
						</React.Fragment>
					</Tooltip>
				</React.Fragment>
			)
		}
	]

	const handleToggle = (id, isActive) => {
		updateCompany(id, { is_active: isActive ? 1 : 0 })
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(handleError)
	}

	const handleDelete = id => {
		deleteCompany(id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(handleError)
	}
	
	return (
		<React.Fragment>
			<Table 
				dataSource={companies}
				rowKey={r => r.id}
				pagination={false}
				columns={columns}
				loading={!companies}
				size="small"
				locale={{ emptyText: (
					<Empty
						description="No se encontraron registros" 
						imageStyle={{ height: '80px' }}
					/> 
				)}}
			/>
			<Pagination 
				pagination={pagination}
				setPagination={setPagination}
				reload={reload}
				className="mb-0"
			/>
			{ editModalRecord && (
				<EditCompanyModal 
					visible
					onClose={() => setEditModalRecord(null)}
					company={editModalRecord}
					reload={reload}
				/>
			)}
			{ companyLocationsModal && (
				<CompanyLocationsModal 
					visible
					onClose={() => setCompanyLocationsModal(null)}
					company={companyLocationsModal}
				/>
			)}
			{ companyEmployeesModal && (
				<CompanyEmployeesModal 
					visible
					onClose={() => setCompanyEmployeesModal(null)}
					company={companyEmployeesModal}
				/>
			)}
		</React.Fragment>
	)
}