import React from 'react'
import { Form } from 'react-bootstrap'

export default function ServiceReportForm({ register, errors, editable }) {
	return (
		<React.Fragment>
			{ (!editable || editable.report_at) && (
				<Form.Group className='form-group'>
					<Form.Label>Fecha del Reporte <span className='text-danger'>*</span></Form.Label>
					<Form.Control 
						type="date"
						{...register('report_at', { required: true })}
					/>
					{ errors.report_at && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
			)}
			{ editable && editable.job_description && (
				<Form.Group className='form-group'>
					<Form.Label>Trabajo realizado <small>(opcional)</small></Form.Label>
					<Form.Control 
						as="textarea"
						{...register('job_description', {})}
						placeholder="Escriba aquí..."
						style={{ height: '100px' }}
					/>
				</Form.Group>
			)}
			{ editable && editable.technician_observations && (
				<Form.Group className='form-group'>
					<Form.Label>Observaciones del Técnico <small>(opcional)</small></Form.Label>
					<Form.Control 
						as="textarea"
						{...register('technician_observations', {})}
						placeholder="Escriba aquí..."
						style={{ height: '100px' }}
					/>
				</Form.Group>
			)}
			{ editable && editable.client_observations && (
				<Form.Group className='form-group'>
					<Form.Label>Observaciones del Cliente <small>(opcional)</small></Form.Label>
					<Form.Control 
						as="textarea"
						{...register('client_observations', {})}
						placeholder="Escriba aquí..."
						style={{ height: '100px' }}
					/>
				</Form.Group>
			)}
			{ editable && editable.physic_recomendations && (
				<Form.Group className='form-group'>
					<Form.Label>Recomendación de Control Físico <small>(opcional)</small></Form.Label>
					<Form.Control 
						as="textarea"
						{...register('physic_recomendations', {})}
						placeholder="Escriba aquí..."
						style={{ height: '100px' }}
					/>
				</Form.Group>
			)}
			{ editable && editable.ambient_recomendations && (
				<Form.Group className='form-group'>
					<Form.Label>Recomendación de Control Ambiental <small>(opcional)</small></Form.Label>
					<Form.Control 
						as="textarea"
						{...register('ambient_recomendations', {})}
						placeholder="Escriba aquí..."
						style={{ height: '100px' }}
					/>
				</Form.Group>
			)}
			{ editable && editable.final_recomendations && (
				<Form.Group className='form-group'>
					<Form.Label>Recomendaciones Finales <small>(opcional)</small></Form.Label>
					<Form.Control 
						as="textarea"
						{...register('final_recomendations', {})}
						placeholder="Escriba aquí..."
						style={{ height: '100px' }}
					/>
				</Form.Group>
			)}
		</React.Fragment>
	)
}