import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Badge } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faMagnifyingGlass, faTrash } from '@fortawesome/free-solid-svg-icons'
import { Divider, Empty, message, Popconfirm, Table, Tooltip } from 'antd'
import moment from 'moment'

import EditServiceModal from './EditServiceModal'
import Pagination from '../../../components/Pagination'

import { deleteService, updateService } from '../services'
import { handleError, numberFormat } from '../../../helpers'

export default function ServicesTable({ services, reload, reloadOnlyServices, pagination, setPagination }) {
	const user = useSelector(state => state.auth.user)
	const [editModalRecord, setEditModalRecord] = useState(null)
	
	const columns = [
		{
			title: 'Servicio',
			dataIndex: 'id',
			render: (t, r) => <Link target="_blank" rel="noopener noreferrer" to={`/services/${r.id}`}>#00{t}</Link>
		},
		{
			title: 'Cliente (Sede)',
			dataIndex: 'location',
			render: t => t ? `${t.company.legal_name } (${t.name})`: <em>Sede eliminada</em>
		},
		{
			title: 'Nombre del Servicio',
			dataIndex: 'name',
		},
		{
			title: 'Tipo de Servicio',
			dataIndex: 'is_corrective',
			render: t => t ? 'Correctivo' : 'Preventivo'
		},
		{
			title: 'Fecha del servicio',
			dataIndex: 'service_at',
			width: '105px',
		},
		{
			title: 'Fecha Creación',
			dataIndex: 'created_at',
			width: '105px',
		},
		{
			title: 'Valor del servicio',
			dataIndex: 'price',
			render: t => t ? `$ ${numberFormat(t)} COP` : <em>Sin registro</em>,
		},
		{
			title: 'Cta. Cobro',
			width: '115px',
			dataIndex: 'charged_at',
			render: (t, r) => t ? (
				<React.Fragment>
					<Tooltip title={t}>
						<Popconfirm
							title="¿Desea marcar como NO cobrado?"
							okText="Eliminar Registro"
							cancelText="Cancelar"
							onConfirm={() => (user.role === 'admin' || user.permissions.includes('update_invoice_status')) ? handleChargedAt(r.id, '') : alert('No posee permisos para esta acción')}
						>
							<Badge color="info">Cta. Enviada</Badge>
						</Popconfirm>
					</Tooltip>
				</React.Fragment>
			) : (
				<Popconfirm
					title="¿Desea marcar como enviada?"
					okText="Enviada Cta. Cobro"
					cancelText="Cancelado"
					onConfirm={() => (user.role === 'admin' || user.permissions.includes('update_invoice_status')) ? handleChargedAt(r.id) : alert('No posee permisos para esta acción')}
				>
					<Badge color="dark" style={{ cursor:'pointer' }}>
						Cta. sin Enviar
					</Badge>
				</Popconfirm>
			)
		},
		{
			title: 'Facturación',
			width: '105px',
			dataIndex: 'invoiced_at',
			render: (t, r) => t ? (
				<React.Fragment>
					<Tooltip title={t}>
						<Popconfirm
							title="¿Desea marcar como NO facturado?"
							okText="Eliminar Registro"
							cancelText="Cancelar"
							onConfirm={() => (user.role === 'admin' || user.permissions.includes('update_invoice_status')) ? handleInvoicedAt(r.id, '') : alert('No posee permisos para esta acción')}
						>
							<Badge color="info">Facturado</Badge>
						</Popconfirm>
					</Tooltip>
				</React.Fragment>
			) : (
				<Popconfirm
					title="¿Desea marcar como facturado?"
					okText="Facturado"
					cancelText="Cancelado"
					onConfirm={() => (user.role === 'admin' || user.permissions.includes('update_invoice_status')) ? handleInvoicedAt(r.id) : alert('No posee permisos para esta acción')}
				>
					<Badge color="dark" style={{ cursor:'pointer' }}>
						Sin Facturar
					</Badge>
				</Popconfirm>
			)
		},
		{
			title: 'Estado',
			dataIndex: 'status',
			render: t => <Badge color={t.color}>{t.label}</Badge>
		},
		{
			title: 'Acciones',
			width: '95px',
			render: (t, r) => (
				<React.Fragment>
					<Tooltip title="Ver Servicio">
						<React.Fragment>
							<Link target="_blank" rel="noopener noreferrer" to={`/services/${r.id}`}>
								<FontAwesomeIcon icon={faMagnifyingGlass} className="text-link" />
							</Link>
						</React.Fragment>
					</Tooltip>
					<Divider type='vertical' />
					<Tooltip title="Editar" onClick={() => setEditModalRecord(r)}>
						<React.Fragment>
							<FontAwesomeIcon icon={faEdit} className="text-link" />
						</React.Fragment>
					</Tooltip>
					<Divider type='vertical' />
					{ (user.role === 'admin' || user.permissions.includes('delete_services')) && (
						<Tooltip title="Eliminar">
							<React.Fragment>
								<Popconfirm
									title="Esta acción no se puede revertir"
									okText="Eliminar"
									cancelText="Cancelar"
									okButtonProps={{ danger:true }}
									onConfirm={() => handleDelete(r.id)}
								>
									<FontAwesomeIcon icon={faTrash} className="text-link" />
								</Popconfirm>
							</React.Fragment>
						</Tooltip>
					)}
				</React.Fragment>
			)
		}
	]

	const expandedRowRender = r => (
		<React.Fragment>
			<p className='mb-0'><strong>Asesor asignado: </strong>{r.user.name}</p>
			<p className='mb-0'><strong>Fecha de último envió del reporte: </strong>{r.report_last_sent_at ? r.report_last_sent_at : <em>Sin registro de envió</em>}</p>
		</React.Fragment>
	)

	const handleDelete = id => {
		deleteService(id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(handleError)
	}

	const handleInvoicedAt = (id, value = moment().format('YYYY-MM-DD HH:mm:ss')) => {
		updateService(id, { 
			invoiced_at: value, 
		})
			.then(res => {
				message.success(res.data.message)
				reloadOnlyServices()
			})
			.catch(handleError)
	}

	const handleChargedAt = (id, value = moment().format('YYYY-MM-DD HH:mm:ss')) => {
		updateService(id, { 
			charged_at: value
		})
			.then(res => {
				message.success(res.data.message)
				reloadOnlyServices()
			})
			.catch(handleError)
	}
	
	return (
		<React.Fragment>
			<Table 
				dataSource={services}
				rowKey={r => r.id}
				pagination={false}
				columns={columns}
				loading={!services}
				size="small"
				expandable={{ expandedRowRender }}
				locale={{ emptyText: (
					<Empty
						description="No se encontraron registros" 
						imageStyle={{ height: '80px' }}
					/> 
				)}}
			/>
			<Pagination 
				pagination={pagination}
				setPagination={setPagination}
				reload={reload}
				className="mb-0"
			/>
			{ editModalRecord && (
				<EditServiceModal 
					visible
					onClose={() => setEditModalRecord(null)}
					service={editModalRecord}
					reload={reload}
				/>
			)}
		</React.Fragment>
	)
}