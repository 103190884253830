import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import LocationsList from './LocationsList'

export default function Locations() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/locations" component={LocationsList} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}