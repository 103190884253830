import React from 'react'
import { message, Upload } from "antd"
import { InboxOutlined } from '@ant-design/icons'
import AWS from "aws-sdk"

import { storeResultAttachment } from '../services'
import { handleError } from '../../../helpers'

const { Dragger } = Upload

export default function ResultAttachmentForm({ serviceResultId, reload }) {

	const props = {
		multiple: false,
		customRequest({
			action,
			data,
			file,
			filename,
			headers,
			onError,
			onProgress,
			onSuccess,
			withCredentials
		}) {
			AWS.config.update({
				accessKeyId: 'AKIA4NB262CUAYKNLDN6',
    			secretAccessKey: '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'
			});
		
			const S3 = new AWS.S3({
				params: { Bucket: 'ingealimentos' },
				region: 'us-east-1',
		  });

		  let fileExt = file.name.split('.').pop();
		  let uri = `app/service_results/${serviceResultId}/${Date.now()}.${fileExt}`
		
			const objParams = {
				Bucket: "ingealimentos",
				Key: uri,
				Body: file,
				ContentType: file.type
			};
		
			S3.putObject(objParams)
				.on("httpUploadProgress", function({ loaded, total }) {
					onProgress(
						{ percent: Math.round((loaded / total) * 100) },
						file
					);
				})
				.send(function(err, data) {
					if (err) {
						onError();
						console.error('Error al subir imagen: ', err.message)
					} else {
						onSuccess(data.response, file);						
						storeResultAttachment({
							service_result_id: serviceResultId,
							url: `https://ingealimentos.s3.us-east-1.amazonaws.com/${uri}`
						})
							.then(res => {
								message.success(res.data.message)
								reload()
							})
							.catch(error => handleError(error))
					}
				});
			}
		};

	return (
		<div style={{ maxWidth: '400px', margin: 'auto' }}>
			<Dragger {...props} className="px-10">
				<p className="ant-upload-drag-icon mb-5">
					<InboxOutlined style={{ color: '#e78035', fontSize: '41px' }} />
				</p>
				<p className="bold">Haga clic o arraste</p>
				<p className="small">
					Solo se permite adjuntar un archivo menor a 3MB
				</p>
			</Dragger>
		</div>
	)
}