import React from 'react'

import logoAndina from '../../../../assets/images/logo_andina.png'
import logoIngealimentos from '../../../../assets/images/logo_ingealimentos.png'

export default function ReviewDocHeader({ serviceReview }) {
	return (
		<React.Fragment>
			<tr>
				<td colSpan={5} rowSpan={3} className="p-10 text-center" width={"250px"}>
					<img 
						src={ serviceReview.service.brand_slug === 'andina' ? logoAndina : logoIngealimentos } 
						width={ serviceReview.service.brand_slug === 'andina' ? '100px' : '140px' } 
						alt="Logo"
					/>
				</td>
				<td colSpan={22} rowSpan={2}>
					<h1 className='mb-0 bold text-center px-30'>
						{serviceReview.review_format.name}
					</h1>
				</td>
				<td colSpan={7} rowSpan={1}>
					<div className='text-center'>
						<p>Código { serviceReview.review_format.code }</p>
					</div>
				</td>
			</tr>
			<tr>
				<td colSpan={7} rowSpan={1}>
					<div className='text-center'>
						<p>Versión { serviceReview.review_format.current_version }</p>
					</div>
				</td>
			</tr>
			<tr>
				<td colSpan={22} rowSpan={1}>
					<h1 className='mb-0 bold text-center px-20'>
						{serviceReview.service.name}<br />
					</h1>
				</td>
				<td colSpan={7} rowSpan={1}>
					<div className='text-center px-10'>
						<p>Fecha de vigencia y/ revisión: { serviceReview.review_format.valid_until }</p>
					</div>
				</td>
			</tr>
		</React.Fragment>
	)
}