import React, { useState, useEffect } from 'react'
import { Card, CardBody } from 'reactstrap'

import ListTopBar from '../../components/ListTopBar'

import ServiceReviewsTable from './partials/ServiceReviewsTable'

import { getServiceReviews } from './services'
import { handleError, parsePagination } from '../../helpers'

export default function ServiceReviewsList() {
	const [serviceReviews, setServiceReviews] = useState(null)
	const [filterType, setFilterType] = useState('sku')
	const [filterValue, setFilterValue] = useState('')
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})

	useEffect(() => {
		!serviceReviews && getServiceReviews({
			...pagination,
			[`filter[${filterType}]`]: filterValue,
			include: 'service.location.company,service.serviceFormat'
		})
			.then(res => {
				setServiceReviews(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [ serviceReviews, pagination, filterType, filterValue ])

	return (
		<React.Fragment>
			<ListTopBar 
				title="Diagnósticos de Servicios"
				subtitle="Listado de diagnósticos de servicio registrados en la plataforma"
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				reload={() => setServiceReviews(null)}
				options={[
					{ label: 'Buscar por consecutivo', value: 'sku' },
				]}
			/>
			<Card>
				<CardBody>
					<ServiceReviewsTable 
						serviceReviews={serviceReviews}
						reload={() => setServiceReviews(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
		</React.Fragment>
	)
}