import React, { useState, useEffect } from 'react'
import { Col, Form, Row, Spinner, InputGroup } from 'react-bootstrap'

import ServerSideSelect from '../../../components/ServerSideSelect'
import NewCompanyModal from '../../Companies/partials/NewCompanyModal'
import NewLocationModal from '../../Locations/partials/NewLocationModal'

import NewEmployeeModal from '../../Employees/partials/NewEmployeeModal'

import { searchCompanies } from '../../Companies/services'
import { searchEmployees } from '../../Employees/services'
import { searchLocations } from '../../Locations/services'
import { searchServiceFormats } from '../services'
import { getUsers } from '../../Users/services'
import { handleError } from '../../../helpers'

export default function ServiceForm({ register, errors, setValue, editable, watch }) {
	const [selectedServiceFormat, setSelectedServiceFormat] = useState([])
	const [selectedCompany, setSelectedCompany] = useState([])
	const [selectedLocation, setSelectedLocation] = useState([])
	const [selectedEmployee, setSelectedEmployee] = useState([])
	const [showNewCompanyModal, setShowNewCompanyModal] = useState(false)
	const [showNewLocationModal, setShowNewLocationModal] = useState(false)
	const [showNewEmployeeModal, setShowNewEmployeeModal] = useState(false)
	const [users, setUsers] = useState(null)

	useEffect(() => {
		!users && getUsers({  per_page: 100, 'filter[active]': 1 })
			.then(res => setUsers(res.data.data))
			.catch(handleError)
	}, [ users ])

	if(!users) return <Spinner animation='border' size="sm" />

	return (
		<React.Fragment>
			{ !editable && (
				<Form.Group className='form-group'>
					<Form.Label>Categoría del Servicio <span className='text-danger'>*</span></Form.Label>
					<ServerSideSelect 
						value={selectedServiceFormat}
						placeholder="Buscar por nombre del servicio"
						fetchOptions={searchServiceFormats}
						onChange={record => {
							setSelectedServiceFormat(record)
							setValue('service_format_id', record.value)
						}}
						className="full-width"
					/>
					<Form.Control {...register('service_format_id', { required: true })} style={{ display:'none' }} />
					{ errors.service_format_id && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
			)}
			{ watch('service_format_id') === 1 && (
				<Form.Group className='form-group'>
					<Form.Label>Nombre del Servicio <span className='text-danger'>*</span></Form.Label>
					<Form.Control 
						as="select"
						{...register('custom_name', { required:true })}
						placeholder="Escribe aquí..."
					>
						<option value="">:: Selecciona una opción ::</option>
						<option>MANTENIMIENTO DE TRAMPA DE GRASA</option>
						<option>EVACUACIÓN DE POZO SÉPTICO</option>
						<option>SONDEO DE TUBERIAS</option>
						<option>SERVICIO VACTOR</option>
						<option>LIMPIEZA DE CAJAS</option>
						<option>LIMPIEZA POZO EYECTOR</option>
						<option>LIMPIEZA TANQUES AGUAS LLUVIAS</option>
						<option>LIMPIEZA DE CARCAMOS</option>
						<option>MANTENIMIENTO PTAR</option>
					</Form.Control>
					{ errors.custom_name && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
			)}
			{ watch('service_format_id') === 4 && (
				<Form.Group className='form-group'>
					<Form.Label>Nombre del Servicio <span className='text-danger'>*</span></Form.Label>
					<Form.Control 
						as="select"
						{...register('custom_name', { required:true })}
						placeholder="Escribe aquí..."
					>
						<option value="">:: Selecciona una opción ::</option>
						<option>CONTROL DE INSECTOS</option>
						<option>CONTROL DE ROEDORES</option>
						<option>DESINFECCIÓN AMBIENTAL</option>
						<option>CONTROL INTEGRADO DE PLAGAS</option>
						<option>INSPECCION DE PLAGAS</option>
						<option>CAMBIO DE LAMINAS DE INSECTOS</option>
					</Form.Control>
					{ errors.custom_name && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
			)}
			<Row>
				<Form.Group className='form-group' as={Col}>
					<Form.Label>Asesor Asignado <span className='text-danger'>*</span></Form.Label>
					<Form.Control
						as="select"
						{...register('user_id', { required: true })}
					>
						<option value="">:: Seleccione una opción ::</option>
						{ users.map(user => (
							<option key={user.id} value={user.id}>{user.name}</option>
						)) }
					</Form.Control>
					{ errors.user_id && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
				<Form.Group className='form-group' as={Col}>
					<Form.Label>Precio del Servicio <small>(opcional)</small></Form.Label>
					<InputGroup className="mb-3">
						<InputGroup.Text>$</InputGroup.Text>
						<Form.Control 
							type="number"
							{...register('price', {})}
							placeholder='0'
						/>
						<InputGroup.Text>COP</InputGroup.Text>
					</InputGroup>				
					<Form.Text className='text-muted'>Escriba el valor sin puntuacion</Form.Text>
				</Form.Group>
			</Row>
			<Row>
				<Form.Group as={Col} className='form-group'>
					<Form.Label>Tipo de Servicio <span className='text-danger'>*</span></Form.Label>
					<Form.Control 
						as="select"
						{...register('is_corrective', { required: true })}
					>
						<option value="">:: Seleccione una opción ::</option>
						<option value="1">Correctivo</option>
						<option value="0">Mantenimiento</option>
					</Form.Control>
					{ errors.is_corrective && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
				<Form.Group as={Col} className='form-group'>
					<Form.Label>Empresa a cargo <span className='text-danger'>*</span></Form.Label>
					<Form.Control 
						as="select"
						{...register('brand_slug', { required: true })}
					>
						<option value="">:: Seleccione una opción ::</option>
						<option value="ingealimentos">Ingealimentos</option>
						<option value="andina">Andina de Servicios</option>
					</Form.Control>
					{ errors.brand_slug && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
			</Row>
			{ !editable && (
				<Form.Group className='form-group'>
					<Form.Label>Cliente Asociado <span className='text-danger'>*</span></Form.Label>
					<ServerSideSelect 
						value={selectedCompany}
						placeholder="Buscar por nombre de empresa"
						fetchOptions={searchCompanies}
						onChange={record => {
							setSelectedCompany(record)
							setValue('company_id', record.value)
						}}
						className="full-width"
					/>
					<Form.Text className='text-link' onClick={() => setShowNewCompanyModal(true)}>Agregar nuevo cliente</Form.Text>
					<Form.Control {...register('company_id', { required: true })} style={{ display:'none' }} />
					{ errors.company_id && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
			)}
			{ !editable && watch('company_id') && (
				<Form.Group className='form-group'>
					<Form.Label>Sede Asociada <span className='text-danger'>*</span></Form.Label>
					<ServerSideSelect 
						value={selectedLocation}
						placeholder="Buscar por nombre de sede"
						fetchOptions={searchLocations}
						scopeId={watch('company_id')}
						onChange={record => {
							setSelectedLocation(record)
							setValue('location_id', record.value)
						}}
						className="full-width"
					/>
					<Form.Text className='text-link' onClick={() => setShowNewLocationModal(true)}>Agregar nueva sede</Form.Text>
					<Form.Control {...register('location_id', { required: true })} style={{ display:'none' }} />
					{ errors.location_id && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
			)}
			{ !editable && watch('company_id') && (
				<Form.Group className='form-group'>
					<Form.Label>Contacto Cliente Principal <span className='text-danger'>*</span></Form.Label>
					<ServerSideSelect 
						value={selectedEmployee}
						placeholder="Buscar por nombre del contacto cliente"
						fetchOptions={searchEmployees}
						scopeId={watch('company_id')}
						onChange={record => {
							setSelectedEmployee(record)
							setValue('employee_id', record.value)
						}}
						className="full-width"
					/>
					<Form.Text className='text-link' onClick={() => setShowNewEmployeeModal(true)}>Agregar nuevo contacto cliente</Form.Text>
					<Form.Control {...register('employee_id', { required: true })} style={{ display:'none' }} />
					{ errors.employee_id && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
			)}
			<Row>
				<Form.Group as={Col} className='form-group'>
					<Form.Label>Fecha del Servicio <span className='text-danger'>*</span></Form.Label>
					<Form.Control 
						type="date"
						{...register('service_at_day', { required: true })}
					/>
					{ errors.service_at_day && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
				<Form.Group as={Col} className='form-group'>
					<Form.Label>Hora del Servicio <span className='text-danger'>*</span></Form.Label>
					<Form.Control 
						type="time"
						{...register('service_at_time', { required: true })}
					/>
					{ errors.service_at_time && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
			</Row>
			<Form.Group className='form-group'>
				<Form.Label>Observaciones al técnico <small>(opcional)</small></Form.Label>
				<Form.Control 
					as="textarea"
					{...register('observations', {})}
					placeholder="Escriba aquí..."
				/>
			</Form.Group>
			{ showNewCompanyModal && (
				<NewCompanyModal 
					visible
					onClose={() => setShowNewCompanyModal(false)}
					reload={() => setSelectedCompany([])}
				/>
			)}
			{ showNewLocationModal && (
				<NewLocationModal 
					visible
					onClose={() => setShowNewLocationModal(false)}
					reload={() => setSelectedLocation([])}
				/>
			)}
			{ showNewEmployeeModal && (
				<NewEmployeeModal 
					visible
					onClose={() => setShowNewEmployeeModal(false)}
					reload={() => setSelectedEmployee([])}
				/>
			)}
		</React.Fragment>
	)
}