import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import CertificatesList from './CertificatesList'
import CertificatePreview from './CertificatePreview'

export default function Certificates() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/certificates" component={CertificatesList} />
				<Route exact path="/certificates/preview/:id" render={props => <CertificatePreview {...props} isAdmin />} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}