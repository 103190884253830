import React, { useState, useEffect } from 'react'
import { Card, CardBody } from 'reactstrap'

import ListTopBar from '../../components/ListTopBar'

import EmployeesTable from './partials/EmployeesTable'
import NewEmployeeModal from './partials/NewEmployeeModal'

import { handleError, parsePagination } from '../../helpers'
import { getEmployees } from './services'

export default function EmployeesList({ company }) {
	const [employees, setEmployees] = useState(null)
	const [filterType, setFilterType] = useState('name')
	const [filterValue, setFilterValue] = useState('')
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})
	const [showNewRecordModal, setShowNewRecordModal] = useState(false)

	useEffect(() => {
		!employees && getEmployees({
			...pagination,
			[`filter[${filterType}]`]: filterValue,
			'filter[company_id]': company ? company.id : '',
			include: 'company,locations'
		})
			.then(res => {
				setEmployees(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [ employees, pagination, filterType, filterValue, company ])

	return (
		<React.Fragment>
			<ListTopBar 
				title={!company ? "Directorio de contactos" : `Contactos ${company.legal_name}`}
				subtitle={!company ? "Contactos asociadas en la plataforma" : ''}
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				reload={() => setEmployees(null)}
				options={[
					{ label: 'Buscar por nombre', value: 'name' },
					{ label: 'Buscar por email', value: 'email' },
				]}
				ctaText="Agregar Registro"
				handleCtaClick={() => setShowNewRecordModal(true)}
			/>
			<Card>
				<CardBody>
					<EmployeesTable 
						employees={employees}
						reload={() => setEmployees(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
			{ showNewRecordModal && (
				<NewEmployeeModal 
					visible
					onClose={() => setShowNewRecordModal(false)}
					reload={() => setEmployees(null)}
					company={company}
				/>
			)}
		</React.Fragment>
	)
}