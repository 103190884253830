import React, { useState } from 'react'
import { faCamera, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Divider, message, Popconfirm, Tooltip } from 'antd'

import EditReviewResultModal from './EditReviewResultModal'
import ReviewResultImagesModal from './ReviewResultImagesModal'
import ListItemCard from '../../../components/ListItemCard'

import { deleteReviewResult } from '../services'
import { handleError } from '../../../helpers'

export default function ReviewResultCard({ reviewResult, reload }) {
	const [showEditModal, setShowEditModal] = useState(false)
	const [showImagesModal, setShowImagesModal] = useState(false)

	const handleDelete = () => {
		deleteReviewResult(reviewResult.id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(handleError)
	}

	return (
		<React.Fragment>
			<ListItemCard>
				<div className='float-right ml-10'>
					<Tooltip title="Evidencia" onClick={() => setShowImagesModal(true)}>
						<FontAwesomeIcon className='text-link' icon={faCamera} />
					</Tooltip>
					<Divider type="horizontal" className='my-5' />
					<Tooltip title="Editar" onClick={() => setShowEditModal(true)}>
						<FontAwesomeIcon className='text-link' icon={faEdit} />
					</Tooltip>
					<Divider type="horizontal" className='my-5' />
					<Tooltip title="Eliminar" placement='bottom'>
						<Popconfirm
							title="Esta acción no se puede revertir"
							okText="Eliminar"
							cancelText="Cancelar"
							okButtonProps={{ danger:true }}
							onConfirm={handleDelete}
						>
							<FontAwesomeIcon className='text-link' icon={faTrash} />
						</Popconfirm>
					</Tooltip>
				</div>
				<p className='mb-5 bold'>Area {reviewResult.area}</p>
				<p className='mb-10'>{reviewResult.activity}</p>
				<p className='mb-0 text-muted small'>
					<b>Tipo de resultado:</b> {reviewResult.group === 'fisica' && 'Condición Fisica'}{reviewResult.group === 'cultural' && 'Condición Cultural'}{reviewResult.group === 'periodicidad' && 'Periodicidad'}
				</p>
			</ListItemCard>
			{ showEditModal && (
				<EditReviewResultModal 
					visible
					onClose={() => setShowEditModal(false)}
					reviewResult={reviewResult}
					reload={reload}
				/>
			)}
			{ showImagesModal && (
				<ReviewResultImagesModal 
					visible
					onClose={() => setShowImagesModal(false)}
					reviewResult={reviewResult}
					reload={reload}
				/>
			)}
		</React.Fragment>
	)
}