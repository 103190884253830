import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, CardSubtitle, CardTitle } from 'reactstrap'
import { Divider, Popconfirm, message } from 'antd'
import { Spinner } from 'react-bootstrap'
import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'

import { notifyServiceResult } from '../services'
import { handleError } from '../../../helpers'

export default function ServiceResultProfileTopbar({ serviceResult }) {
	const currentUser = useSelector(state => state.auth.user)
	const [loading, setLoading] = useState(false)

	if(!serviceResult) return null

	const printDocument = () => {
		setLoading(true)
		const input = document.getElementById('reportPreviewContainer')
		html2canvas(input, { 
			useCORS: true,
			allowTaint: false,
			imageTimeout: 60000,
			scale: 3,
		})
		  	.then((canvas) => {
				const imgData = canvas.toDataURL('image/png')
				
				const pdf = new jsPDF('p', 'mm', 'a4', true)

				var position = 0
				var pageHeight = pdf.internal.pageSize.getHeight()
				var imgWidth = pdf.internal.pageSize.getWidth()
				var imgHeight = canvas.height * imgWidth / canvas.width
				var heightLeft = imgHeight
				
				pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
				heightLeft -= pageHeight;
				
				while (heightLeft >= 0) {
					position = heightLeft - imgHeight;
					pdf.addPage('a4')
					pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
					heightLeft -= pageHeight
				}
			 	
			 	pdf.save(`Informe de Servicio #${serviceResult.id}.pdf`)
		  })
		  .then(() => setLoading(false))
		;
	}

	const handleSendServiceResult = () => {
		notifyServiceResult(serviceResult.id)
			.then(res => message.success(res.data.message))
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<div className='float-right pt-5'>				
				<Link to={`/service_reports/${serviceResult.service_report.id}`}>
					<Button color='primary' outline className='ml-7'>
						Reporte de Servicio Asociado
					</Button>
				</Link>
				<Divider type="vertical" />
				<Link to={`/service_results/preview/${serviceResult.id}`}>
					<Button color='primary' outline className='ml-7'>
						Imprimir
					</Button>
				</Link>
				<Divider type="vertical" />
				<Button 
					color='primary' 
					outline 
					onClick={printDocument}
					disabled={loading}
					// style={{display:'none'}}
				>
					{ loading && <Spinner animation='border' size="sm" className='mr-1' /> }
					Exportar PDF
				</Button>
				{ currentUser.role === 'admin' && (
					<React.Fragment>
						<Divider type="vertical" />
						<Popconfirm
							title="Esta acción no puede revertirse"
							desc="El email se enviara a todos los empleados asociados a la sede"
							okText="Enviar Informe"
							cancelText="Cancelar"
							onConfirm={handleSendServiceResult}
						>
							<Button color="primary" outline>
								Enviar Informe
							</Button>
						</Popconfirm>
					</React.Fragment>
				)}
			</div>

			<CardTitle>
				{serviceResult.result_format.name} #{serviceResult.sku}
			</CardTitle>
			<CardSubtitle>
			{serviceResult.service_report.service.location.company.legal_name} - {serviceResult.service_report.service.name}
			</CardSubtitle>
		</React.Fragment>
	)
}