import React, { useEffect, useState } from 'react'
import { Spinner, Button } from 'react-bootstrap'
import { Divider, Empty } from 'antd'

import ServiceVehicleCard from './partials/ServiceVehicleCard'
import NewServiceVehicleModal from './partials/NewServiceVehicleModal'

import { getServiceVehicles } from './services'
import { handleError } from '../../helpers'

export default function ServiceVehiclesWidget({ serviceId }) {
	const [serviceVehicles, setServiceVehicles] = useState(null)
	const [showNewRecordModal, setShowNewRecordModal] = useState(false)

	useEffect(() => {
		!serviceVehicles && getServiceVehicles({
			'filter[service_id]': serviceId,
			include: 'vehicle'
		})
			.then(res => setServiceVehicles(res.data.data))
			.catch(handleError)
	}, [ serviceVehicles, serviceId ])

	if(!serviceVehicles) 
		return <Spinner animation='border' size="sm" />

	return (
		<React.Fragment>
			{ serviceVehicles.length > 0 ? (
				<React.Fragment>
					{ serviceVehicles.map(record => (
						<ServiceVehicleCard 
							key={record.id}
							serviceVehicle={record}
							reload={() => setServiceVehicles(null)}
						/>
					)) }
				</React.Fragment>
			) : (
				<Empty 
					description="No tiene vehículos asociados"
					imageStyle={{ maxHeight: '75px' }}
				/>
			)}
			<Divider className='my-15' />
			<div className='text-center'>
				<Button 
					variant='outline-primary' 
					onClick={() => setShowNewRecordModal(true)}
					size="sm"
				>
					Asociar Vehículo al Servicio
				</Button>
			</div>
			{ showNewRecordModal && (
				<NewServiceVehicleModal 
					visible
					onClose={() => setShowNewRecordModal(false)}
					reload={() => setServiceVehicles(null)}
					serviceId={serviceId}
				/>
			)}
		</React.Fragment>
	)
}