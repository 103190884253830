import React from 'react'
import { Col, Row } from 'reactstrap'
import moment from 'moment'

import logoAndina from '../../../../assets/images/logo_andina.png'
import logoIngealimentos from '../../../../assets/images/logo_ingealimentos.png'

export default function DocumentHeader({ serviceReport }) {
	return (
		<React.Fragment>
			<Row className='mb-0'>
				<Col>
					<div className='logoDiv'>
						<img 
							src={ serviceReport.service.brand_slug === 'andina' ? logoAndina : logoIngealimentos } 
							width={ serviceReport.service.brand_slug === 'andina' ? '100px' : '140px' } 
							alt="Logo" 
						/>
					</div>
				</Col>
				<Col>
					<div className='titleDiv'>
						<h1 className='mb-0 bold'>
							REPORTE DE SERVICIO {serviceReport.service.name}<br />
						</h1>
					</div>
				</Col>
				<Col>
					<div className='text-center'>
						<h1 className='text-danger bold mt-0 mb-5'>
							No. { serviceReport.sku }
						</h1>
						<p className='small mb-0'>
							Codigo: { serviceReport.report_format.code }
						</p>
						<p className='small mb-0'>
							Versión: { serviceReport.report_format.current_version }
						</p>
						<p className='small mb-0'>
							{ moment(serviceReport.report_format.valid_until).format('DD [de] MMMM [de] YYYY').toUpperCase() }
						</p>
					</div>
				</Col>
			</Row>
		</React.Fragment>
	)
}