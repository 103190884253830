import ingealimentosApi, { getDomain } from '../../services/ingealimentosApi'

export const getFormatTypes = params => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.get('/format_types', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getPublicFormatTypes = params => (
	new Promise((resolve, reject) => {
		ingealimentosApi()
			.get('/public/format_types', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchFormatTypes = async (term, token, query) => (
	fetch( getDomain()+`/format_types?filter[setting.name]=${term}&${query}&include=setting`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(item => ({
				label: item.setting.name,
				value: item.setting.id,
				setting: item.setting,
			})),
		)
)