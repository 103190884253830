import React, { useState, useEffect } from 'react'
import { Card, CardBody } from 'reactstrap'

import ListTopBar from '../../components/ListTopBar'

import CertificatesTable from './partials/CertificatesTable'

import { handleError, parsePagination } from '../../helpers'
import { getCertificates } from './services'

export default function CertificatesList() {
	const [certificates, setCertificates] = useState(null)
	const [filterType, setFilterType] = useState('id')
	const [filterValue, setFilterValue] = useState('')
	const [filterScope, setFilterScope] = useState('')
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})

	useEffect(() => {
		!certificates && getCertificates({
			...pagination,
			[`filter[${filterType}]`]: filterValue,
			[filterScope ? `filter[${filterScope}]` : 'noscope']: 1,
			include: 'serviceReport.service.location.company,serviceReport.service.serviceFormat'
		})
			.then(res => {
				setCertificates(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [ certificates, pagination, filterType, filterValue, filterScope ])

	return (
		<React.Fragment>
			<ListTopBar 
				title="Certificados Emitidos"
				subtitle="Listado de certificados registrados en la plataforma"
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				filterScope={filterScope}
				setFilterScope={setFilterScope}
				reload={() => setCertificates(null)}
				options={[
					{ label: 'Buscar por id de certificado', value: 'id' },
				]}
				scopes={[
					{ label: 'Certificados en Borrador', value: 'draft' },
					{ label: 'Certificados Publicados', value: 'publish' },
				]}
			/>
			<Card>
				<CardBody>
					<CertificatesTable 
						certificates={certificates}
						reload={() => setCertificates(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
		</React.Fragment>
	)
}