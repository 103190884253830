import React, { useEffect, useState } from 'react'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Empty, Popconfirm, Table, Tooltip, message } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUnlink } from '@fortawesome/free-solid-svg-icons'

import AttachLocationToEmployeeModal from './AttachLocationToEmployeeModal'
import Pagination from '../../../components/Pagination'

import { detachEmployeeLocation } from '../services'
import { getLocations } from '../../Locations/services'
import { handleError, parsePagination } from '../../../helpers'

export default function EmployeeLocationsModal({ visible, onClose, employee }) {
	const [locations, setLocations] = useState(null)
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})
	const [attachLocationModal, setAttachLocationModal] = useState(false)

	useEffect(() => {
		!locations && getLocations({
			...pagination,
			'filter[employees.id]': employee.id,
		})
			.then(res => {
				setLocations(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [ locations, pagination, employee ])

	const handleDetach = locationId => {
		detachEmployeeLocation(employee.id, locationId)
			.then(res => {
				message.success(res.data.message)
				setLocations(null)
			})
			.catch(error => handleError(error))
	}

	const columns = [
		{
			title: 'Nombre de Sede',
			dataIndex: 'name'
		},
		{
			title: 'Dirección',
			dataIndex: 'address',
			render: (t, r) => `${t}, ${r.address_extra}`
		},
		{
			title: 'Ciudad',
			dataIndex: 'city',
		},
		{
			title: 'Acciones',
			render: (t, r) => (
				<React.Fragment>
					<Tooltip title="Desasociar">
						<React.Fragment>
							<Popconfirm
								title="¿Desea desasociar sede del contacto?"
								okText="Desasociar"
								cancelText="Cancelar"
								okButtonProps={{ danger:true }}
								onConfirm={() => handleDetach(r.id)}
							>
								<FontAwesomeIcon icon={faUnlink} className="text-link" />
							</Popconfirm>
						</React.Fragment>
					</Tooltip>
				</React.Fragment>
			)
		}
	]

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose} size="xl">
				<ModalHeader toggle={onClose}>Listado de Sedes del Contacto</ModalHeader>
				<ModalBody>
					<Table 
						dataSource={locations}
						rowKey={r => r.id}
						pagination={false}
						columns={columns}
						loading={!locations}
						size="small"
						locale={{ emptyText: (
							<Empty
								description="No se tiene sedes asociados al contacto" 
								imageStyle={{ height: '80px' }}
							/> 
						)}}
					/>
					<Pagination
						pagination={pagination}
						setPagination={setPagination}
						reload={() => setLocations(null)}
					/>
					<div className='text-right mt-10'>
						<Button 
							color="primary" 
							outline 
							onClick={() => setAttachLocationModal(true)}
						>
							Asociar Sede a Contacto
						</Button>
					</div>
				</ModalBody>
			</Modal>
			{ attachLocationModal && (
				<AttachLocationToEmployeeModal
					visible
					onClose={() => setAttachLocationModal(false)}
					reload={() => setLocations(null)}
					employee={employee}
				/>
			)}
		</React.Fragment>
	)
}