import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Form, Spinner } from 'react-bootstrap'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Alert, message } from 'antd'
import moment from 'moment'

import { storeCertificate } from '../services'

import { handleError } from '../../../helpers'

export default function NewCertificateModal({ visible, onClose, serviceReport }) {
	const [loading, setLoading] = useState(false)
	const history = useHistory()

	const onSubmit = () => {
		setLoading(true)
		storeCertificate({ 
			service_report_id: serviceReport.id,
			published_at: moment().format('YYYY-MM-DD HH:mm:ss')
		})
			.then(res => {
				message.success(res.data.message)
				history.push(`/certificates/preview/${res.data.data.id}`)
			})
			.catch(error => handleError(error, setLoading))
	}

	return (
		<Modal isOpen={visible} toggle={onClose}>
			<ModalHeader toggle={onClose}>Crear Certificado</ModalHeader>
			<ModalBody>
				<Form>
					<Alert description="Al crear el certificado se creara en estado borrador. Puede publicarlo cuando desee desde el módulos de certificados." type="info" className='mb-10 p-10' />
					<Button color="primary" onClick={onSubmit} disabled={loading}>
						{ loading && <Spinner animation='border' size="sm" /> }
						Crear Certificado de Servicio
					</Button>
				</Form>
			</ModalBody>
		</Modal>
	)
}