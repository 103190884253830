import React, { useState } from 'react'
import { Descriptions, Divider } from 'antd'
import { Spinner } from 'react-bootstrap'
import { Button } from 'reactstrap'

import EditServiceReportModal from '../EditServiceReportModal'

const { Item } = Descriptions

export default function ReportInfoWidget({ serviceReport, reload }) {
	const [showEditModal, setShowEditModal] = useState(false)

	if(!serviceReport) 
		return <Spinner animation='border' size="sm" />

	return (
		<React.Fragment>
			<Descriptions column={1}>
				<Item label="Consecutivo">{serviceReport.sku}</Item>
				<Item label="Fecha del Reporte">{serviceReport.report_at}</Item>
				<Item label="Contacto Principal">{serviceReport.service.employee.name} ({serviceReport.service.employee.position})</Item>
				<Item label="Correo de Contacto">{serviceReport.service.employee.email ? serviceReport.service.employee.email : <em>sin registro</em>}</Item>
				<Item label="Celular de Contacto">{serviceReport.service.employee.mobile} {serviceReport.service.employee.phone1} {serviceReport.service.employee.phone2} {serviceReport.service.employee.phone3}</Item>
			</Descriptions>
			{ serviceReport.report_format.has_job_description === 1 && (
				<React.Fragment>
					<p className='item-label'>Trabajo realizado:</p>
					<p>{ serviceReport.job_description ? serviceReport.job_description : <em>sin registro</em> }</p>
				</React.Fragment>
			)}
			<Divider className='my-15' />
			<div className='text-center'>
				<Button
					color='primary'
					outline
					size="sm" 
					onClick={() => setShowEditModal(true)}
				>
					Actualizar Detalles del Reporte
				</Button>
			</div>
			{ showEditModal && (
				<EditServiceReportModal 
					visible
					onClose={() => setShowEditModal(false)}
					serviceReport={serviceReport}
					reload={reload}
					editable={{
						report_at: true,
						job_description: serviceReport.report_format.has_job_description === 1
					}}
				/>
			)}
		</React.Fragment>
	)
}